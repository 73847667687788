<template>
  <div id="mainContainer">
    <div id="mainTableContainer">
      <table
        id="mainTable"
        class="iquoteDesignpackTable noTableShading">
        <tr class="mainHeader">
          <th colspan="21">&nbsp;</th>
        </tr>
        <tr class="mainHeader">
          <th colspan="1">&nbsp;</th>
          <th colspan="2">A End</th>
          <th colspan="2">B End</th>
          <th colspan="7">&nbsp;</th>
          <th colspan="6">Class Of Service</th>
          <th colspan="1">Exchange</th>
          <th colspan="2">Contract</th>
        </tr>
        <tr class="subHeader">
          <th>&nbsp;</th>
          <th>Name</th>
          <th>Postcode</th>
          <th>Name</th>
          <th>Postcode</th>
          <th>ALK Used?</th>
          <th>Quote Valid To Date</th>
          <th>Supplier Quote Ref</th>
          <th>Carrier</th>
          <th>AT Code</th>
          <th>CP</th>
          <th>Circuit Type</th>
          <th>EF46</th>
          <th>AF4x</th>
          <th>AF3X</th>
          <th>AF2X</th>
          <th>AF1X</th>
          <th>CD</th>
          <th>Exchange Name</th>
          <th>Attainable Bandwidth</th>
          <th>Contract Term<br>(Months)</th>
        </tr>
        <tbody
          v-for="circuitsHostingAndCablingEntry in circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries"
          :key="circuitsHostingAndCablingEntry.id">
          <tr
            v-if="!isBlankEntry(circuitsHostingAndCablingEntry) || (!isReadOnly && isBlankEntry(circuitsHostingAndCablingEntry))"
            :class="{isNewRow: circuitsHostingAndCablingEntry.timeCopied !== undefined, disabled: circuitsHostingAndCablingEntry.disabled}"
            class="data"
          >
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'selectedInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.selected"
                :disabled="isReadOnly"
                class=""
                type="checkbox">
            </td>
            <td>
              <input
                :id="'a_EndNameInput' + circuitsHostingAndCablingEntry.id"
                v-model="circuitsHostingAndCablingEntry.a_EndName"
                :disabled="isReadOnly"
                class="a_EndNameInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'a_EndPostcodeInput' + circuitsHostingAndCablingEntry.id"
                v-model="circuitsHostingAndCablingEntry.a_EndPostcode"
                :disabled="isReadOnly"
                class="a_EndPostcodeInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'b_EndNameInput' + circuitsHostingAndCablingEntry.id"
                v-model="circuitsHostingAndCablingEntry.b_EndName"
                :disabled="isReadOnly"
                class="b_EndNameInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'b_EndPostcodeInput' + circuitsHostingAndCablingEntry.id"
                v-model="circuitsHostingAndCablingEntry.b_EndPostcode"
                :disabled="isReadOnly"
                class="b_EndPostcodeInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'alk_UsedInput' + circuitsHostingAndCablingEntry.id"
                v-model="circuitsHostingAndCablingEntry.alk_Used"
                :disabled="isReadOnly"
                class="alk_UsedInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'quoteValidToDateInput' + circuitsHostingAndCablingEntry.id"
                v-model="circuitsHostingAndCablingEntry.quoteValidToDate"
                :disabled="isReadOnly"
                class="quoteValidToDateInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'supplierQuoteRefInput' + circuitsHostingAndCablingEntry.id"
                v-model="circuitsHostingAndCablingEntry.supplierQuoteRef"
                :disabled="isReadOnly"
                class="supplierQuoteRefInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <select
                :id="'CarrierInput' + circuitsHostingAndCablingEntry.id"
                :class="{invalidInput: circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingCarrierId === 0}"
                :disabled="isReadOnly"
                v-model="circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingCarrierId"
                class="CarrierInput"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="onCarrierChanged(circuitsHostingAndCablingEntry)"
              >
                <option
                  v-if="circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingCarrierId === 0"
                  key="0"
                  value="0"
                  title="< Please Select >"
                >&lt; Please Select &gt;
                </option>
                <option
                  v-for="t in iQuoteStore_GetCircuitsTableLookups.hostingAndCablingCarriers"
                  :key="t.id"
                  :value="t.id"
                  :title="t.description"
                >{{ t.description }}
                </option>
              </select>
            </td>
            <td>
              <select
                v-if="circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingCarrierId > 0"
                :id="'CarrierInput' + circuitsHostingAndCablingEntry.id"
                :class="{invalidInput: circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingProductId === 0}"
                :disabled="isReadOnly"
                v-model="circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingProductId"
                class="CarrierInput"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="onProductChanged(circuitsHostingAndCablingEntry)"
              >
                <option
                  v-if="circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingProductId === 0"
                  key="0"
                  value="0"
                  title="< Please Select >"
                >&lt; Please Select &gt;
                </option>
                <option
                  v-for="t in iQuoteStore_GetCircuitsTableLookups.hostingAndCablingProducts.filter(x => x.designPackCircuitsHostingAndCablingCarrierId === circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingCarrierId)"
                  :key="t.id"
                  :value="t.id"
                  :title="t.description"
                >{{ t.description }}
                </option>
              </select>
            </td>
            <td>
              <input
                :id="'circuitTypeInput' + circuitsHostingAndCablingEntry.id"
                v-model="circuitsHostingAndCablingEntry.circuitType"
                :disabled="isReadOnly"
                class="circuitTypeInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">PSTN</span>
            </td>
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'eF46Input' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.eF46"
                :class="{invalidInput: circuitsHostingAndCablingEntry.eF46 < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'eF4xInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.eF4x"
                :class="{invalidInput: circuitsHostingAndCablingEntry.eF4x < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'eF3xInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.eF3x"
                :class="{invalidInput: circuitsHostingAndCablingEntry.eF3x < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'eF2xInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.eF2x"
                :class="{invalidInput: circuitsHostingAndCablingEntry.eF2x < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'eF1xInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.eF1x"
                :class="{invalidInput: circuitsHostingAndCablingEntry.eF1x < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'cdInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.cd"
                :class="{invalidInput: circuitsHostingAndCablingEntry.cd < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'exchangeNameInput' + circuitsHostingAndCablingEntry.id"
                v-model="circuitsHostingAndCablingEntry.exchangeName"
                :disabled="isReadOnly"
                class="exchangeNameInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsHostingAndCablingEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'attainableBandwidthInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.attainableBandwidth"
                :class="{invalidInput: circuitsHostingAndCablingEntry.attainableBandwidth < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'contractTermMonthsInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.contractTermMonths"
                :class="{invalidInput: circuitsHostingAndCablingEntry.contractTermMonths < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="marginsTableContainer">
      <table
        id="marginsTable"
        class="iquoteDesignpackTable noTableShading">
        <tr class="mainHeader">
          <th colspan="6">&nbsp;</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th
            id="capexMarginColumn"
            colspan="3">Capex Sales Margin</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th
            id="opexMarginColumn"
            colspan="3">Opex Sales Margin</th>
        </tr>
        <tr class="mainHeader">
          <th colspan="1">&nbsp;</th>
          <th colspan="2">Install</th>
          <th colspan="2">Rental</th>
          <th colspan="1">&nbsp;</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th
            id="capexMarginColumn"
            colspan="3">
            <div
              v-if="!isReadOnly"
              class="verticalAlignTop"
            >
              <input
                id="chkOverrideCapexMargin"
                v-model.number="circuitsHostingAndCablingTableEditorComponent.overrideCapexMargin"
                type="checkbox"
              >
              <input
                v-show="circuitsHostingAndCablingTableEditorComponent.overrideCapexMargin"
                id="txtCapexMarginOverride"
                v-model.number="iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsHostingAndCablingCapexPercent"
                :class="{invalidInput: (iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsHostingAndCablingCapexPercent < 0 || iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsHostingAndCablingCapexPercent >= 100)}"
                :disabled="isReadOnly"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                max="99"
                class="marginInput marginOverrideTextboxInTableHeader"
                @change="handleCapexMarginChange($event)">
              <span v-if="!circuitsHostingAndCablingTableEditorComponent.overrideCapexMargin">Override Margin</span>
              <span v-if="circuitsHostingAndCablingTableEditorComponent.overrideCapexMargin">%</span>
            </div>
          </th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th
            id="opexMarginColumn"
            colspan="3">
            <div
              v-if="!isReadOnly"
              class="verticalAlignTop"
            >
              <input
                id="chkOverrideOpexMargin"
                v-model.number="circuitsHostingAndCablingTableEditorComponent.overrideOpexMargin"
                type="checkbox"
              >
              <input
                v-show="circuitsHostingAndCablingTableEditorComponent.overrideOpexMargin"
                id="txtOpexMarginOverride"
                v-model.number="iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsHostingAndCablingOpexPercent"
                :class="{invalidInput: (iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsHostingAndCablingOpexPercent < 0 || iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsHostingAndCablingOpexPercent >= 100)}"
                :disabled="isReadOnly"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                max="99"
                class="marginInput marginOverrideTextboxInTableHeader"
                @change="handleOpexMarginChange($event)">
              <span v-if="!circuitsHostingAndCablingTableEditorComponent.overrideOpexMargin">Override Margin</span>
              <span v-if="circuitsHostingAndCablingTableEditorComponent.overrideOpexMargin">%</span>
            </div>
          </th>
        </tr>
        <tr class="subHeader">
          <th>Quantity</th>
          <th>Buy<br>(Each)</th>
          <th>Cost<br>(Total)</th>
          <th>Monthly Buy<br>(Each)</th>
          <th>Annual Cost<br>(Total)</th>
          <th class="buttonsCol">&nbsp;</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th>Margin %</th>
          <th>Margin</th>
          <th>Sale Price</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th>Margin %</th>
          <th>Margin</th>
          <th>Sale Price</th>
        </tr>
        <tbody
          v-for="circuitsHostingAndCablingEntry in circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries"
          :key="circuitsHostingAndCablingEntry.id">
          <tr
            v-if="!isBlankEntry(circuitsHostingAndCablingEntry) || (!isReadOnly && isBlankEntry(circuitsHostingAndCablingEntry))"
            :class="{isNewRow: circuitsHostingAndCablingEntry.timeCopied !== undefined, disabled: circuitsHostingAndCablingEntry.disabled}"
            class="data"
          >
            <td>
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'quantityInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.quantity"
                :class="{invalidInput: circuitsHostingAndCablingEntry.quantity === 0}"
                :disabled="isReadOnly"
                class="quantityInput"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">£{{ circuitsHostingAndCablingEntry.costUpfront.toFixed(2) }}</span></td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">£{{ getEntryUpfrontCost(circuitsHostingAndCablingEntry).toFixed(2) }}</span></td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">£{{ circuitsHostingAndCablingEntry.costMonthly.toFixed(2) }}</span></td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">£{{ getEntryAnnualCost(circuitsHostingAndCablingEntry).toFixed(2) }}</span></td>
            <td class="buttonsCol">
              <button
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry) && !isReadOnly && !circuitsHostingAndCablingEntry.disabled"
                :id="'btnDisable' + circuitsHostingAndCablingEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Disable this entry so that the costs and prices are NOT INCLUDED in the design pack and quote"
                @click="disableEntry(circuitsHostingAndCablingEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconPause"
                  alt="remove">
              </button>
              <button
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry) && !isReadOnly && circuitsHostingAndCablingEntry.disabled"
                :id="'btnDisable' + circuitsHostingAndCablingEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Enable this entry so that the costs and prices are included in the design pack and quote"
                @click="enableEntry(circuitsHostingAndCablingEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconResume"
                  alt="insert">
              </button>
              <button
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry) && !isReadOnly && circuitsHostingAndCablingEntry.quantity !== 0"
                :id="'btnNegate' + circuitsHostingAndCablingEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Invert the quantity of this entry.  If it's positive make it negative and vice-versa"
                @click="negateEntry(circuitsHostingAndCablingEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconPlusMinus"
                  alt="negate">
              </button>
              <button
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry) && !isReadOnly"
                :id="'btnDelete' + circuitsHostingAndCablingEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Delete this entry"
                style="margin-left: 15px"
                @click="deleteEntry(circuitsHostingAndCablingEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconDelete"
                  alt="delete">
              </button>
            </td>
            <td class="noborder fixedSpacer">&nbsp;</td>
            <td
              v-if="getEntryUpfrontCost(circuitsHostingAndCablingEntry) === 0"
              class="NotApplicableMargin"
              colspan="3"
            >
              <span
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
              >Not Applicable
              </span>
            </td>
            <td
              v-if="getEntryUpfrontCost(circuitsHostingAndCablingEntry) !== 0 && circuitsHostingAndCablingEntry.priceOverrideOO === null"
            >
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'unitCostInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.marginCapexPercent"
                :class="{invalidInput: (circuitsHostingAndCablingEntry.marginCapexPercent < 0 || circuitsHostingAndCablingEntry.marginCapexPercent >= 100)}"
                :disabled="isReadOnly || getEntryUpfrontCost(circuitsHostingAndCablingEntry) === 0"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                class="marginInput"
                max="99"
                @change="sendChangesToParent()">
              <span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">%</span>
            </td>
            <td
              v-if="getEntryUpfrontCost(circuitsHostingAndCablingEntry) !== 0 && circuitsHostingAndCablingEntry.priceOverrideOO !== null"
              class="readOnlyCell"
            >
              <span
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">
                {{ calcUpfrontMarginPercent(circuitsHostingAndCablingEntry) }}
                <br>
                <TooltipIcon
                  style="margin-left: -8px"
                  width="200"
                  align="right"
                  text="Price was set on Detailed Summary Tab" />
              </span>
            </td>
            <td
              v-if="getEntryUpfrontCost(circuitsHostingAndCablingEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">£{{ getEntryCapexMargin(circuitsHostingAndCablingEntry).toFixed(2) }}</span></td>
            <td
              v-if="getEntryUpfrontCost(circuitsHostingAndCablingEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">£{{ getEntryCapexSalePrice(circuitsHostingAndCablingEntry).toFixed(2) }}</span></td>
            <td class="noborder fixedSpacer">&nbsp;</td>
            <td
              v-if="getEntryAnnualCost(circuitsHostingAndCablingEntry) === 0"
              class="NotApplicableMargin"
              colspan="3"
            >
              <span
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
              >Not Applicable
              </span>
            </td>
            <td
              v-if="getEntryAnnualCost(circuitsHostingAndCablingEntry) !== 0 && circuitsHostingAndCablingEntry.priceOverrideAR === null"
            >
              <input
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)"
                :id="'unitCostInput' + circuitsHostingAndCablingEntry.id"
                v-model.number="circuitsHostingAndCablingEntry.marginOpexPercent"
                :class="{invalidInput: (circuitsHostingAndCablingEntry.marginOpexPercent < 0 || circuitsHostingAndCablingEntry.marginOpexPercent >= 100)}"
                :disabled="isReadOnly || getEntryAnnualCost(circuitsHostingAndCablingEntry) === 0"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                class="marginInput"
                max="99"
                @change="sendChangesToParent()">
              <span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">%</span>
            </td>
            <td
              v-if="getEntryAnnualCost(circuitsHostingAndCablingEntry) !== 0 && circuitsHostingAndCablingEntry.priceOverrideAR !== null"
              class="readOnlyCell"
            >
              <span
                v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">
                {{ calcRecurringMarginPercent(circuitsHostingAndCablingEntry) }}
                <br>
                <TooltipIcon
                  style="margin-left: -8px"
                  width="200"
                  align="right"
                  text="Price was set on Detailed Summary Tab" />
              </span>
            </td>
            <td
              v-if="!isBlankEntry(circuitsHostingAndCablingEntry) && getEntryAnnualCost(circuitsHostingAndCablingEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">£{{ getEntryOpexMargin(circuitsHostingAndCablingEntry).toFixed(2) }}</span></td>
            <td
              v-if="!isBlankEntry(circuitsHostingAndCablingEntry) && getEntryAnnualCost(circuitsHostingAndCablingEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsHostingAndCablingEntry)">£{{ getEntryOpexSalePrice(circuitsHostingAndCablingEntry).toFixed(2) }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries.length > 0 && !isReadOnly"
      id="ItemsEditorContainer"
    >
      <input
        v-if="selectedEntries.length > 0"
        id="selectAll"
        value="Select all Entries"
        type="button"
        @click="selectAllEntries()"
      >
      <input
        v-if="selectedEntries.length > 0"
        id="unselectAll"
        value="Unselect all Entries"
        type="button"
        @click="unselectAllEntries()"
      >
      <input
        v-if="selectedEntries.length > 0"
        id="deleteSelected"
        value="Delete Selected Entries"
        type="button"
        @click="deleteSelectedEntries()"
      >
    </div>
    <div
      v-if="selectedEntries.length > 0 && !isReadOnly"
      id="duplicateSelectedItemsEditorContainer"
      style="vertical-align: top"
    >
      <strong>Duplicate Selected Entries</strong>
      <br><br>
      <div v-if="selectedEntries.length === 1">The selected entry will be copied.</div>
      <div v-if="selectedEntries.length > 1">The {{ selectedEntries.length }} selected entries will be copied.</div>
      <br>
      <div v-if="numDuplicatePostcodeEntries >= 1">There will be as many new entries as there are postcodes added for each of the {{ selectedEntries.length }} selected entries, and the new entries will use the postcodes for the <strong>B End Postcode</strong> field.</div>
      <div v-if="numDuplicatePostcodeEntries === 0">If you enter postcodes below, then there will be as many new entries as there are postcodes added for each of the {{ selectedEntries.length }} selected entries, and the new entries will use the postcodes for the <strong>B End Postcode</strong> field.</div>
      <br>
      <textarea
        id="duplicateWithPostcodes"
        v-model="circuitsHostingAndCablingTableEditorComponent.duplicatePostcodes"
        name="duplicateWithPostcodes"
        placeholder="Postcodes"
        rows="2"
        cols="20" />
      <br>
      &nbsp;{{ numDuplicatePostcodeEntries }} postcode(s)
      <br><br>
      This will be repeated&nbsp;
      <input
        id="duplicateCopies"
        v-model.number="circuitsHostingAndCablingTableEditorComponent.duplicateCopies"
        :class="{invalidInput: circuitsHostingAndCablingTableEditorComponent.duplicateCopies < 0}"
        type="number">
      &nbsp;times and will result in {{ numEntriesThatWillBeDuplicated }} new item(s)
      <br>
      <input
        id="duplicateSelected"
        value="OK"
        type="button"
        @click="duplicateSelectedEntries()"
      >
    </div>
  </div>
</template>

<script>

import { iQuoteStoreMixin } from '../../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackCircuitsMixin } from '../../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackCircuits'
import { iQuoteImagesMixin } from '../../../../mixins/iQuote/Globals/iQuoteImages'
import { mapActions } from 'vuex'
import TooltipIcon from '../../../common/tooltipIcon'
import { iQuoteEventBus } from '../../../../events/iQuoteEvents'

export default {
  name: 'CircuitsHostingAndCablingTableEditor',
  components: {
    TooltipIcon
  },
  mixins: [ iQuoteStoreMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsDesignPackCircuitsMixin, iQuoteImagesMixin ],
  props: {
  },
  data () {
    return {
      circuitsHostingAndCablingTableEditorComponent: {
        circuitsHostingAndCablingEntries: [],
        quoteDesignPackRevisionId: 0,
        duplicateCopies: 1,
        duplicatePostcodes: '',
        overrideCapexMargin: false,
        overrideOpexMargin: false
      }
    }
  },
  computed: {
    isReadOnly () {
      return this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.isReadOnly
    },
    selectedEntries () {
      return this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries.filter(x => x.selected === true)
    },
    duplicatePostcodeEntries () {
      return this.circuitsHostingAndCablingTableEditorComponent.duplicatePostcodes.split('\n').filter(x => x.trim() !== '')
    },
    numDuplicatePostcodeEntries () {
      if (this.duplicatePostcodeEntries.length === 1 && this.duplicatePostcodeEntries[0].trim() === '') {
        return 0
      } else {
        return this.duplicatePostcodeEntries.length
      }
    },
    numEntriesThatWillBeDuplicated () {
      if (this.numDuplicatePostcodeEntries === 0) {
        return this.circuitsHostingAndCablingTableEditorComponent.duplicateCopies *
               this.selectedEntries.length
      } else {
        return this.duplicatePostcodeEntries.length *
              this.circuitsHostingAndCablingTableEditorComponent.duplicateCopies *
              this.selectedEntries.length
      }
    },
    designPackTableSection_Technology () {
      return this.iQuoteStore_GetDesignPackTableSections.find(x => x.id === this.iQuoteConstants.designPackTableSections.TECH)
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.designPack,
      (newValue) => {
        this.initialise(newValue)
      }
    )

    this.initialise(this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack)
  },
  async created () {
    iQuoteEventBus.$on('designpack-priceOverridesChanged', async (designPack) => {
      var forceReload = true
      await this.initialise(designPack, forceReload)
    })
  },
  async destroyed () {
    iQuoteEventBus.$off('designpack-priceOverridesChanged')
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    async initialise (designPack, forceReload = false) {
      if (designPack.quoteDesignPackRevisionId === undefined) {
        // There is no design pack so nothing we can do here
        return
      }

      if (this.circuitsHostingAndCablingTableEditorComponent.quoteDesignPackRevisionId === designPack.quoteDesignPackRevisionId) {
        //  The designpack that just saved, is the one we are currently looking at

        if (!forceReload) {
          // DP is the same one as before, and we aren't forcing a reload. So just return
          return
        }
      }

      this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries = []
      this.circuitsHostingAndCablingTableEditorComponent.quoteDesignPackRevisionId = designPack.quoteDesignPackRevisionId

      // Copy entries from store into this component for editing
      designPack.circuitsHostingAndCablingEntries.forEach(entry => {
        this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries.push(entry)
      })

      if (designPack.isReadOnly === false) {
        await this.iQuoteFunctions_delay(2000) // Wait a while for the DP to finish loading

        // Update entries to latest costs from the lookups table
        this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries.forEach(entry => {
          entry.costUpfront = this.iQuoteCalculationsDesignPackCircuitsMixin_getHostingAndCablingProductUpfrontCost(entry.designPackCircuitsHostingAndCablingProductId)
          entry.costMonthly = this.iQuoteCalculationsDesignPackCircuitsMixin_getHostingAndCablingProductRecurringCost(entry.designPackCircuitsHostingAndCablingProductId)
        })

        var updatedEntries = []
        var array = this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries

        for (let index = 0; index < array.length; index++) {
          const entry = array[index]

          if (!this.isBlankEntry(entry)) {
            updatedEntries.push(entry)
          }
        }

        // this.$emit('tableUpdated', updatedEntries)
      }

      this.addBlankEntry()
    },
    calcUpfrontMarginPercent (entry) {
      var cost = entry.costUpfront * entry.quantity
      var price = this.getEntryCapexSalePrice(entry)
      var priceOverride = entry.priceOverrideOO

      if (priceOverride !== null) {
        price = priceOverride * entry.quantity
      }

      var profit = price - cost
      var margin = (profit / price) * 100

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(margin) + '%'
    },
    calcRecurringMarginPercent (entry) {
      var cost = entry.costMonthly * 12 * entry.quantity
      var price = this.getEntryOpexSalePrice(entry)
      var profit = price - cost
      var margin = (profit / price) * 100

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(margin) + '%'
    },
    handleCapexMarginChange (event) {
      this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries.forEach(entry => {
        entry.marginCapexPercent = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsHostingAndCablingCapexPercent
      })
      this.sendChangesToParent()
    },
    handleOpexMarginChange (event) {
      this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries.forEach(entry => {
        entry.marginOpexPercent = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsHostingAndCablingOpexPercent
      })
      this.sendChangesToParent()
    },
    getEntryUpfrontCost (entry) {
      if (this.isBlankEntry(entry)) {
        return 0
      }

      return entry.costUpfront * entry.quantity
    },
    getEntryAnnualCost (entry) {
      if (this.isBlankEntry(entry)) {
        return 0
      }

      return entry.costMonthly * entry.quantity * 12
    },
    getEntryCapexMargin (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      return this.getEntryCapexSalePrice(entry) - this.getEntryUpfrontCost(entry)
    },
    getEntryCapexSalePrice (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      var price = this.getEntryUpfrontCost(entry) / ((100 - entry.marginCapexPercent) / 100)
      var priceOverride = entry.priceOverrideOO

      if (priceOverride !== null) {
        price = priceOverride * entry.quantity
      }

      return price
    },
    getEntryOpexMargin (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      return this.getEntryOpexSalePrice(entry) - this.getEntryAnnualCost(entry)
    },
    getEntryOpexSalePrice (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      var price = this.getEntryAnnualCost(entry) / ((100 - entry.marginOpexPercent) / 100)
      var priceOverride = entry.priceOverrideAR

      if (priceOverride !== null) {
        price = priceOverride * entry.quantity
      }

      return price
    },
    onInputItemFocusLost (entry, event) {
      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }
    },
    onCarrierChanged (entry) {
    // If the carrier has changed, then we need to reset the carrier items dropdown,
    // so the user must select a new item, before pricing will be calculated
      entry.costUpfront = 0
      entry.costMonthly = 0
      entry.designPackCircuitsHostingAndCablingProductId = 0

      this.sendChangesToParent()
    },
    onProductChanged (entry) {
      entry.costUpfront = this.iQuoteCalculationsDesignPackCircuitsMixin_getHostingAndCablingProductUpfrontCost(entry.designPackCircuitsHostingAndCablingProductId)
      entry.costMonthly = this.iQuoteCalculationsDesignPackCircuitsMixin_getHostingAndCablingProductRecurringCost(entry.designPackCircuitsHostingAndCablingProductId)

      this.sendChangesToParent()
    },
    isBlankEntry (entry) {
      return entry.a_EndName === '' &&
        entry.a_EndPostcode === '' &&
        entry.b_EndName === '' &&
        entry.b_EndPostcode === '' &&
        entry.alk_Used === '' &&
        entry.quoteValidToDate === '' &&
        entry.supplierQuoteRef === '' &&
        entry.designPackCircuitsHostingAndCablingCarrierId === 0 &&
        entry.circuitType === '' &&
        entry.eF46 === 0 &&
        entry.eF4x === 0 &&
        entry.eF3x === 0 &&
        entry.eF2x === 0 &&
        entry.eF1x === 0 &&
        entry.cd === 0 &&
        entry.exchangeName === '' &&
        entry.attainableBandwidth === 0 &&
        entry.contractTermMonths === 0 &&
        entry.quantity === 0 &&
        entry.costUpfront === 0 &&
        entry.costMonthly === 0
    },
    hasBlankEntry () {
      var foundBlankEntry = false
      this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries.forEach(entry => {
        if (this.isBlankEntry(entry)) {
          foundBlankEntry = true
        }
      })

      return foundBlankEntry
    },
    addBlankEntry () {
      this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries.push(this.getBlankEntry())
    },
    getBlankEntry () {
      return {
        id: this.getTempEntryId(),
        a_EndName: '',
        a_EndPostcode: '',
        b_EndName: '',
        b_EndPostcode: '',
        alk_Used: '',
        quoteValidToDate: '',
        supplierQuoteRef: '',
        designPackCircuitsHostingAndCablingCarrierId: 0,
        designPackCircuitsHostingAndCablingProductId: 0,
        circuitType: '',
        eF46: 0,
        eF4x: 0,
        eF3x: 0,
        eF2x: 0,
        eF1x: 0,
        cd: 0,
        exchangeName: '',
        attainableBandwidth: 0,
        contractTermMonths: 0,
        quantity: 0,
        costUpfront: 0,
        costMonthly: 0,
        marginCapexPercent: this.iQuoteConstants.salesMargins.CIRCUITS_DEFAULT_MARGIN,
        marginOpexPercent: this.iQuoteConstants.salesMargins.CIRCUITS_DEFAULT_MARGIN,
        disabled: false,
        designPackItemLookupId: this.iQuoteCalculationsDesignPackMixin_getDesignPackItemLookupId(),
        priceOverrideOO: null,
        priceOverrideAR: null
      }
    },
    sendChangesToParent () {
      var newEntries = []
      var blankEntry = this.getBlankEntry()
      var array = this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries

      for (let index = 0; index < array.length; index++) {
        const entry = array[index]

        if (!this.isBlankEntry(entry)) {
          // Loop through all properties of the blank entry and compare against the entry to be
          // saved to check that the user hasn't entered a value of the wrong data type. This typically
          // will only happen if they deleted the contents of a numeric field. This would result in a empty
          // string being returned, and will cause a failure if we tried to save that to the DB, as it's not numeric
          for (const property in blankEntry) {
            if (typeof blankEntry[property] !== typeof entry[property] &&
                property !== 'priceOverrideOO' /* can be object (null) or a number */ &&
                property !== 'priceOverrideAR' /* can be object (null) or a number */) {
              // User has entered something of wrong datatype,
              // which will fail when saving, so reset it to default value

              console.log('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])
              this.iQuoteFunctions_ShowError('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])

              entry[property] = blankEntry[property] // Set to default
            }
          }
          newEntries.push(entry)
        }
      }

      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }

      this.$emit('tableUpdated', newEntries)
    },
    getTempEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    deleteEntry (entry, sendChangesEvent = true) {
      var arr = this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === entry.id) {
          arr.splice(i, 1)
          if (sendChangesEvent) {
            this.sendChangesToParent()
          }
          return
        }
      }
    },
    disableEntry (entry, sendChangesEvent = true) {
      entry.disabled = true
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    enableEntry (entry, sendChangesEvent = true) {
      entry.disabled = false
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    negateEntry (entry, sendChangesEvent = true) {
      entry.quantity = -entry.quantity
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    /*
    FUNCTION: duplicateEntry
    PARAMS:
      entry = the entry to be copied
      postcode = if null is copied from the entry, otherwise will override it
      sendChangesEvent = if true then will send event to parent component.  Typically you
                         will set this to false of batch copying (and send one event when
                         batch is done), and true if copying one item
    */
    duplicateEntry (entry, postCode = null, sendChangesEvent = true) {
      var newItem = {
      }

      /* Copy all properties from the item to be copied into the new item */
      for (var prop in entry) {
        newItem[prop] = entry[prop]
      }

      newItem.id = this.getTempEntryId() /* set a new id for the newly copied item */
      newItem.timeCopied = new Date() /* This will cause the cells for this row to be highlighted */

      /* The item being copied would have been selected (checkbox checked).
         We don't want this to be the case for the copy */
      newItem.selected = false

      if (postCode !== null) {
        newItem.b_EndPostcode = postCode
      }

      var arr = this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === entry.id) {
          arr.splice(arr.length - 1, 0, newItem)

          if (sendChangesEvent) {
            this.sendChangesToParent(entry)
          }

          setTimeout(() => this.stopHighlightingNewlyAddedRecords(), 2000)
          return
        }
      }
    },
    /*
    When items are newly added to the table a "timeCopied" property was set, and will cause
    a class to be added to those cells.  This function is called after a delay in order to
    remove that property, which in turn will stop the cells from being highlighted.
    */
    stopHighlightingNewlyAddedRecords () {
      var arr = this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries

      arr.forEach(record => {
        if (Date.now() - record.timeCopied > 1850) {
          record.timeCopied = undefined
        }
      })
    },
    deleteSelectedEntries () {
      var rowsDeleted = 0
      this.selectedEntries.forEach(entry => {
        rowsDeleted++
        this.deleteEntry(entry, false)
      })
      this.iQuoteFunctions_ShowMessage(rowsDeleted + ' row(s) deleted')
      this.sendChangesToParent()
    },
    duplicateSelectedEntries () {
      var rowsAdded = 0
      this.selectedEntries.forEach(entry => {
        if (this.numDuplicatePostcodeEntries === 0) {
          for (let index = 0; index < this.circuitsHostingAndCablingTableEditorComponent.duplicateCopies; index++) {
            this.duplicateEntry(entry, null, false)
            rowsAdded++
          }
        } else {
          for (let i = 0; i < this.duplicatePostcodeEntries.length; i++) {
            const postcode = this.duplicatePostcodeEntries[i]
            for (let j = 0; j < this.circuitsHostingAndCablingTableEditorComponent.duplicateCopies; j++) {
              this.duplicateEntry(entry, postcode, false)
              rowsAdded++
            }
          }
        }
      })
      this.iQuoteFunctions_ShowMessage(rowsAdded + ' row(s) added')
      this.sendChangesToParent()
    },
    unselectAllEntries () {
      this.selectedEntries.forEach(entry => {
        entry.selected = false
      })
    },
    selectAllEntries () {
      var array = this.circuitsHostingAndCablingTableEditorComponent.circuitsHostingAndCablingEntries
      for (let index = 0; index < array.length; index++) {
        const entry = array[index]
        if (!this.isBlankEntry(entry)) {
          entry.selected = true
        }
      }
    }
  }
}
</script>
<style scoped>
  table.iquoteDesignpackTable tr{
    height: 38px;
  }

  #mainContainer::after, #duplicateSelectedItemsEditorContainer::before {
    content: "";
    display: table;
    clear: both;
  }

  #mainTable tr, #mainTable td {
    transition: background .5s ease-in;;
    -moz-transition: background .5s ease-in;
    -o-transition: background .5s ease-in;
    -webkit-transition: background .5s ease-in;
  }

  #mainTable tr.isNewRow, #marginsTable tr.isNewRow {
    background-color: burlywood;
  }

  #mainTable tr.isNewRow td.readOnlyCell, #marginsTable tr.isNewRow td.readOnlyCell {
    background-color: chocolate;
  }

  #mainTableContainer {
    width: calc(100% - 875px);
    display: inline;
    float: left;
    padding: 0;
    overflow-x: scroll;
  }

  #marginsTableContainer {
    width: 875px;
    display: inline;
    float: right;
  }

  #ItemsEditorContainer {
    margin-top: 32px;
    float: left;
    text-align: left;
    width: 100%;
  }

  #duplicateSelectedItemsEditorContainer {
    margin-top: 32px;
    float: left;
    text-align: left;
    width: 100%;
    border: 1px dashed black;
    padding: 7px;
  }

  #mainTable {
    width: auto;
    table-layout: fixed;
    border: none;
    margin: 0;
  }

  #marginsTable {
    width: 100%;
    margin: 0;
    table-layout: auto;
    border: none;
  }

  #mainTable th, #mainTable td, #marginsTable th, #marginsTable td {
    white-space:nowrap;
    height: 37px;
    text-align: center;
  }

  #marginsTable tr.data td.fixedSpacer {
    width: 15px;
    background-color: white;
  }

  #marginsTable tr td.buttonsCol {
    width: 15px;
  }

  table input[type=text], table input[type=number], table input[type=checkbox], table input[type=button], table select {
    border: none;
    margin: 0;
    height: 25px;
    font-size: 1em;
  }

  table input[type=number].quantityInput {
    width: 50px;
  }

  #mainTable tr.disabled td,  #marginsTable tr.disabled td {
    background-color: silver;
  }

  tr.mainHeader {
    text-align: center;
    font-size: 1.5em;
  }

  #mainTable tr.data:nth-child(1) {
    width: 100px;
  }

  #marginsTable tr.data td {
    width: 55px;
  }

  #marginsTable tr.data td input[type=number] {
    width: 50px;
  }
  .readOnlyCell {
    background-color: silver;
  }

  .noborder {
    border: none;
  }

  #mainTable tr td.NotApplicableMargin {
    text-align: center;
    font-style: italic;
    background-color: silver;
  }
</style>
