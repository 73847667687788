<template>
  <div id="mainContainer">
    <pagination
      :index="paginationComponentIndex"
      :page-length="pageLength"
      :margin-top="-10"
      :margin-bottom="3"
      :has-replica-in-the-same-page="hasReplicaInTheSamePage"
      @pageChanged="pageChanged"
      @firstClicked="firstClicked"
      @prevClicked="prevClicked"
      @nextClicked="nextClicked"
      @lastClicked="lastClicked"
      @searchText="searchText"
    />
    <div id="mainTableContainer">
      <table
        id="mainTable"
        class="iquoteDesignpackTable noTableShading">
        <tr class="mainHeader">
          <th colspan="14">&nbsp;</th>
        </tr>
        <tr class="mainHeader">
          <th colspan="1">&nbsp;</th>
          <th colspan="2">A End</th>
          <th colspan="2">B End</th>
          <th colspan="6">&nbsp;</th>
          <th
            colspan="1">Class Of Service</th>
          <th colspan="1">Contract</th>
        </tr>
        <tr class="subHeader">
          <th>&nbsp;</th>
          <th>Name</th>
          <th>Postcode</th>
          <th>Name</th>
          <th>Postcode</th>
          <th>ALK Used?</th>
          <th>Quote Valid To Date</th>
          <th>Carrier</th>
          <th>Product</th>
          <th>AT Code</th>
          <th>PSTN Required</th>
          <th>Circuit Type</th>
          <th v-if="false">EF46</th>
          <th v-if="false">AF4x</th>
          <th v-if="false">CD</th>
          <th v-if="false">Attainable Bandwidth</th>
          <th>Contract Term<br>(Months)</th>
        </tr>
        <tbody
          v-for="circuitsBroadbandEntry in circuitsBroadbandTableEditorComponent.currentPageEntries"
          :key="circuitsBroadbandEntry.id"
          :class="{ opaque: !circuitsBroadbandTableEditorComponent.showData }">
          <tr
            v-if="!isBlankEntry(circuitsBroadbandEntry) || (!isReadOnly && isBlankEntry(circuitsBroadbandEntry))"
            :class="{isNewRow: circuitsBroadbandEntry.timeCopied !== undefined, disabled: circuitsBroadbandEntry.disabled, deletedProductRow: hasDeletedProduct(circuitsBroadbandEntry)}"
            class="data"
          >
            <td>
              <input
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
                :id="'selectedInput' + circuitsBroadbandEntry.id"
                v-model.number="circuitsBroadbandEntry.selected"
                :disabled="isReadOnly"
                class=""
                type="checkbox">
            </td>
            <td>
              <input
                :id="'a_EndNameInput' + circuitsBroadbandEntry.id"
                v-model="circuitsBroadbandEntry.a_EndName"
                :disabled="isReadOnly"
                :list="'a_EndNameData' + circuitsBroadbandEntry.id"
                class="a_EndNameInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsBroadbandEntry, $event)"
                @change="onAEndChanged($event, circuitsBroadbandEntry)">
              <datalist
                :id="'a_EndNameData' + circuitsBroadbandEntry.id">
                <option
                  v-for="aEnd in iQuoteCalculationsDesignPackCircuitsMixin.colocationAndAEnds"
                  :key="aEnd.id"
                  :value="aEnd.description" />
              </datalist>
            </td>
            <td>
              <input
                :id="'a_EndPostcodeInput' + circuitsBroadbandEntry.id"
                v-model="circuitsBroadbandEntry.a_EndPostcode"
                :disabled="isReadOnly"
                class="a_EndPostcodeInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsBroadbandEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'b_EndNameInput' + circuitsBroadbandEntry.id"
                v-model="circuitsBroadbandEntry.b_EndName"
                :disabled="isReadOnly"
                class="b_EndNameInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsBroadbandEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'b_EndPostcodeInput' + circuitsBroadbandEntry.id"
                v-model="circuitsBroadbandEntry.b_EndPostcode"
                :disabled="isReadOnly"
                class="b_EndPostcodeInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsBroadbandEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'alk_UsedInput' + circuitsBroadbandEntry.id"
                v-model="circuitsBroadbandEntry.alk_Used"
                :disabled="isReadOnly"
                class="alk_UsedInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsBroadbandEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <input
                :id="'quoteValidToDateInput' + circuitsBroadbandEntry.id"
                v-model="circuitsBroadbandEntry.quoteValidToDate"
                :disabled="isReadOnly"
                class="quoteValidToDateInput"
                type="text"
                @blur="onInputItemFocusLost(circuitsBroadbandEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <select
                :id="'CarrierInput' + circuitsBroadbandEntry.id"
                :class="{invalidInput: circuitsBroadbandEntry.designPackCircuitsBroadbandCarrierId === 0}"
                :disabled="isReadOnly"
                v-model="circuitsBroadbandEntry.designPackCircuitsBroadbandCarrierId"
                class="CarrierInput"
                @blur="onInputItemFocusLost(circuitsBroadbandEntry, $event)"
                @change="onCarrierChanged(circuitsBroadbandEntry)"
              >
                <option
                  v-if="circuitsBroadbandEntry.designPackCircuitsBroadbandCarrierId === 0"
                  key="0"
                  value="0"
                  title="< Please Select >"
                >&lt; Please Select &gt;
                </option>
                <option
                  v-for="t in circuitsBroadbandTableEditorComponent.broadbandCarriers"
                  :key="t.id"
                  :value="t.id"
                  :class="{activeOption: !t.deleted, deletedBeingUsedOption: t.deleted && circuitsBroadbandEntry.designPackCircuitsBroadbandCarrierId === t.id, deletedNotBeingUsedOption: t.deleted && circuitsBroadbandEntry.designPackCircuitsBroadbandCarrierId !== t.id}"
                  :title="t.description"
                >{{ t.description }}
                </option>
              </select>
            </td>
            <td>
              <select
                v-if="circuitsBroadbandEntry.designPackCircuitsBroadbandCarrierId !== 0"
                :id="'CarrierInput' + circuitsBroadbandEntry.id"
                :class="{invalidInput: circuitsBroadbandEntry.designPackCircuitsBroadbandProductId === 0}"
                :disabled="isReadOnly"
                v-model="circuitsBroadbandEntry.designPackCircuitsBroadbandProductId"
                class="CarrierInput"
                @blur="onInputItemFocusLost(circuitsBroadbandEntry, $event)"
                @change="onBroadbandProductChanged(circuitsBroadbandEntry)"
              >
                <option
                  v-if="circuitsBroadbandEntry.designPackCircuitsBroadbandProductId === 0"
                  key="0"
                  value="0"
                  title="< Please Select >"
                >&lt; Please Select &gt;
                </option>
                <option
                  v-for="t in circuitsBroadbandTableEditorComponent.broadbandProducts.filter(x => x.designPackCircuitsBroadbandCarrierId === circuitsBroadbandEntry.designPackCircuitsBroadbandCarrierId)"
                  :key="t.id"
                  :value="t.id"
                  :title="t.deleted ? '(DELETED PRODUCT)' : t.description"
                  :class="{activeOption: !t.deleted, deletedBeingUsedOption: t.deleted && circuitsBroadbandEntry.designPackCircuitsBroadbandProductId === t.id, deletedNotBeingUsedOption: t.deleted && circuitsBroadbandEntry.designPackCircuitsBroadbandProductId !== t.id }"
                >{{ t.description }}
                </option>
              </select>
            </td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">{{ iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductAtCode (circuitsBroadbandEntry.designPackCircuitsBroadbandProductId) }}</span></td>
            <td>
              <input
                :id="'pstnRequiredInput' + circuitsBroadbandEntry.id"
                v-model.number="circuitsBroadbandEntry.pstnRequired"
                :disabled="isReadOnly"
                class="pstnRequiredInput"
                type="checkbox"
                @blur="onInputItemFocusLost(circuitsBroadbandEntry, $event)"
                @change="sendChangesToParent()">
            </td>
            <td>
              <span v-if="!isBlankEntry(circuitsBroadbandEntry)">{{ circuitsBroadbandTableEditorComponent.circuitType }}</span>
            </td>
            <td v-if="false">
              <input
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
                :id="'eF46Input' + circuitsBroadbandEntry.id"
                v-model.number="circuitsBroadbandEntry.eF46"
                :class="{invalidInput: circuitsBroadbandEntry.eF46 < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td v-if="false">
              <input
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
                :id="'eF4xInput' + circuitsBroadbandEntry.id"
                v-model.number="circuitsBroadbandEntry.eF4x"
                :class="{invalidInput: circuitsBroadbandEntry.eF4x < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td v-if="false">
              <input
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
                :id="'cdInput' + circuitsBroadbandEntry.id"
                v-model.number="circuitsBroadbandEntry.cd"
                :class="{invalidInput: circuitsBroadbandEntry.cd < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td v-if="false">
              <input
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
                :id="'attainableBandwidthInput' + circuitsBroadbandEntry.id"
                v-model.number="circuitsBroadbandEntry.attainableBandwidth"
                :class="{invalidInput: circuitsBroadbandEntry.attainableBandwidth < 0}"
                :disabled="isReadOnly"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">{{ iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductTermLengthMonths (circuitsBroadbandEntry.designPackCircuitsBroadbandProductId) }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div id="marginsTableContainer">
      <table
        id="marginsTable"
        class="iquoteDesignpackTable noTableShading">
        <tr class="mainHeader">
          <th colspan="6">&nbsp;</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th
            id="capexMarginColumn"
            colspan="3">Capex Sales Margin</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th
            id="opexMarginColumn"
            colspan="3">Opex Sales Margin</th>
        </tr>
        <tr class="mainHeader">
          <th colspan="1">&nbsp;</th>
          <th colspan="2">Install</th>
          <th colspan="2">Rental</th>
          <th colspan="1">&nbsp;</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th
            id="capexMarginColumn"
            colspan="3">
            <div
              v-if="!isReadOnly"
              class="verticalAlignTop"
            >
              <input
                id="chkOverrideCapexMargin"
                v-model.number="circuitsBroadbandTableEditorComponent.overrideCapexMargin"
                type="checkbox"
              >
              <input
                v-show="circuitsBroadbandTableEditorComponent.overrideCapexMargin"
                id="txtCapexMarginOverride"
                v-model.number="iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandCapexPercent"
                :class="{invalidInput: (iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandCapexPercent < 0 || iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandCapexPercent >= 100)}"
                :disabled="isReadOnly"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                max="99"
                class="marginInput marginOverrideTextboxInTableHeader"
                @change="handleCapexMarginChange($event)">
              <span v-if="!circuitsBroadbandTableEditorComponent.overrideCapexMargin">Override Margin</span>
              <span v-if="circuitsBroadbandTableEditorComponent.overrideCapexMargin">%</span>
            </div>
          </th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th
            id="opexMarginColumn"
            colspan="3">
            <div
              v-if="!isReadOnly"
              class="verticalAlignTop"
            >
              <input
                id="chkOverrideOpexMargin"
                v-model.number="circuitsBroadbandTableEditorComponent.overrideOpexMargin"
                type="checkbox"
              >
              <input
                v-show="circuitsBroadbandTableEditorComponent.overrideOpexMargin"
                id="txtOpexMarginOverride"
                v-model.number="iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandOpexPercent"
                :class="{invalidInput: (iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandOpexPercent < 0 || iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandOpexPercent >= 100)}"
                :disabled="isReadOnly"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                max="99"
                class="marginInput marginOverrideTextboxInTableHeader"
                @change="handleOpexMarginChange($event)">
              <span v-if="!circuitsBroadbandTableEditorComponent.overrideOpexMargin">Override Margin</span>
              <span v-if="circuitsBroadbandTableEditorComponent.overrideOpexMargin">%</span>
            </div>
          </th>
        </tr>
        <tr class="subHeader">
          <th>Quantity</th>
          <th>Buy<br>(Each)</th>
          <th>Cost<br>(Total)</th>
          <th>Monthly Buy<br>(Each)</th>
          <th>Annual Cost<br>(Total)</th>
          <th class="buttonsCol">&nbsp;</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th>Margin %</th>
          <th>Margin</th>
          <th>Sale Price</th>
          <td class="noborder fixedSpacer">&nbsp;</td>
          <th>Margin %</th>
          <th>Margin</th>
          <th>Sale Price</th>
        </tr>
        <tbody
          v-for="circuitsBroadbandEntry in circuitsBroadbandTableEditorComponent.currentPageEntries"
          :key="circuitsBroadbandEntry.id"
          :class="{ opaque: !circuitsBroadbandTableEditorComponent.showData }">
          <tr
            v-if="!isBlankEntry(circuitsBroadbandEntry) || (!isReadOnly && isBlankEntry(circuitsBroadbandEntry))"
            :class="{isNewRow: circuitsBroadbandEntry.timeCopied !== undefined, disabled: circuitsBroadbandEntry.disabled, deletedProductRow: hasDeletedProduct(circuitsBroadbandEntry)}"
            class="data"
          >
            <td>
              <input
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
                :id="'quantityInput' + circuitsBroadbandEntry.id"
                v-model.number="circuitsBroadbandEntry.quantity"
                :class="{invalidInput: circuitsBroadbandEntry.quantity === 0}"
                :disabled="isReadOnly"
                class="quantityInput"
                type="number"
                @change="sendChangesToParent()">
            </td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">£{{ circuitsBroadbandEntry.costUpfront.toFixed(2) }}</span></td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">£{{ getEntryUpfrontCost(circuitsBroadbandEntry).toFixed(2) }}</span></td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">£{{ circuitsBroadbandEntry.costMonthly.toFixed(2) }}</span></td>
            <td class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">£{{ getEntryAnnualCost(circuitsBroadbandEntry).toFixed(2) }}</span></td>
            <td class="buttonsCol">
              <button
                v-if="!isBlankEntry(circuitsBroadbandEntry) && !isReadOnly && !circuitsBroadbandEntry.disabled"
                :id="'btnDisable' + circuitsBroadbandEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Disable this entry so that the costs and prices are NOT INCLUDED in the design pack and quote"
                @click="disableEntry(circuitsBroadbandEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconPause"
                  alt="remove">
              </button>
              <button
                v-if="!isBlankEntry(circuitsBroadbandEntry) && !isReadOnly && circuitsBroadbandEntry.disabled"
                :id="'btnDisable' + circuitsBroadbandEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Enable this entry so that the costs and prices are included in the design pack and quote"
                @click="enableEntry(circuitsBroadbandEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconResume"
                  alt="insert">
              </button>
              <button
                v-if="!isBlankEntry(circuitsBroadbandEntry) && !isReadOnly && circuitsBroadbandEntry.quantity !== 0"
                :id="'btnNegate' + circuitsBroadbandEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Invert the quantity of this entry.  If it's positive make it negative and vice-versa"
                @click="negateEntry(circuitsBroadbandEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconPlusMinus"
                  alt="negate">
              </button>
              <button
                v-if="!isBlankEntry(circuitsBroadbandEntry) && !isReadOnly"
                :id="'btnDelete' + circuitsBroadbandEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Delete this entry"
                style="margin-left: 15px"
                @click="deleteEntry(circuitsBroadbandEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconDelete"
                  alt="delete">
              </button>
            </td>
            <td class="noborder fixedSpacer">&nbsp;</td>
            <td
              v-if="getEntryUpfrontCost(circuitsBroadbandEntry) === 0"
              class="NotApplicableMargin"
              colspan="3"
            >
              <span
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
              >Not Applicable
              </span>
            </td>
            <td
              v-if="getEntryUpfrontCost(circuitsBroadbandEntry) !== 0 && circuitsBroadbandEntry.priceOverrideOO === null"
            >
              <input
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
                :id="'unitCostInput' + circuitsBroadbandEntry.id"
                v-model.number="circuitsBroadbandEntry.marginCapexPercent"
                :class="{invalidInput: (circuitsBroadbandEntry.marginCapexPercent < 0 || circuitsBroadbandEntry.marginCapexPercent >= 100)}"
                :disabled="isReadOnly || getEntryUpfrontCost(circuitsBroadbandEntry) === 0"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                class="marginInput"
                max="99"
                @change="sendChangesToParent()">
              <span v-if="!isBlankEntry(circuitsBroadbandEntry)">%</span>
            </td>
            <td
              v-if="getEntryUpfrontCost(circuitsBroadbandEntry) !== 0 && circuitsBroadbandEntry.priceOverrideOO !== null"
              class="readOnlyCell"
            >
              <span
                v-if="!isBlankEntry(circuitsBroadbandEntry)">
                {{ calcUpfrontMarginPercent(circuitsBroadbandEntry) }}
                <br>
                <TooltipIcon
                  style="margin-left: -8px"
                  width="200"
                  align="right"
                  text="Price was set on Detailed Summary Tab" />
              </span>
            </td>
            <td
              v-if="getEntryUpfrontCost(circuitsBroadbandEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">£{{ getEntryCapexMargin(circuitsBroadbandEntry).toFixed(2) }}</span></td>
            <td
              v-if="getEntryUpfrontCost(circuitsBroadbandEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">£{{ getEntryCapexSalePrice(circuitsBroadbandEntry).toFixed(2) }}</span></td>
            <td class="noborder fixedSpacer">&nbsp;</td>
            <td
              v-if="getEntryAnnualCost(circuitsBroadbandEntry) === 0"
              class="NotApplicableMargin"
              colspan="3"
            >
              <span
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
              >Not Applicable
              </span>
            </td>
            <td
              v-if="getEntryAnnualCost(circuitsBroadbandEntry) !== 0 && circuitsBroadbandEntry.priceOverrideAR === null"
            >
              <input
                v-if="!isBlankEntry(circuitsBroadbandEntry)"
                :id="'unitCostInput' + circuitsBroadbandEntry.id"
                v-model.number="circuitsBroadbandEntry.marginOpexPercent"
                :class="{invalidInput: (circuitsBroadbandEntry.marginOpexPercent < 0 || circuitsBroadbandEntry.marginOpexPercent >= 100)}"
                :disabled="isReadOnly || getEntryAnnualCost(circuitsBroadbandEntry) === 0"
                :min="designPackTableSection_Technology.upfrontExternalIquoteUserMinMargin"
                type="number"
                step="any"
                class="marginInput"
                max="99"
                @change="sendChangesToParent()">
              <span v-if="!isBlankEntry(circuitsBroadbandEntry)">%</span>
            </td>
            <td
              v-if="getEntryAnnualCost(circuitsBroadbandEntry) !== 0 && circuitsBroadbandEntry.priceOverrideAR !== null"
              class="readOnlyCell"
            >
              <span
                v-if="!isBlankEntry(circuitsBroadbandEntry)">
                {{ calcRecurringMarginPercent(circuitsBroadbandEntry) }}
                <br>
                <TooltipIcon
                  style="margin-left: -8px"
                  width="200"
                  align="right"
                  text="Price was set on Detailed Summary Tab" />
              </span>
            </td>
            <td
              v-if="!isBlankEntry(circuitsBroadbandEntry) && getEntryAnnualCost(circuitsBroadbandEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">£{{ getEntryOpexMargin(circuitsBroadbandEntry).toFixed(2) }}</span></td>
            <td
              v-if="!isBlankEntry(circuitsBroadbandEntry) && getEntryAnnualCost(circuitsBroadbandEntry) !== 0"
              class="readOnlyCell"><span v-if="!isBlankEntry(circuitsBroadbandEntry)">£{{ getEntryOpexSalePrice(circuitsBroadbandEntry).toFixed(2) }}</span></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="selectedEntries.length > 0 && !isReadOnly">
      <div id="ItemsEditorContainer">
        <input
          id="selectAll"
          value="Select all Entries"
          type="button"
          @click="selectAllEntries()"
        >
        <input
          id="unselectAll"
          value="Unselect all Entries"
          type="button"
          @click="unselectAllEntries()"
        >
        <input
          id="deleteSelected"
          value="Delete Selected Entries"
          type="button"
          @click="deleteSelectedEntries()"
        >
      </div>
      <div id="duplicateSelectedItemsEditorContainer">
        <strong>Duplicate Selected Entries</strong>
        <br><br>
        <div v-if="selectedEntries.length === 1">The selected entry will be copied.</div>
        <div v-if="selectedEntries.length > 1">The {{ selectedEntries.length }} selected entries will be copied.</div>
        <br><br>
        This will be repeated&nbsp;
        <input
          id="duplicateCopies"
          v-model.number="circuitsBroadbandTableEditorComponent.duplicateCopies"
          :class="{invalidInput: circuitsBroadbandTableEditorComponent.duplicateCopies < 0}"
          type="number">
        &nbsp;times and will result in {{ numEntriesThatWillBeDuplicated }} new item(s)
        <br>
        <input
          id="duplicateSelected"
          value="OK"
          type="button"
          @click="duplicateSelectedEntries()"
        >
      </div>
    </div>
    <pagination
      :index="paginationComponentIndex"
      :page-length="pageLength"
      :has-replica-in-the-same-page="hasReplicaInTheSamePage"
      @pageChanged="pageChanged"
      @firstClicked="firstClicked"
      @prevClicked="prevClicked"
      @nextClicked="nextClicked"
      @lastClicked="lastClicked"
      @searchText="searchText"
    />
  </div>
</template>

<script>

import { iQuoteStoreMixin } from '../../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackCircuitsMixin } from '../../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackCircuits'
import { iQuoteImagesMixin } from '../../../../mixins/iQuote/Globals/iQuoteImages'
import pagination from '../../../common/pagination'
import TooltipIcon from '../../../common/tooltipIcon'

import { DataEventBus } from '../../../../events/dataEvents'
import { iQuoteEventBus } from '../../../../events/iQuoteEvents'

import { mapActions } from 'vuex'

export default {
  name: 'CircuitsBroadbandTableEditor',
  components: {
    pagination,
    TooltipIcon
  },
  mixins: [ iQuoteStoreMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsDesignPackCircuitsMixin, iQuoteImagesMixin ],
  props: {
  },
  data () {
    return {
      paginationComponentIndex: 1,
      hasReplicaInTheSamePage: true,
      maxNumberOfEntries: 2000,
      circuitsBroadbandTableEditorComponent: {        
        broadbandCarriers: [],
        broadbandProducts: [],
        currentPage: 0,
        pageLength: 20,
        cachedEntries: [],
        currentPageEntries: [],
        filteredEntries: [],
        circuitType: 'ADSL',
        filter: '',
        uniqueId: 0,
        quoteDesignPackRevisionId: 0,
        duplicateCopies: 1,
        overrideCapexMargin: false,
        overrideOpexMargin: false,
        animatePageTransitions: true,
        showData: true
      }
    }
  },
  computed: {
    isReadOnly () {
      return this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.isReadOnly
    },
    selectedEntries () {
      return this.circuitsBroadbandTableEditorComponent.currentPageEntries.filter(x => x.selected === true)
    },
    cachedEntries () {
      return this.circuitsBroadbandTableEditorComponent.cachedEntries
    },
    filteredEntries () {
      return this.circuitsBroadbandTableEditorComponent.filteredEntries
    },
    filter () {
      return this.circuitsBroadbandTableEditorComponent.filter
    },
    hasFilter () {
      return this.circuitsBroadbandTableEditorComponent.filter !== ''
    },
    numEntriesThatWillBeDuplicated () {
      return this.circuitsBroadbandTableEditorComponent.duplicateCopies * this.selectedEntries.length
    },
    currentPage () {
      return this.circuitsBroadbandTableEditorComponent.currentPage
    },
    pageLength () {
      return this.circuitsBroadbandTableEditorComponent.pageLength
    },
    totalPages () {
      let totalEntries = 0
      if (this.hasFilter) {
        totalEntries = this.filteredEntries
      } else {
        totalEntries = this.cachedEntries
      }
      return Math.ceil(totalEntries.length / this.pageLength)
    },
    designPackTableSection_Technology () {
      return this.iQuoteStore_GetDesignPackTableSections.find(x => x.id === this.iQuoteConstants.designPackTableSections.TECH)
    }
  },
  watch: {
    currentPage () {
      this.refreshCurrentPageData()
      this.refreshPaginationTab()
    },
    totalPages () {
      if (this.currentPage === this.totalPages) {
        this.circuitsBroadbandTableEditorComponent.currentPage = this.currentPage - 1
      }
    },
    filter () {
      this.refreshCurrentPageData()
      this.refreshPaginationTab()
      if (this.totalPages > 0) {
        this.circuitsBroadbandTableEditorComponent.currentPage = 0
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, 1)
      }
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.designPack,
      (newValue) => {
        this.initialise(newValue)
      }
    )

    this.initialise(this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack)
  },
  async created () {
    iQuoteEventBus.$on('designpack-priceOverridesChanged', async (designPack) => {
      var forceReload = true
      await this.initialise(designPack, forceReload)
    })
  },
  async destroyed () {
    iQuoteEventBus.$off('designpack-priceOverridesChanged')
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    
    populateCarriers (designPack) {
      this.circuitsBroadbandTableEditorComponent.broadbandCarriers = []

      // populate the carrier dropdown
      this.iQuoteStore_GetCircuitsTableLookups.broadbandCarriers.forEach(carrier => {
        const carrierProducts = this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts.filter(x => x.designPackCircuitsBroadbandCarrierId === carrier.id)
        const carrierDeletedProducts = carrierProducts.filter(x => x.deleted === true)
        if (!carrierDeletedProducts || (carrierProducts.length > carrierDeletedProducts.length)) {          
          this.circuitsBroadbandTableEditorComponent.broadbandCarriers.push({ ...carrier, deleted: false }) // add the carrier with no deleted products
        } else {
          carrierDeletedProducts.forEach(product => {
            if (designPack.circuitsBroadbandEntries.find(x => x.designPackCircuitsBroadbandProductId === product.id) !== undefined) { // a deleted product has been found in the quote
              if (this.circuitsBroadbandTableEditorComponent.broadbandCarriers.find(x => x.id === carrier.id) === undefined) {
                this.circuitsBroadbandTableEditorComponent.broadbandCarriers.push({ ...carrier, deleted: true }) // add the carrier with a deleted product
              }
            }
          })
        }
      })
    },
    populateProducts (designPack) {
      this.circuitsBroadbandTableEditorComponent.broadbandProducts = []

      // populate the product dropdown
      let deletedProductsCount = 0
      this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts.forEach(product => {
        const deletedProductFoundInQuote = product.deleted && designPack.circuitsBroadbandEntries.find(x => x.designPackCircuitsBroadbandProductId === product.id) // a deleted product has been found in the quote
        if (!product.deleted || deletedProductFoundInQuote) {
          this.circuitsBroadbandTableEditorComponent.broadbandProducts.push(product)
          if (deletedProductFoundInQuote) deletedProductsCount++
        }
      })

      const isLatestRevision = designPack.revisionNumber === designPack.revisions.length
      if (isLatestRevision && deletedProductsCount > 0) {
        this.displayDeletedProductsError(deletedProductsCount)
      }
    },
    displayDeletedProductsError (deletedCount) {
      const errorMessage = deletedCount === 1 ? `There is a deleted product in Circuits - Broadband section. Please remove it or switch to a valid product` : `There are ${deletedCount} deleted products in Circuits - Broadband section. Please remove them or switch to a valid product`
      this.iQuoteFunctions_ShowError(errorMessage)
    },
    async initialise (designPack, forceReload = false) {
      if (designPack.quoteDesignPackRevisionId === undefined) {
        // There is no design pack so nothing we can do here
        return
      }

      if (this.circuitsBroadbandTableEditorComponent.quoteDesignPackRevisionId === designPack.quoteDesignPackRevisionId) {
        //  The designpack that just saved, is the one we are currently looking at

        if (this.currentPage !== 0) this.circuitsBroadbandTableEditorComponent.currentPage = 0

        if (!forceReload) {
          // DP is the same one as before, and we aren't forcing a reload. So just return
          return
        }
      }      
      this.populateCarriers(designPack)
      this.populateProducts(designPack)

      this.circuitsBroadbandTableEditorComponent.quoteDesignPackRevisionId = designPack.quoteDesignPackRevisionId

      if (designPack.isReadOnly === false) {
        await this.iQuoteFunctions_delay(2000) // Wait a while for the DP to finish loading
        // Update entries to latest costs from the lookups table
        this.circuitsBroadbandTableEditorComponent.cachedEntries = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.circuitsBroadbandEntries
        if (this.circuitsBroadbandTableEditorComponent.cachedEntries) {
          this.circuitsBroadbandTableEditorComponent.cachedEntries.forEach(entry => {
            entry.costUpfront = this.iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductUpfrontCost(entry.designPackCircuitsBroadbandProductId)
            entry.costMonthly = this.iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductRecurringCost(entry.designPackCircuitsBroadbandProductId)
          })
        }
        // this.$emit('tableUpdated', this.circuitsBroadbandTableEditorComponent.cachedEntries)
      }

      if (this.currentPage !== 0) this.circuitsBroadbandTableEditorComponent.currentPage = 0
      this.initialiseData()
    },
    initialiseData () {
      this.refreshCurrentPageData()
      this.refreshPaginationTab()
      this.clearFilter()
    },
    calcUpfrontMarginPercent (entry) {
      var cost = entry.costUpfront * entry.quantity
      var price = this.getEntryCapexSalePrice(entry)
      var priceOverride = entry.priceOverrideOO

      if (priceOverride !== null) {
        price = priceOverride * entry.quantity
      }

      var profit = price - cost
      var margin = (profit / price) * 100

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(margin) + '%'
    },
    calcRecurringMarginPercent (entry) {
      var cost = entry.costMonthly * 12 * entry.quantity
      var price = this.getEntryOpexSalePrice(entry)
      var profit = price - cost
      var margin = (profit / price) * 100

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(margin) + '%'
    },
    refreshCurrentPageData () { // mount current page - triggered on page change, search and delete
      if (!this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.circuitsBroadbandEntries) return

      this.circuitsBroadbandTableEditorComponent.uniqueId = 0
      this.circuitsBroadbandTableEditorComponent.cachedEntries = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.circuitsBroadbandEntries.map(array => ({ ...array, uniqueId: ++this.circuitsBroadbandTableEditorComponent.uniqueId, selected: false }))

      const offset = this.currentPage * this.pageLength
      if (!this.hasFilter) {
        this.circuitsBroadbandTableEditorComponent.currentPageEntries = this.cachedEntries.slice(offset, offset + this.pageLength)
      } else {
        this.circuitsBroadbandTableEditorComponent.filteredEntries = []
        this.cachedEntries.forEach(element => {
          var foundOne = false
          for (var key in element) {
            if (key === 'id' || key === 'uniqueId' || key === 'disabled') continue
            // carrier lookup (dropdown):
            if (key === 'designPackCircuitsBroadbandCarrierId') {
              if (this.searchInDescriptions(this.iQuoteStore_GetCircuitsTableLookups.broadbandCarriers, element.designPackCircuitsBroadbandCarrierId, this.filter)) {
                foundOne = true
              }
            // product lookup (dropdown):
            } else if (key === 'designPackCircuitsBroadbandProductId') {
              if (this.searchInDescriptions(this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts, element.designPackCircuitsBroadbandProductId, this.filter)) {
                foundOne = true
              }
            // other
            } else if (element[key] && element[key].toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
              foundOne = true
            }
          }
          if (!foundOne) {
            // search in a fixed column (Circuit Type)
            foundOne = this.searchInFixedColumn(this.circuitsBroadbandTableEditorComponent.circuitType)
          }
          if (!foundOne) {
            // search in a fixed column (AT Code)
            foundOne = this.searchInFixedColumn(this.iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductAtCode(element.designPackCircuitsBroadbandProductId))
          }
          if (!foundOne) {
            foundOne = this.searchInCalculatedColumns(element)
          }

          if (foundOne) { this.circuitsBroadbandTableEditorComponent.filteredEntries.push(element) }
        })
        this.circuitsBroadbandTableEditorComponent.currentPageEntries = this.filteredEntries.slice(offset, offset + this.pageLength)
      }

      this.addBlankEntry()
    },
    searchInDescriptions (objectToSearch, id, filter) {
      // find item in dropdowns
      const item = objectToSearch.find((el) => el.id === id)
      if (item !== undefined && item.description.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        return true
      }

      return false
    },
    searchInFixedColumn (value) {
      return value.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
    },
    searchInCalculatedColumns (element) {
      // cost (total):
      if (this.getEntryUpfrontCost(element).toFixed(2).toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
        return true
      }
      // annual cost (total):
      if (this.getEntryAnnualCost(element).toFixed(2).toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
        return true
      }
      // capex margin lookup:
      if (this.getEntryCapexMargin(element).toFixed(2).toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
        return true
      }
      // capex sale price lookup:
      if (this.getEntryCapexSalePrice(element).toFixed(2).toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
        return true
      }
      // opex margin lookup:
      if (this.getEntryOpexMargin(element).toFixed(2).toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
        return true
      }
      // opex sale price lookup:
      if (this.getEntryOpexSalePrice(element).toFixed(2).toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
        return true
      }
    },
    refreshPaginationTab () {
      let numberOfEntries
      if (this.hasFilter) {
        numberOfEntries = this.filteredEntries.length
      } else {
        numberOfEntries = this.cachedEntries.length
      }
      DataEventBus.$emit(`refreshTotalEntries${this.paginationComponentIndex}`, numberOfEntries)
    },
    clearFilter () {
      this.circuitsBroadbandTableEditorComponent.filter = ''
      DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'filter', true, '')
    },
    handleCapexMarginChange (event) {
      this.circuitsBroadbandTableEditorComponent.currentPageEntries.forEach(entry => {
        entry.marginCapexPercent = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandCapexPercent
      })

      this.circuitsBroadbandTableEditorComponent.cachedEntries.forEach(entry => {
        entry.marginCapexPercent = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandCapexPercent
      })

      this.sendChangesToParent()
    },
    handleOpexMarginChange (event) {
      this.circuitsBroadbandTableEditorComponent.currentPageEntries.forEach(entry => {
        entry.marginOpexPercent = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandOpexPercent
      })

      this.circuitsBroadbandTableEditorComponent.cachedEntries.forEach(entry => {
        entry.marginOpexPercent = this.iQuoteCalculationsDesignPackCircuitsMixin_selectedDesignPack.marginCircuitsBroadbandOpexPercent
      })
      this.sendChangesToParent()
    },
    getEntryUpfrontCost (entry) {
      if (this.isBlankEntry(entry)) {
        return 0
      }

      return entry.costUpfront * entry.quantity
    },
    getEntryAnnualCost (entry) {
      if (this.isBlankEntry(entry)) {
        return 0
      }

      return entry.costMonthly * entry.quantity * 12
    },
    getEntryCapexMargin (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      return this.getEntryCapexSalePrice(entry) - this.getEntryUpfrontCost(entry)
    },
    getEntryCapexSalePrice (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      var price = this.getEntryUpfrontCost(entry) / ((100 - entry.marginCapexPercent) / 100)
      var priceOverride = entry.priceOverrideOO

      if (priceOverride !== null) {
        price = priceOverride * entry.quantity
      }

      return price
    },
    getEntryOpexMargin (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      return this.getEntryOpexSalePrice(entry) - this.getEntryAnnualCost(entry)
    },
    getEntryOpexSalePrice (entry) {
      if (this.isBlankEntry(entry)) {
        return
      }

      var price = this.getEntryAnnualCost(entry) / ((100 - entry.marginOpexPercent) / 100)
      var priceOverride = entry.priceOverrideAR

      if (priceOverride !== null) {
        price = priceOverride * entry.quantity
      }

      return price
    },
    onInputItemFocusLost (entry, event) {
      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }
    },
    onCarrierChanged (entry) {
    // If the carrier has changed, then we need to reset the products dropdown,
    // so the user must select a new product, before pricing will be calculated
      entry.costUpfront = 0
      entry.costMonthly = 0
      entry.designPackCircuitsBroadbandProductId = 0

      this.sendChangesToParent()
    },
    onBroadbandProductChanged (entry) {
      entry.costUpfront = this.iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductUpfrontCost(entry.designPackCircuitsBroadbandProductId)
      entry.costMonthly = this.iQuoteCalculationsDesignPackCircuitsMixin_getBroadbandProductRecurringCost(entry.designPackCircuitsBroadbandProductId)
      this.sendChangesToParent()
    },
    isBlankEntry (entry) {
      return entry.a_EndName === '' &&
        entry.a_EndPostcode === '' &&
        entry.b_EndName === '' &&
        entry.b_EndPostcode === '' &&
        entry.alk_Used === '' &&
        entry.quoteValidToDate === '' &&
        entry.designPackCircuitsBroadbandCarrierId === 0 &&
        entry.eF46 === 0 &&
        entry.eF4x === 0 &&
        entry.cd === 0 &&
        entry.attainableBandwidth === 0 &&
        entry.contractTermMonths === 0 &&
        entry.quantity === 0 &&
        entry.costUpfront === 0 &&
        entry.costMonthly === 0
    },
    hasBlankEntry () {
      var foundBlankEntry = false
      this.circuitsBroadbandTableEditorComponent.currentPageEntries.forEach(entry => {
        if (this.isBlankEntry(entry)) {
          foundBlankEntry = true
        }
      })

      return foundBlankEntry
    },
    canAddBlankEntry () {
      let hasBlankEntry = false
      if (this.circuitsBroadbandTableEditorComponent.currentPageEntries.length > 0) {
        const quantity = this.circuitsBroadbandTableEditorComponent.currentPageEntries[this.circuitsBroadbandTableEditorComponent.currentPageEntries.length - 1].quantity
        hasBlankEntry = (quantity === 0 || quantity === '')
      }

      return ((this.cachedEntries.length + (hasBlankEntry ? 1 : 0)) < this.maxNumberOfEntries)
    },
    addBlankEntry () {
      if (this.canAddBlankEntry()) {
        this.circuitsBroadbandTableEditorComponent.currentPageEntries.push(this.getBlankEntry())
      }
    },
    getBlankEntry () {
      return {
        id: this.getTempEntryId(),
        a_EndName: '',
        a_EndPostcode: '',
        b_EndName: '',
        b_EndPostcode: '',
        alk_Used: '',
        quoteValidToDate: '',
        designPackCircuitsBroadbandCarrierId: 0,
        designPackCircuitsBroadbandProductId: 0,
        pstnRequired: false,
        eF46: 0,
        eF4x: 0,
        cd: 0,
        attainableBandwidth: 0,
        contractTermMonths: 0,
        quantity: 0,
        costUpfront: 0,
        costMonthly: 0,
        marginCapexPercent: this.iQuoteConstants.salesMargins.CIRCUITS_DEFAULT_MARGIN,
        marginOpexPercent: this.iQuoteConstants.salesMargins.CIRCUITS_DEFAULT_MARGIN,
        disabled: false,
        uniqueId: ++this.circuitsBroadbandTableEditorComponent.uniqueId,
        designPackItemLookupId: this.iQuoteCalculationsDesignPackMixin_getDesignPackItemLookupId(),
        priceOverrideOO: null,
        priceOverrideAR: null
      }
    },
    sendChangesToParent () {
      var currentPageEntries = []
      var blankEntry = this.getBlankEntry()
      var array = this.circuitsBroadbandTableEditorComponent.currentPageEntries

      for (let index = 0; index < array.length; index++) {
        const entry = array[index]

        if (!this.isBlankEntry(entry)) {
          // Loop through all properties of the blank entry and compare against the entry to be
          // saved to check that the user hasn't entered a value of the wrong data type. This typically
          // will only happen if they deleted the contents of a numeric field. This would result in a empty
          // string being returned, and will cause a failure if we tried to save that to the DB, as it's not numeric
          for (const property in blankEntry) {
            if (typeof blankEntry[property] !== typeof entry[property] &&
                property !== 'priceOverrideOO' /* can be object (null) or a number */ &&
                property !== 'priceOverrideAR' /* can be object (null) or a number */) {
              // User has entered something of wrong datatype,
              // which will fail when saving, so reset it to default value

              console.log('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])
              this.iQuoteFunctions_ShowError('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])

              entry[property] = blankEntry[property] // Set to default
            }
          }
          currentPageEntries.push(entry)
        }
      }

      const cachedEntries = this.circuitsBroadbandTableEditorComponent.cachedEntries // original records

      currentPageEntries.forEach(pageEntry => {
        let cachedEntry = cachedEntries.find(entry => entry.uniqueId === pageEntry.uniqueId)
        if (cachedEntry === undefined) {
          cachedEntries.push(pageEntry) // adds the new record into the cached records
        } else {
          cachedEntry = pageEntry // updates the cached record with changes made to the record in the current page
        }
      })
      this.$emit('tableUpdated', cachedEntries)
      this.refreshPaginationTab()

      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }
    },
    onAEndChanged (event, entry) {
      var matchingColoAEnds = this.iQuoteCalculationsDesignPackCircuitsMixin.colocationAndAEnds.filter(x => x.description === event.target.value)

      if (matchingColoAEnds.length === 1) {
        entry.a_EndPostcode = matchingColoAEnds[0].postcode
      }
    },
    getTempEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.circuitsBroadbandTableEditorComponent.currentPageEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    deleteEntry (entry, sendChangesEvent = true) {
      var arr = this.circuitsBroadbandTableEditorComponent.currentPageEntries
      let cachedEntries = this.circuitsBroadbandTableEditorComponent.cachedEntries // original records

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === entry.id) {
          cachedEntries.splice(cachedEntries.findIndex(c => c.uniqueId === arr[i].uniqueId), 1)
          arr.splice(i, 1)
          if (sendChangesEvent) {
            this.sendChangesToParent()
            this.refreshCurrentPageData()
          }
          return
        }
      }
    },
    disableEntry (entry, sendChangesEvent = true) {
      entry.disabled = true
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    enableEntry (entry, sendChangesEvent = true) {
      entry.disabled = false
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    negateEntry (entry, sendChangesEvent = true) {
      entry.quantity = -entry.quantity
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    /*
    FUNCTION: duplicateEntry
    PARAMS:
      entry = the entry to be copied
      postcode = if null is copied from the entry, otherwise will override it
      sendChangesEvent = if true then will send event to parent component.  Typically you
                         will set this to false of batch copying (and send one event when
                         batch is done), and true if copying one item
    */
    duplicateEntry (entry, postCode = null, sendChangesEvent = true) {
      var newItem = {
      }

      /* Copy all properties from the item to be copied into the new item */
      for (var prop in entry) {
        if (prop !== 'uniqueId') {
          newItem[prop] = entry[prop]
        } else {
          newItem.uniqueId = ++this.circuitsBroadbandTableEditorComponent.uniqueId
        }
      }

      newItem.id = this.getTempEntryId() /* set a new id for the newly copied item */
      newItem.timeCopied = new Date() /* This will cause the cells for this row to be highlighted */

      /* The item being copied would have been selected (checkbox checked).
         We don't want this to be the case for the copy */
      newItem.selected = false

      if (postCode !== null) {
        newItem.b_EndPostcode = postCode
      }

      var arr = this.circuitsBroadbandTableEditorComponent.currentPageEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === entry.id) {
          arr.splice(arr.length - 1, 0, newItem)

          if (sendChangesEvent) {
            this.sendChangesToParent(entry)
          }

          setTimeout(() => this.stopHighlightingNewlyAddedRecords(), 2000)
          return
        }
      }
    },
    /*
    When items are newly added to the table a "timeCopied" property was set, and will cause
    a class to be added to those cells.  This function is called after a delay in order to
    remove that property, which in turn will stop the cells from being highlighted.
    */
    stopHighlightingNewlyAddedRecords () {
      var arr = this.circuitsBroadbandTableEditorComponent.currentPageEntries

      arr.forEach(record => {
        if (Date.now() - record.timeCopied > 1850) {
          record.timeCopied = undefined
        }
      })
    },
    deleteSelectedEntries () {
      var rowsDeleted = 0
      this.selectedEntries.forEach(entry => {
        rowsDeleted++
        this.deleteEntry(entry, false)
      })
      this.iQuoteFunctions_ShowMessage(rowsDeleted + ' row(s) deleted')
      this.sendChangesToParent()
      this.refreshCurrentPageData()
    },
    canDuplicate () {
      const maxEntriesPerPage = 100
      let currentPageEntries = 0
      this.circuitsBroadbandTableEditorComponent.currentPageEntries.forEach(entry => {
        if (!this.isBlankEntry(entry)) {
          currentPageEntries++
        }
      })
      if ((currentPageEntries + this.numEntriesThatWillBeDuplicated) > maxEntriesPerPage) {
        this.iQuoteFunctions_ShowError(`Error: Could not duplicate requested entries as it will exceed the limit of ${maxEntriesPerPage} items per page.`)
        return false
      } else if ((this.cachedEntries.length + this.numEntriesThatWillBeDuplicated) > this.maxNumberOfEntries) {
        this.iQuoteFunctions_ShowError(`Error: Could not duplicate requested entries as it will exceed the limit of ${this.maxNumberOfEntries} items.`)
        return false
      }
      return true
    },
    duplicateSelectedEntries () {
      if (!this.canDuplicate()) {
        return
      }

      var rowsAdded = 0
      this.selectedEntries.forEach(entry => {
        for (let index = 0; index < this.circuitsBroadbandTableEditorComponent.duplicateCopies; index++) {
          this.duplicateEntry(entry, null, false)
          rowsAdded++
        }
      })
      this.iQuoteFunctions_ShowMessage(rowsAdded + ' row(s) added')
      this.sendChangesToParent()
      if (this.cachedEntries.length >= this.maxNumberOfEntries && this.hasBlankEntry()) {
        this.circuitsBroadbandTableEditorComponent.currentPageEntries.splice(-1)
      }
    },
    unselectAllEntries () {
      this.selectedEntries.forEach(entry => {
        entry.selected = false
      })
    },
    selectAllEntries () {
      this.unselectAllEntries()
      this.circuitsBroadbandTableEditorComponent.currentPageEntries.forEach(entry => {
        if (!this.isBlankEntry(entry)) {
          entry.selected = true
        }
      })
    },
    pageChanged (pageNumber) {
      this.circuitsBroadbandTableEditorComponent.currentPage = pageNumber - 1
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.circuitsBroadbandTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    firstClicked () {
      this.circuitsBroadbandTableEditorComponent.currentPage = 0
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.circuitsBroadbandTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    prevClicked () {
      this.circuitsBroadbandTableEditorComponent.currentPage--
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.circuitsBroadbandTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    nextClicked () {
      this.circuitsBroadbandTableEditorComponent.currentPage++
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.circuitsBroadbandTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    lastClicked () {
      this.circuitsBroadbandTableEditorComponent.currentPage = this.totalPages - 1
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.circuitsBroadbandTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    searchText (filter) {
      this.circuitsBroadbandTableEditorComponent.filter = filter
      DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'filter', true, filter)
    },
    animateTable () {
      if (this.circuitsBroadbandTableEditorComponent.animatePageTransitions) {
        this.circuitsBroadbandTableEditorComponent.showData = false
        setTimeout(() => {
          this.circuitsBroadbandTableEditorComponent.showData = true
        }, 400)
      }
    },
    hasDeletedProduct (circuitsBroadbandEntry) {
      return this.circuitsBroadbandTableEditorComponent.broadbandProducts.some(x => x.deleted && x.id === circuitsBroadbandEntry.designPackCircuitsBroadbandProductId)
    }
  }
}
</script>
<style scoped>

  table.iquoteDesignpackTable tr{
    height: 38px;
  }

  #mainContainer::after, #duplicateSelectedItemsEditorContainer::before {
    content: "";
    display: table;
    clear: both;
  }

  #mainTable tr, #mainTable td {
    transition: background .5s ease-in;
    -moz-transition: background .5s ease-in;
    -o-transition: background .5s ease-in;
    -webkit-transition: background .5s ease-in;
  }

  #mainTable tr.isNewRow, #marginsTable tr.isNewRow {
    background-color: burlywood;
  }

  #mainTable tr.isNewRow td.readOnlyCell, #marginsTable tr.isNewRow td.readOnlyCell {
    background-color: chocolate;
  }

  #mainTableContainer {
    width: calc(100% - 875px);
    display: inline;
    float: left;
    padding: 0;
    overflow-x: scroll;
    margin-bottom: 20px;
  }

  #marginsTableContainer {
    width: 875px;
    display: inline;
    float: right;
    margin-bottom: 20px;
  }

  #ItemsEditorContainer {
    margin-top: 32px;
    float: left;
    text-align: left;
    width: 100%;
  }

  #duplicateSelectedItemsEditorContainer {
    margin-top: 32px;
    float: left;
    text-align: left;
    width: 100%;
    border: 1px dashed black;
    padding: 7px;
    vertical-align: top;
    margin-bottom: 10px;
  }

  #mainTable {
    width: auto;
    table-layout: fixed;
    border: none;
    margin: 0;
  }

  #marginsTable {
    width: 100%;
    margin: 0;
    table-layout: auto;
    border: none;
  }

  #mainTable th, #mainTable td, #marginsTable th, #marginsTable td {
    white-space:nowrap;
    height: 37px;
    text-align: center;
    vertical-align: top;
  }

  #marginsTable tr.data td.fixedSpacer {
    width: 15px;
    background-color: white;
  }

  #marginsTable tr td.buttonsCol {
    width: 15px;
  }

  table input[type=text], table input[type=number], table input[type=checkbox], table input[type=button], table select {
    border: none;
    margin: 0;
    height: 25px;
    font-size: 1em;
  }

  table input[type=number].quantityInput {
    width: 50px;
  }

  #mainTable tr.disabled td,  #marginsTable tr.disabled td {
    background-color: silver;
  }

  tr.mainHeader {
    text-align: center;
    font-size: 1.5em;
  }

  #mainTable tr.data:nth-child(1) {
    width: 100px;
  }

  #marginsTable tr.data td {
    width: 55px;
  }

  #marginsTable tr.data td input[type=number] {
    width: 50px;
  }
  .readOnlyCell {
    background-color: silver;
  }

  .noborder {
    border: none;
  }

  #mainTable tr td.NotApplicableMargin {
    text-align: center;
    font-style: italic;
    background-color: silver;
  }

  @-webkit-keyframes fadeIn {
    0% { opacity: 0.1; }
    30% { opacity: 0.4; }
    50% { opacity: 0.6; }
    70% { opacity: 0.9; }
    100% { opacity: 1; }
  }

  @keyframes fadeIn {
    0% { opacity: 0.1; }
    30% { opacity: 0.4; }
    50% { opacity: 0.6; }
    70% { opacity: 0.9; }
    100% { opacity: 1; }
  }

  .opaque {
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 0.7s;
    animation-name: fadeIn;
    animation-duration: 0.7s;
  }

  tr.deletedProductRow td {
    color: white !important;
    background-color: #DA291C;
  }

  tr.deletedProductRow td input {
    color: white !important;
  }

  tr.deletedProductRow td select {
    color: white !important;
    background-color: #DA291C;
  }

  .activeOption {
    color: black !important;
    background-color: white !important;
  }

  .deletedNotBeingUsedOption {
    display: none;
  }

  .deletedBeingUsedOption {
    color: red !important;
    background-color: white !important;
  }
</style>
