import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteFunctions } from '../iQuoteFunctions'
import { externalQuotesModelMixin } from '../../../mixins/iQuote/Models/externalQuotesModelMixin'
import { iQuoteQuoteDesignPacksAPIMixin } from '../API/iQuoteQuoteDesignPacksAPIMixin'
import { iQuoteCalculationsReviewScreenMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsReviewScreenMixin'
import { iQuoteCalculationsDesignPackDetailDataMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackDetailData'
import { iQuoteEventBus } from '../../../events/iQuoteEvents'
import { mapActions } from 'vuex'
import debounce from 'lodash.debounce'

export const iQuoteCalculationsDesignPackMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin,
    iQuoteFunctions,
    externalQuotesModelMixin,
    iQuoteQuoteDesignPacksAPIMixin,
    iQuoteCalculationsReviewScreenMixin,
    iQuoteCalculationsDesignPackDetailDataMixin
  ],
  data () {
    return {
      iQuoteCalculationsDesignPackMixin: {
        sfToBusinessCoeList: [
          { sfCoe: 'Call Traffic', businessCoe: 'C&L' },
          { sfCoe: 'Conferencing', businessCoe: 'C&L' },
          { sfCoe: 'Inbound', businessCoe: 'C&L' },
          { sfCoe: 'SIP Trunking', businessCoe: 'C&L' },
          { sfCoe: 'Voice Line Rental', businessCoe: 'C&L' },
          { sfCoe: 'Caas', businessCoe: 'DATA' },
          { sfCoe: 'Data Connectivity', businessCoe: 'DATA' },
          { sfCoe: 'ICON Secure', businessCoe: 'DATA' },
          { sfCoe: 'NS Upfront', businessCoe: 'TECH' },
          { sfCoe: 'Mobile', businessCoe: 'Mobile' },
          { sfCoe: 'New Managed Services', businessCoe: 'MS' },
          { sfCoe: 'New Third Party Support', businessCoe: 'MS' },
          { sfCoe: 'New Vendor Support (SA)', businessCoe: 'MS' },
          { sfCoe: 'Professional Services', businessCoe: 'PS' },
          { sfCoe: 'Third Party Pro Services', businessCoe: 'PS' },
          { sfCoe: 'Technology', businessCoe: 'TECH' },
          { sfCoe: 'Tech (Callmedia) - Lic Only', businessCoe: 'TECH' },
          { sfCoe: 'Technology Subscriptions', businessCoe: 'TECH' }
        ],
        contractFrameworks: [
          { id: 1, name: 'None', managementCharge: 0.00 },
          { id: 2, name: 'RM3808', managementCharge: 1.00 },
          { id: 3, name: 'RM3804', managementCharge: 1.15 },
          { id: 11, name: 'RM6116', managementCharge: 1.00 },
          { id: 4, name: 'OJEU', managementCharge: 0.00 },
          { id: 5, name: 'JISC TPS', managementCharge: 3.50 },
          { id: 6, name: 'G-Cloud 11', managementCharge: 0.75 },
          { id: 7, name: 'RM3733', managementCharge: 1.00 },
          { id: 8, name: 'Scottish Government Telephony Purchasing Service', managementCharge: 0.00 },
          { id: 9, name: 'NHS London Procurement Partnership', managementCharge: 1.00 },
          { id: 10, name: 'Other', managementCharge: 0 }
        ],
        hardwareCurrencies: [
          { id: 1, description: 'GBP' },
          { id: 2, description: 'USD' },
          { id: 3, description: 'EUR' }
        ],
        designPackId: 0
      }
    }
  },
  computed: {
    iQuoteCalculationsDesignPackMixin_designPackSummaryEntrySources () {
      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined) {
        return []
      }

      var sources = this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.map(x => x.source)
      var uniqueSources = sources.filter((value, index, searchedArray) => searchedArray.indexOf(value) === index)

      return uniqueSources
    },
    iQuoteCalculationsDesignPackMixin_designPackSummaryEntries () {
      if (!this.iQuoteStore_GetDesignPack.useDetailDataForSummaries) {
        return this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries_Legacy
      } else {
        return this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntriesFromDetailData
      }
    },
    iQuoteCalculationsDesignPackMixin_designPackSummaryEntriesFromDetailData () {
      var entries = []
      var detailData = this.iQuoteStore_GetDesignPackDetailedDataEntries
      var dpSections = detailData.map(x => ({ id: x.designPackTableSectionId, salesforceCoeCodeId: x.dpSectionSalesforceCoeCodeId, description: x.dpSectionDescription, canBeDiscounted: x.canBeDiscounted, usesGlobalMargins: x.usesGlobalMargins, canBePaused: x.canBePaused, dpSectionSortOrder: x.dpSectionSortOrder }))
      var dpUniqueDpSections = dpSections.filter((value, index, searchedArray) => searchedArray.findIndex((x) =>
        (x.salesforceCoeCodeId === value.salesforceCoeCodeId && x.description === value.description)) === index && value.salesforceCoeCodeId !== null)
      var sources = detailData.map(x => x.dpSource)
      var uniqueSources = sources.filter((value, index, searchedArray) => searchedArray.indexOf(value) === index)
      var id = -1

      console.log('detaildata', detailData)

      dpUniqueDpSections.sort((a, b) => a.dpSectionSortOrder - b.dpSectionSortOrder)

      uniqueSources.forEach(source => {
        dpUniqueDpSections.forEach(dpSection => {
          var detailDataWithinDPSection = detailData.filter(x => x.dpSource === source &&
                                                                 x.dpSectionDescription === dpSection.description &&
                                                                 x.dpSectionSalesforceCoeCodeId === dpSection.salesforceCoeCodeId && (x.includeInCalculations || x.visibleToCustomer))

          if (detailDataWithinDPSection.length > 0) {
            var newEntry = {
              id: id--,
              description: dpSection.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(dpSection.salesforceCoeCodeId),
              salesforceCoeCodeId: dpSection.salesforceCoeCodeId,
              upfrontExternalCost: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + dataEntry.totalExternalUpfrontCost + dataEntry.totalExternalUpfrontHiddenCost, 0),
              upfrontInternalCost: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + dataEntry.totalInternalUpfrontCost + dataEntry.totalInternalUpfrontHiddenCost, 0),
              recurringExternalCost: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + dataEntry.totalExternalAnnualCost + dataEntry.totalExternalAnnualHiddenCost, 0),
              recurringInternalCost: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + dataEntry.totalInternalAnnualCost + dataEntry.totalInternalAnnualHiddenCost, 0),
              designPackTableSectionId: dpSection.id,
              upfrontExternalPrice: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + dataEntry.totalExternalUpfrontPrice, 0),
              upfrontInternalPrice: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + dataEntry.totalInternalUpfrontPrice, 0),
              recurringExternalPrice: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + dataEntry.totalExternalAnnualPrice, 0),
              recurringInternalPrice: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + dataEntry.totalInternalAnnualPrice, 0),
              upfrontSellPrice: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + (dataEntry.unitOOPriceOverride !== null ? dataEntry.unitOOPriceOverride * Math.abs(dataEntry.quantity) : dataEntry.totalExternalUpfrontPrice + dataEntry.totalInternalUpfrontPrice), 0),
              recurringSellPrice: detailDataWithinDPSection.reduce((accumulator, dataEntry) => accumulator + (dataEntry.unitARPriceOverride !== null ? dataEntry.unitARPriceOverride * Math.abs(dataEntry.quantity) : dataEntry.totalExternalAnnualPrice + dataEntry.totalInternalAnnualPrice), 0),
              source: source,
              disabled: false,
              supportsPausing: dpSection.canBePaused,
              usesGlobalMargins: dpSection.usesGlobalMargins,
              canBeDiscounted: dpSection.canBeDiscounted,
              priceOverrideAllowed: detailDataWithinDPSection.filter(x => x.priceOverrideAllowed === true).length > 0
            }

            if (newEntry.upfrontExternalCost !== 0 ||
              newEntry.upfrontInternalCost !== 0 ||
              newEntry.recurringExternalCost !== 0 ||
              newEntry.recurringInternalCost !== 0 ||
              newEntry.upfrontExternalPrice !== 0 ||
              newEntry.upfrontInternalPrice !== 0 ||
              newEntry.recurringExternalPrice !== 0 ||
              newEntry.recurringInternalPrice !== 0 ||
              newEntry.upfrontSellPrice !== 0 ||
              newEntry.recurringSellPrice !== 0) {
              entries.push(
                newEntry
              )
            }
          }
        })
      })

      console.log('summaryFromDetailData', entries)

      return entries
    },
    iQuoteCalculationsDesignPackMixin_designPackSummaryEntries_Legacy () {
      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined) {
        return []
      }

      var entries = []
      var id = -1

      if (this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length > 0) {
        this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.forEach(entry => {
          var newEntry = {
            id: id--,
            description: entry.description,
            SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
            upfrontExternalCost: entry.upfrontThirdPartyCost,
            upfrontInternalCost: entry.upfrontInternalCost,
            recurringExternalCost: entry.recurringThirdPartyCost,
            recurringInternalCost: entry.recurringInternalCost,
            designPackTableSectionId: null,
            upfrontExternalPrice: entry.upfrontSellPrice,
            upfrontInternalPrice: 0,
            recurringExternalPrice: entry.recurringSellPrice,
            recurringInternalPrice: 0,
            upfrontSellPrice: entry.upfrontSellPrice,
            recurringSellPrice: entry.recurringSellPrice,
            source: 'Manually Edited DP',
            disabled: false,
            supportsPausing: false,
            canBeDiscounted: false
          }

          entries.push(
            newEntry
          )
        })

        return entries
      }

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId === this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP) {
        this.iQuoteStore_GetDesignPack.externalDPEntries.forEach(entry => {
          var newEntry = {
            id: id--,
            description: entry.description,
            SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
            upfrontExternalCost: entry.upfrontThirdPartyCost,
            upfrontInternalCost: entry.upfrontInternalCost,
            recurringExternalCost: entry.recurringThirdPartyCost,
            recurringInternalCost: entry.recurringInternalCost,
            designPackTableSectionId: null,
            upfrontExternalPrice: entry.upfrontSellPrice,
            upfrontInternalPrice: 0,
            recurringExternalPrice: entry.recurringSellPrice,
            recurringInternalPrice: 0,
            upfrontSellPrice: entry.upfrontSellPrice,
            recurringSellPrice: entry.recurringSellPrice,
            source: 'External DP Template',
            disabled: false,
            supportsPausing: true,
            canBeDiscounted: true
          }

          entries.push(
            newEntry
          )
        })
        return entries
      }

      var designPackEntriesFiltered = this.iQuoteStore_GetQuoteDesignPackEntries.filter(
        item => ((item.designPackEntryTypeId === this.iQuoteConstants.designPackEntryTypes.UPFRONT && this.iQuoteStore_GetQuote.chargeUpfront) ||
                 (item.designPackEntryTypeId === this.iQuoteConstants.designPackEntryTypes.AMORTISED && !this.iQuoteStore_GetQuote.chargeUpfront)) &&
                 (item.recurringExternalCost > 0 || item.recurringInternalCost > 0 || item.upfrontExternalCost > 0 || item.upfrontInternalCost > 0 ||
                  item.recurringExternalPrice > 0 || item.recurringInternalPrice > 0 || item.upfrontExternalPrice > 0 || item.upfrontInternalPrice > 0 ||
                  item.recurringExternalHiddenCost > 0 || item.recurringInternalHiddenCost > 0 || item.upfrontExternalHiddenCost > 0 || item.upfrontInternalHiddenCost > 0 ||
                  item.recurringExternalQuoteOnlyCost > 0 || item.recurringInternalQuoteOnlyCost > 0 || item.upfrontExternalQuoteOnlyCost > 0 || item.upfrontInternalQuoteOnlyCost > 0
                 )
      )

      designPackEntriesFiltered.forEach(entry => {
        var newEntry = {
          id: id--,
          description: entry.description,
          SF_COE: entry.SF_COE,
          upfrontExternalCost: entry.upfrontExternalCost,
          upfrontInternalCost: entry.upfrontInternalCost,
          recurringExternalCost: entry.recurringExternalCost,
          recurringInternalCost: entry.recurringInternalCost,
          designPackTableSectionId: entry.designPackTableSectionId,
          upfrontExternalPrice: entry.upfrontExternalPrice,
          upfrontInternalPrice: entry.upfrontInternalPrice,
          recurringExternalPrice: entry.recurringExternalPrice,
          recurringInternalPrice: entry.recurringInternalPrice,
          source: 'iQuote',
          disabled: false,
          supportsPausing: true
        }

        var designPackSection = this.iQuoteStore_GetDesignPackTableSections.find(x => x.id === entry.designPackTableSectionId)

        if (designPackSection !== undefined) {
          if (designPackSection.precalculatedPricing === true) {
            newEntry.usesGlobalMargins = false
            newEntry.upfrontExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontExternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalDiscount(entry)) / 100))
            newEntry.upfrontInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontInternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalDiscount(entry)) / 100))
            newEntry.upfrontSellPrice = newEntry.upfrontExternalPrice + newEntry.upfrontInternalPrice

            newEntry.recurringExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringExternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalDiscount(entry)) / 100))
            newEntry.recurringInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringInternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalDiscount(entry)) / 100))
            newEntry.recurringSellPrice = newEntry.recurringExternalPrice + newEntry.recurringInternalPrice

            newEntry.canBeDiscounted = designPackSection.canBeDiscounted
          } else {
            newEntry.upfrontExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.upfrontExternalCost + entry.upfrontExternalQuoteOnlyCost) / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(entry)) / 100))
            newEntry.upfrontInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.upfrontInternalCost + entry.upfrontInternalQuoteOnlyCost) / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalMargin(entry)) / 100))
            newEntry.upfrontSellPrice = newEntry.upfrontExternalPrice + newEntry.upfrontInternalPrice

            newEntry.recurringExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.recurringExternalCost + entry.recurringExternalQuoteOnlyCost) / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalMargin(entry)) / 100))
            newEntry.recurringInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.recurringInternalCost + entry.recurringInternalQuoteOnlyCost) / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalMargin(entry)) / 100))
            newEntry.recurringSellPrice = newEntry.recurringExternalPrice + newEntry.recurringInternalPrice

            newEntry.usesGlobalMargins = true
            newEntry.canBeDiscounted = false
          }
        } else if (entry.canBeDiscounted) {
          newEntry.upfrontExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontExternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalDiscount(entry)) / 100))
          newEntry.upfrontInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontInternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalDiscount(entry)) / 100))
          newEntry.upfrontSellPrice = newEntry.upfrontExternalPrice + newEntry.upfrontInternalPrice

          newEntry.recurringExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringExternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalDiscount(entry)) / 100))
          newEntry.recurringInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringInternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalDiscount(entry)) / 100))
          newEntry.recurringSellPrice = newEntry.recurringExternalPrice + newEntry.recurringInternalPrice

          newEntry.canBeDiscounted = true
          newEntry.usesGlobalMargins = false
        } else {
          newEntry.canBeDiscounted = false
          newEntry.usesGlobalMargins = true
        }

        newEntry.priceOverrideAllowed = true

        newEntry.upfrontSellPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(newEntry.upfrontSellPrice)
        newEntry.recurringSellPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(newEntry.recurringSellPrice)

        newEntry.upfrontExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(newEntry.upfrontExternalPrice)
        newEntry.upfrontInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(newEntry.upfrontInternalPrice)
        newEntry.recurringExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(newEntry.recurringExternalPrice)
        newEntry.recurringInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(newEntry.recurringInternalPrice)

        // Once all prices have been calculated using the non-hidden costs + margins, we now need
        // to add the hidden costs on (These are not charged for)
        newEntry.upfrontExternalCost += entry.upfrontExternalHiddenCost
        newEntry.upfrontInternalCost += entry.upfrontInternalHiddenCost
        newEntry.recurringExternalCost += entry.recurringExternalHiddenCost
        newEntry.recurringInternalCost += entry.recurringInternalHiddenCost

        entries.push(
          newEntry
        )
      })

      if (this.iQuoteCalculationsDesignPackMixin_totalHardwareTabCost !== 0) {
        var designPack = this.iQuoteStore_GetDesignPack

        var upfrontHardwareExternalCost = 0
        var upfrontHardwareInternalCost = 0
        var recurringHardwareExternalCost = 0
        var recurringHardwareInternalCost = 0
        var upfrontHardwareExternalPrice = 0
        var upfrontHardwareInternalPrice = 0
        var recurringHardwareExternalPrice = 0
        var recurringHardwareInternalPrice = 0

        for (let index = 0; index < designPack.hardwareMainEntries.length; index++) {
          const entry = designPack.hardwareMainEntries[index]
          if (!entry.disabled) {
            upfrontHardwareExternalPrice += this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitPriceGBP(entry)
            upfrontHardwareExternalCost += this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitCostGBP(entry)
          }
        }

        for (let index = 0; index < designPack.hardwareMiscEntries.length; index++) {
          const entry = designPack.hardwareMiscEntries[index]
          if (!entry.disabled) {
            upfrontHardwareExternalPrice += this.iQuoteCalculationsDesignPackMixin_calcHardwareMiscEntryTotalPrice(entry)
            upfrontHardwareExternalCost += this.iQuoteCalculationsDesignPackMixin_calcHardwareMiscEntryTotalCost(entry)
          }
        }

        var hardwareEntry = {
          id: id--,
          description: 'Hardware (Tab)',
          SF_COE: 'Technology',
          upfrontExternalCost: upfrontHardwareExternalCost,
          upfrontInternalCost: upfrontHardwareInternalCost,
          recurringExternalCost: recurringHardwareExternalCost,
          recurringInternalCost: recurringHardwareInternalCost,
          designPackTableSectionId: null,
          upfrontExternalPrice: upfrontHardwareExternalPrice,
          upfrontInternalPrice: upfrontHardwareInternalPrice,
          recurringExternalPrice: recurringHardwareExternalPrice,
          recurringInternalPrice: recurringHardwareInternalPrice,
          usesGlobalMargins: false,
          canBeDiscounted: false,
          source: 'Design Pack Tabs',
          disabled: false,
          supportsPausing: false,
          priceOverrideAllowed: true
        }

        // Apply any discounts
        hardwareEntry.upfrontExternalPrice = hardwareEntry.upfrontExternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalDiscount(hardwareEntry)) / 100)

        hardwareEntry.upfrontSellPrice = hardwareEntry.upfrontExternalPrice + hardwareEntry.upfrontInternalPrice
        hardwareEntry.recurringSellPrice = hardwareEntry.recurringExternalPrice + hardwareEntry.recurringInternalPrice

        entries.push(hardwareEntry)
      }

      if (this.iQuoteCalculationsDesignPackMixin_totalCircuitsTabUpfrontCost !== 0) {
        var circuitsInstallEntry = {
          id: id--,
          description: 'Circuit Install',
          SF_COE: 'NS Upfront',
          upfrontExternalCost: this.iQuoteCalculationsDesignPackMixin_totalCircuitsTabUpfrontCost,
          upfrontInternalCost: 0,
          recurringExternalCost: 0,
          recurringInternalCost: 0,
          recurringSellPrice: 0,
          upfrontExternalPrice: this.iQuoteCalculationsDesignPackMixin_totalCircuitsTabUpfrontPrice,
          upfrontInternalPrice: 0,
          recurringExternalPrice: 0,
          recurringInternalPrice: 0,
          designPackTableSectionId: null,
          usesGlobalMargins: false,
          canBeDiscounted: false,
          source: 'Design Pack Tabs',
          disabled: false,
          supportsPausing: false,
          priceOverrideAllowed: true
        }

        // Apply any discounts
        circuitsInstallEntry.upfrontExternalPrice = circuitsInstallEntry.upfrontExternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalDiscount(circuitsInstallEntry)) / 100)

        circuitsInstallEntry.upfrontSellPrice = circuitsInstallEntry.upfrontExternalPrice + circuitsInstallEntry.upfrontInternalPrice
        circuitsInstallEntry.recurringSellPrice = circuitsInstallEntry.recurringExternalPrice + circuitsInstallEntry.recurringInternalPrice

        entries.push(circuitsInstallEntry)
      }

      if (this.iQuoteCalculationsDesignPackMixin_totalCircuitsTabRecurringCost !== 0) {
        var circuitContingencyCost = (this.iQuoteCalculationsDesignPackMixin_totalCircuitsTabRecurringCost / 365) * 14
        var circuitsContingencyEntry = {
          id: id--,
          description: 'Circuit Contingency',
          SF_COE: 'NS Upfront',
          upfrontExternalCost: circuitContingencyCost,
          upfrontInternalCost: 0,
          recurringExternalCost: 0,
          recurringInternalCost: 0,
          upfrontSellPrice: 0, /* No Charge for this! */
          recurringSellPrice: 0, /* No Charge for this! */
          upfrontExternalPrice: 0, /* No Charge for this! */
          upfrontInternalPrice: 0, /* No Charge for this! */
          recurringExternalPrice: 0, /* No Charge for this! */
          recurringInternalPrice: 0, /* No Charge for this! */
          designPackTableSectionId: null,
          usesGlobalMargins: false,
          source: 'Design Pack Tabs',
          disabled: false,
          supportsPausing: true,
          priceOverrideAllowed: false
        }

        entries.push(circuitsContingencyEntry)

        var circuitsRecurringEntry = {
          id: id--,
          description: 'Circuit Recurring',
          SF_COE: 'Data Connectivity',
          upfrontSellPrice: 0,
          upfrontExternalCost: 0,
          upfrontInternalCost: 0,
          recurringExternalCost: this.iQuoteCalculationsDesignPackMixin_totalCircuitsTabRecurringCost,
          recurringInternalCost: 0,
          upfrontExternalPrice: 0,
          upfrontInternalPrice: 0,
          recurringExternalPrice: this.iQuoteCalculationsDesignPackMixin_totalCircuitsTabRecurringPrice,
          recurringInternalPrice: 0,
          designPackTableSectionId: null,
          usesGlobalMargins: false,
          canBeDiscounted: false,
          source: 'Design Pack Tabs',
          disabled: false,
          supportsPausing: false,
          priceOverrideAllowed: true
        }

        // Apply any discounts
        circuitsRecurringEntry.recurringExternalPrice = circuitsRecurringEntry.recurringExternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalDiscount(circuitsRecurringEntry)) / 100)

        circuitsRecurringEntry.recurringSellPrice = circuitsRecurringEntry.recurringExternalPrice + circuitsRecurringEntry.recurringInternalPrice

        entries.push(circuitsRecurringEntry)
      }

      if (this.iQuoteCalculationsDesignPackMixin_totalPSTabInternalCost !== 0) {
        var psTabEntryInternal = {
          id: id--,
          description: 'Professional Services (Tab) - Internal',
          SF_COE: 'Professional Services',
          upfrontExternalCost: 0,
          upfrontInternalCost: this.iQuoteCalculationsDesignPackMixin_totalPSTabInternalCost,
          recurringExternalCost: 0,
          recurringInternalCost: 0,
          recurringSellPrice: 0,
          designPackTableSectionId: this.iQuoteConstants.designPackTableSections.PROFESSIONAL_SERVICES,
          upfrontExternalPrice: 0,
          recurringExternalPrice: 0,
          recurringInternalPrice: 0,
          usesGlobalMargins: false,
          canBeDiscounted: true,
          source: 'Design Pack Tabs',
          disabled: false,
          supportsPausing: false,
          priceOverrideAllowed: true
        }

        // Work out price from margin, and then apply any discounts
        psTabEntryInternal.upfrontInternalPrice = this.iQuoteCalculationsDesignPackMixin_totalPSTabInternalCost * 2
        psTabEntryInternal.upfrontInternalPrice = psTabEntryInternal.upfrontInternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalDiscount(psTabEntryInternal)) / 100)
        psTabEntryInternal.upfrontInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(psTabEntryInternal.upfrontInternalPrice)
        psTabEntryInternal.upfrontSellPrice = psTabEntryInternal.upfrontInternalPrice

        entries.push(
          psTabEntryInternal
        )
      }

      if (this.iQuoteCalculationsDesignPackMixin_totalPsExpensesCost !== 0) {
        var psTabEntryExpenses = {
          id: id--,
          description: 'Professional Services (Tab) - Expenses',
          SF_COE: 'Third Party Pro Services',
          upfrontExternalCost: this.iQuoteCalculationsDesignPackMixin_totalPsExpensesCost,
          upfrontInternalCost: 0,
          recurringExternalCost: 0,
          recurringInternalCost: 0,
          recurringSellPrice: 0,
          designPackTableSectionId: null,
          upfrontInternalPrice: 0,
          recurringExternalPrice: 0,
          recurringInternalPrice: 0,
          usesGlobalMargins: true,
          canBeDiscounted: false,
          source: 'Design Pack Tabs',
          disabled: false,
          supportsPausing: false,
          priceOverrideAllowed: true
        }

        // Work out price from margin
        psTabEntryExpenses.upfrontExternalPrice = this.iQuoteCalculationsDesignPackMixin_totalPsExpensesCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(psTabEntryExpenses)) / 100)
        psTabEntryExpenses.upfrontExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(psTabEntryExpenses.upfrontExternalPrice)
        psTabEntryExpenses.upfrontSellPrice = psTabEntryExpenses.upfrontExternalPrice

        entries.push(
          psTabEntryExpenses
        )
      }

      if (this.iQuoteCalculationsDesignPackMixin_totalPsThirdPartyInstallationCost !== 0) {
        var psTabEntryExternalInstallation = {
          id: id--,
          description: 'Professional Services (Tab) - External Installation',
          SF_COE: 'Third Party Pro Services',
          upfrontExternalCost: this.iQuoteCalculationsDesignPackMixin_totalPsThirdPartyInstallationCost,
          upfrontInternalCost: 0,
          recurringExternalCost: 0,
          recurringInternalCost: 0,
          recurringSellPrice: 0,
          designPackTableSectionId: this.iQuoteConstants.designPackTableSections.THIRD_PARTY_PRO_SERVICES_INSTALLATION,
          upfrontInternalPrice: 0,
          recurringExternalPrice: 0,
          recurringInternalPrice: 0,
          usesGlobalMargins: true,
          canBeDiscounted: false,
          source: 'Design Pack Tabs',
          disabled: false,
          supportsPausing: false,
          priceOverrideAllowed: true
        }

        /* Work out the prices, but applying a margin to the costs */
        psTabEntryExternalInstallation.upfrontExternalPrice = psTabEntryExternalInstallation.upfrontExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(psTabEntryExternalInstallation)) / 100)
        psTabEntryExternalInstallation.upfrontExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(psTabEntryExternalInstallation.upfrontExternalPrice)
        psTabEntryExternalInstallation.upfrontSellPrice = psTabEntryExternalInstallation.upfrontExternalPrice

        entries.push(
          psTabEntryExternalInstallation
        )
      }

      if (this.iQuoteCalculationsDesignPackMixin_totalPsThirdPartyOtherCost !== 0) {
        var psTabEntryExternalOther = {
          id: id--,
          description: 'Professional Services (Tab) - External Other',
          SF_COE: 'Third Party Pro Services',
          upfrontExternalCost: this.iQuoteCalculationsDesignPackMixin_totalPsThirdPartyOtherCost,
          upfrontInternalCost: 0,
          recurringExternalCost: 0,
          recurringInternalCost: 0,
          recurringSellPrice: 0,
          designPackTableSectionId: this.iQuoteConstants.designPackTableSections.THIRD_PARTY_PRO_SERVICES_OTHER,
          upfrontInternalPrice: 0,
          recurringExternalPrice: 0,
          recurringInternalPrice: 0,
          usesGlobalMargins: true,
          canBeDiscounted: false,
          source: 'Design Pack Tabs',
          disabled: false,
          supportsPausing: false,
          priceOverrideAllowed: true
        }

        /* Work out the prices, but applying a margin to the costs */
        psTabEntryExternalOther.upfrontExternalPrice = psTabEntryExternalOther.upfrontExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(psTabEntryExternalOther)) / 100)
        psTabEntryExternalOther.upfrontExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(psTabEntryExternalOther.upfrontExternalPrice)
        psTabEntryExternalOther.upfrontSellPrice = psTabEntryExternalOther.upfrontExternalPrice

        entries.push(
          psTabEntryExternalOther
        )
      }

      var sourceName = ''
      var bomCount = 0

      this.externalQuotesModelMixin_State.quoteBoms.forEach(bom => {
        bomCount++

        if (bom.description === '') {
          sourceName = 'External Quotes - BOM ' + bomCount.toString()
        } else {
          sourceName = 'External Quotes - BOM (' + bom.description + ')'
        }

        bom.entries.forEach(entry => {
          if ((
            entry.upfrontExternalCost !== 0 ||
            entry.upfrontInternalCost !== 0 ||
            entry.recurringExternalCost !== 0 ||
            entry.recurringInternalCost !== 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)

            var bomEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontExternalCost: entry.upfrontExternalCost,
              upfrontInternalCost: entry.upfrontInternalCost,
              recurringExternalCost: entry.recurringExternalCost,
              recurringInternalCost: entry.recurringInternalCost,
              upfrontExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(entry)) / 100)),
              upfrontInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalMargin(entry)) / 100)),
              recurringExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalMargin(entry)) / 100)),
              recurringInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalMargin(entry)) / 100)),
              designPackTableSectionId: 0,
              usesGlobalMargins: true,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            bomEntry.recurringSellPrice = bomEntry.recurringExternalPrice + bomEntry.recurringInternalPrice
            bomEntry.upfrontSellPrice = bomEntry.upfrontExternalPrice + bomEntry.upfrontInternalPrice

            entries.push(bomEntry)
          }
        })
      })

      if (this.externalQuotesModelMixin_State.quoteIconConnect.id !== undefined) {
        if (this.externalQuotesModelMixin_State.quoteIconConnect.description === '') {
          sourceName = 'External Quotes - Icon Connect'
        } else {
          sourceName = 'External Quotes - Icon Connect (' + this.externalQuotesModelMixin_State.quoteIconConnect.description + ')'
        }

        this.externalQuotesModelMixin_State.quoteIconConnect.costSummaryEntries.forEach(entry => {
          if ((
            entry.upfrontExternalCost > 0 ||
            entry.upfrontInternalCost > 0 ||
            entry.recurringExternalCost > 0 ||
            entry.recurringInternalCost > 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)
            var iconConnectEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontExternalCost: entry.upfrontExternalCost,
              upfrontInternalCost: entry.upfrontInternalCost,
              recurringExternalCost: entry.recurringExternalCost,
              recurringInternalCost: entry.recurringInternalCost,
              upfrontExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(entry)) / 100)),
              upfrontInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalMargin(entry)) / 100)),
              recurringExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalMargin(entry)) / 100)),
              recurringInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalMargin(entry)) / 100)),
              designPackTableSectionId: 0,
              usesGlobalMargins: true,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            iconConnectEntry.recurringSellPrice = iconConnectEntry.recurringExternalPrice + iconConnectEntry.recurringInternalPrice
            iconConnectEntry.upfrontSellPrice = iconConnectEntry.upfrontExternalPrice + iconConnectEntry.upfrontInternalPrice

            entries.push(iconConnectEntry)
          }
        })
      }

      if (this.externalQuotesModelMixin_State.quoteManagedService.id !== undefined) {
        if (this.externalQuotesModelMixin_State.quoteManagedService.description === '') {
          sourceName = 'External Quotes - Managed Service'
        } else {
          sourceName = 'External Quotes - Managed Service (' + this.externalQuotesModelMixin_State.quoteManagedService.description + ')'
        }

        this.externalQuotesModelMixin_State.quoteManagedService.costSummaryEntries.forEach(entry => {
          if ((
            entry.upfrontExternalCost > 0 ||
            entry.upfrontInternalCost > 0 ||
            entry.recurringExternalCost > 0 ||
            entry.recurringInternalCost > 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)

            var managedServiceEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontExternalCost: entry.upfrontExternalCost,
              upfrontInternalCost: entry.upfrontInternalCost,
              recurringExternalCost: entry.recurringExternalCost,
              recurringInternalCost: entry.recurringInternalCost,
              upfrontExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(entry)) / 100)),
              upfrontInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalMargin(entry)) / 100)),
              recurringExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalMargin(entry)) / 100)),
              recurringInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalMargin(entry)) / 100)),
              designPackTableSectionId: 0,
              usesGlobalMargins: true,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            managedServiceEntry.recurringSellPrice = managedServiceEntry.recurringExternalPrice + managedServiceEntry.recurringInternalPrice
            managedServiceEntry.upfrontSellPrice = managedServiceEntry.upfrontExternalPrice + managedServiceEntry.upfrontInternalPrice

            entries.push(managedServiceEntry)
          }
        })
      }

      if (this.externalQuotesModelMixin_State.quoteNewManagedService.id !== undefined) {
        if (this.externalQuotesModelMixin_State.quoteNewManagedService.description === '') {
          sourceName = 'External Quotes - New Managed Service'
        } else {
          sourceName = 'External Quotes - New Managed Service (' + this.externalQuotesModelMixin_State.quoteNewManagedService.description + ')'
        }

        this.externalQuotesModelMixin_State.quoteNewManagedService.costSummaryEntries.forEach(entry => {
          if ((
            entry.upfrontExternalCost > 0 ||
            entry.upfrontInternalCost > 0 ||
            entry.recurringExternalCost > 0 ||
            entry.recurringInternalCost > 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)

            var newManagedServiceEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontExternalCost: entry.upfrontExternalCost,
              upfrontInternalCost: entry.upfrontInternalCost,
              recurringExternalCost: entry.recurringExternalCost,
              recurringInternalCost: entry.recurringInternalCost,
              upfrontExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(entry)) / 100)),
              upfrontInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalMargin(entry)) / 100)),
              recurringExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalMargin(entry)) / 100)),
              recurringInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalMargin(entry)) / 100)),
              designPackTableSectionId: 0,
              usesGlobalMargins: true,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            newManagedServiceEntry.recurringSellPrice = newManagedServiceEntry.recurringExternalPrice + newManagedServiceEntry.recurringInternalPrice
            newManagedServiceEntry.upfrontSellPrice = newManagedServiceEntry.upfrontExternalPrice + newManagedServiceEntry.upfrontInternalPrice

            entries.push(newManagedServiceEntry)
          }
        })
      }

      if (this.externalQuotesModelMixin_State.quoteCallsAndLines.id !== undefined) {
        if (this.externalQuotesModelMixin_State.quoteCallsAndLines.description === '') {
          sourceName = 'External Quotes - Calls and Lines'
        } else {
          sourceName = 'External Quotes - Calls and Lines (' + this.externalQuotesModelMixin_State.quoteCallsAndLines.description + ')'
        }

        this.externalQuotesModelMixin_State.quoteCallsAndLines.costSummaryEntries.forEach(entry => {
          if ((
            entry.upfrontExternalCost > 0 ||
            entry.upfrontInternalCost > 0 ||
            entry.recurringExternalCost > 0 ||
            entry.recurringInternalCost > 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)

            var callsAndLinesEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontExternalCost: entry.upfrontExternalCost,
              upfrontInternalCost: entry.upfrontInternalCost,
              recurringExternalCost: entry.recurringExternalCost,
              recurringInternalCost: entry.recurringInternalCost,
              upfrontExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(entry)) / 100)),
              upfrontInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalMargin(entry)) / 100)),
              recurringExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalMargin(entry)) / 100)),
              recurringInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalMargin(entry)) / 100)),
              designPackTableSectionId: 0,
              usesGlobalMargins: true,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            callsAndLinesEntry.recurringSellPrice = callsAndLinesEntry.recurringExternalPrice + callsAndLinesEntry.recurringInternalPrice
            callsAndLinesEntry.upfrontSellPrice = callsAndLinesEntry.upfrontExternalPrice + callsAndLinesEntry.upfrontInternalPrice

            entries.push(callsAndLinesEntry)
          }
        })

        this.externalQuotesModelMixin_State.quoteCallsAndLines.autotaskEntries.forEach(entry => {
          if ((
            entry.unitOOCost === 0 &&
            entry.unitARCost === 0 &&
            entry.quantity > 0 &&
            (entry.unitOOPrice !== 0 ||
            entry.unitARPrice !== 0)
          )) {
            var callsAndLinesEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontExternalCost: 0,
              upfrontInternalCost: 0,
              recurringExternalCost: 0,
              recurringInternalCost: 0,
              upfrontExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.unitOOPrice * entry.quantity),
              upfrontInternalPrice: 0,
              recurringExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.unitARPrice * entry.quantity),
              recurringInternalPrice: 0,
              designPackTableSectionId: 0,
              usesGlobalMargins: false,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            callsAndLinesEntry.recurringSellPrice = callsAndLinesEntry.recurringExternalPrice + callsAndLinesEntry.recurringInternalPrice
            callsAndLinesEntry.upfrontSellPrice = callsAndLinesEntry.upfrontExternalPrice + callsAndLinesEntry.upfrontInternalPrice

            entries.push(callsAndLinesEntry)
          }
        })
      }

      if (this.externalQuotesModelMixin_State.quoteHorizon.id !== undefined) {
        if (this.externalQuotesModelMixin_State.quoteHorizon.description === '') {
          sourceName = 'External Quotes - Horizon'
        } else {
          sourceName = 'External Quotes - Horizon (' + this.externalQuotesModelMixin_State.quoteHorizon.description + ')'
        }

        this.externalQuotesModelMixin_State.quoteHorizon.costSummaryEntries.forEach(entry => {
          if ((
            entry.upfrontExternalCost > 0 ||
            entry.upfrontInternalCost > 0 ||
            entry.recurringExternalCost > 0 ||
            entry.recurringInternalCost > 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)

            var horizonEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontExternalCost: entry.upfrontExternalCost,
              upfrontInternalCost: entry.upfrontInternalCost,
              recurringExternalCost: entry.recurringExternalCost,
              recurringInternalCost: entry.recurringInternalCost,
              upfrontExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(entry)) / 100)),
              upfrontInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalMargin(entry)) / 100)),
              recurringExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalMargin(entry)) / 100)),
              recurringInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalMargin(entry)) / 100)),
              designPackTableSectionId: 0,
              usesGlobalMargins: true,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            horizonEntry.recurringSellPrice = horizonEntry.recurringExternalPrice + horizonEntry.recurringInternalPrice
            horizonEntry.upfrontSellPrice = horizonEntry.upfrontExternalPrice + horizonEntry.upfrontInternalPrice

            entries.push(horizonEntry)
          }
        })
      }

      if (this.externalQuotesModelMixin_State.quoteIconSecure.id !== undefined) {
        if (this.externalQuotesModelMixin_State.quoteIconSecure.description === '') {
          sourceName = 'External Quotes - Icon Secure'
        } else {
          sourceName = 'External Quotes - Icon Secure (' + this.externalQuotesModelMixin_State.quoteIconSecure.description + ')'
        }

        this.externalQuotesModelMixin_State.quoteIconSecure.costSummaryEntries.forEach(entry => {
          if ((
            entry.upfrontExternalCost > 0 ||
            entry.upfrontInternalCost > 0 ||
            entry.recurringExternalCost > 0 ||
            entry.recurringInternalCost > 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)

            var iconSecureEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontExternalCost: entry.upfrontExternalCost,
              upfrontInternalCost: entry.upfrontInternalCost,
              recurringExternalCost: entry.recurringExternalCost,
              recurringInternalCost: entry.recurringInternalCost,
              upfrontExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin(entry)) / 100)),
              upfrontInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalMargin(entry)) / 100)),
              recurringExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringExternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalMargin(entry)) / 100)),
              recurringInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringInternalCost / ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalMargin(entry)) / 100)),
              designPackTableSectionId: 0,
              usesGlobalMargins: true,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            iconSecureEntry.recurringSellPrice = iconSecureEntry.recurringExternalPrice + iconSecureEntry.recurringInternalPrice
            iconSecureEntry.upfrontSellPrice = iconSecureEntry.upfrontExternalPrice + iconSecureEntry.upfrontInternalPrice

            entries.push(iconSecureEntry)
          }
        })
      }

      if (this.externalQuotesModelMixin_State.quoteIconNow.id !== undefined) {
        if (this.externalQuotesModelMixin_State.quoteIconNow.description === '') {
          sourceName = 'External Quotes - Icon Now'
        } else {
          sourceName = 'External Quotes - Icon Now (' + this.externalQuotesModelMixin_State.quoteIconNow.description + ')'
        }

        this.externalQuotesModelMixin_State.quoteIconNow.priceSummaryEntries.forEach(entry => {
          if ((
            entry.upfrontPrice > 0 ||
            entry.recurringPrice > 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)
            var iconNowEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontInternalPrice: entry.upfrontPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalDiscount(entry)) / 100),
              upfrontExternalPrice: 0,
              upfrontExternalCost: 0,
              upfrontInternalCost: 0,
              recurringExternalCost: 0,
              recurringInternalCost: 0,
              recurringInternalPrice: entry.recurringPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringInternalDiscount(entry)) / 100),
              recurringExternalPrice: 0,
              designPackTableSectionId: 0,
              usesGlobalMargins: false,
              canBeDiscounted: true,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            iconNowEntry.upfrontSellPrice = iconNowEntry.upfrontInternalPrice + iconNowEntry.upfrontExternalPrice
            iconNowEntry.recurringSellPrice = iconNowEntry.recurringInternalPrice + iconNowEntry.recurringExternalPrice

            entries.push(
              iconNowEntry
            )
          }
        })

        this.externalQuotesModelMixin_State.quoteIconNow.costSummaryEntries.forEach(entry => {
          if ((
            entry.upfrontExternalCost > 0 ||
            entry.upfrontInternalCost > 0 ||
            entry.recurringExternalCost > 0 ||
            entry.recurringInternalCost > 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)
            var upfrontPrice = 0
            var recurringPrice = 0

            // KP - Removed 25/11/2022, as we now read the cost pricing table instead
            // // Find the prices shown in the autotask table for all rows that are linked
            // // to this cost summary row.
            // this.externalQuotesModelMixin_State.quoteIconNow.autotaskEntries.forEach(autotaskEntry => {
            //   if (autotaskEntry.salesforceCoeCodeId === entry.salesforceCoeCodeId &&
            //       autotaskEntry.salesforceDescription === entry.description) {
            //     upfrontPrice += autotaskEntry.unitOOPrice
            //     recurringPrice += autotaskEntry.unitARPrice
            //   }
            // })

            entries.push(
              {
                id: id--,
                description: entry.description,
                SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
                upfrontSellPrice: upfrontPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontExternalDiscount(entry)) / 100),
                upfrontExternalCost: entry.upfrontExternalCost,
                upfrontInternalCost: entry.upfrontInternalCost,
                recurringExternalCost: entry.recurringExternalCost,
                recurringInternalCost: entry.recurringInternalCost,
                upfrontExternalPrice: 0,
                upfrontInternalPrice: 0,
                recurringExternalPrice: 0,
                recurringInternalPrice: 0,
                recurringSellPrice: recurringPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getRecurringExternalDiscount(entry)) / 100),
                designPackTableSectionId: 0,
                usesGlobalMargins: false,
                canBeDiscounted: true,
                source: sourceName,
                disabled: false,
                supportsPausing: true,
                priceOverrideAllowed: true
              }
            )
          }
        })
      }

      if (this.externalQuotesModelMixin_State.quoteTwilio.id !== undefined) {
        if (this.externalQuotesModelMixin_State.quoteTwilio.description === '') {
          sourceName = 'External Quotes - Twilio'
        } else {
          sourceName = 'External Quotes - Twilio (' + this.externalQuotesModelMixin_State.quoteTwilio.description + ')'
        }
        this.externalQuotesModelMixin_State.quoteTwilio.costSummaryEntries.forEach(entry => {
          if ((
            entry.upfrontExternalCost > 0 ||
            entry.upfrontInternalCost > 0 ||
            entry.recurringExternalCost > 0 ||
            entry.recurringInternalCost > 0
          )) {
            entry.SF_COE = this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId)
            var twilioEntry = {
              id: id--,
              description: entry.description,
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(entry.salesforceCoeCodeId),
              upfrontExternalCost: entry.upfrontExternalCost,
              upfrontInternalCost: entry.upfrontInternalCost,
              recurringExternalCost: entry.recurringExternalCost,
              recurringInternalCost: entry.recurringInternalCost,
              designPackTableSectionId: 0,
              usesGlobalMargins: true,
              source: sourceName,
              disabled: false,
              supportsPausing: true,
              priceOverrideAllowed: true
            }

            const priceSummaryEntry = this.externalQuotesModelMixin_State.quoteTwilio.priceSummaryEntries.find((item) => item.description === entry.description)
            if (priceSummaryEntry !== undefined) {
              twilioEntry.upfrontExternalPrice = priceSummaryEntry.upfrontExternalPrice
              twilioEntry.upfrontInternalPrice = priceSummaryEntry.upfrontInternalPrice
              twilioEntry.recurringExternalPrice = priceSummaryEntry.recurringExternalPrice
              twilioEntry.recurringInternalPrice = priceSummaryEntry.recurringInternalPrice
            }

            twilioEntry.recurringSellPrice = twilioEntry.recurringExternalPrice + twilioEntry.recurringInternalPrice
            twilioEntry.upfrontSellPrice = twilioEntry.upfrontExternalPrice + twilioEntry.upfrontInternalPrice
            twilioEntry.usesGlobalMargins = false
            entries.push(twilioEntry)
          }
        })
      }

      if (this.iQuoteStore_GetDesignPack.addPCTime) {
        var pcTimeEntry = {
          id: id--,
          description: 'Order Co-ordination', // // AT Code: Labour - Project Coordinator (0.25 Day)
          SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(this.iQuoteConstants.salesforceCoeCodes.PROFESSIONAL_SERVICES),
          upfrontExternalCost: 0,
          upfrontInternalCost: 81.25,
          recurringExternalCost: 0,
          recurringInternalCost: 0,
          upfrontExternalPrice: 0,
          recurringExternalPrice: 0,
          recurringInternalPrice: 0,
          recurringSellPrice: 0,
          designPackTableSectionId: this.iQuoteConstants.designPackTableSections.PROFESSIONAL_SERVICES,
          usesGlobalMargins: false,
          canBeDiscounted: true,
          source: 'Design Pack Options',
          disabled: false,
          supportsPausing: true,
          priceOverrideAllowed: true
        }

        // Work out price from margin, and then apply any discounts
        pcTimeEntry.upfrontInternalPrice = pcTimeEntry.upfrontInternalCost * 2
        pcTimeEntry.upfrontInternalPrice = pcTimeEntry.upfrontInternalPrice * ((100 - this.iQuoteCalculationsDesignPackMixin_getUpfrontInternalDiscount(pcTimeEntry)) / 100)
        pcTimeEntry.upfrontSellPrice = pcTimeEntry.upfrontInternalPrice

        entries.push(pcTimeEntry)
      }

      if (this.iQuoteStore_GetDesignPack.ciscoVIPRebate !== 0) {
        var ciscoRebateTimeEntry = {
          id: id--,
          description: 'Cisco VIP Rebate',
          SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(this.iQuoteConstants.salesforceCoeCodes.NS_UPFRONT),
          upfrontExternalCost: -this.iQuoteStore_GetDesignPack.ciscoVIPRebate,
          upfrontInternalCost: 0,
          recurringExternalCost: 0,
          recurringInternalCost: 0,
          upfrontInternalPrice: 0,
          upfrontExternalPrice: 0,
          recurringExternalPrice: 0,
          recurringInternalPrice: 0,
          designPackTableSectionId: null,
          usesGlobalMargins: false,
          canBeDiscounted: false,
          source: 'Design Pack Options',
          disabled: false,
          supportsPausing: false
        }

        ciscoRebateTimeEntry.upfrontSellPrice = ciscoRebateTimeEntry.upfrontInternalPrice + ciscoRebateTimeEntry.upfrontExternalPrice
        ciscoRebateTimeEntry.recurringSellPrice = ciscoRebateTimeEntry.recurringInternalPrice + ciscoRebateTimeEntry.recurringExternalPrice

        entries.push(ciscoRebateTimeEntry)
      }

      // Update any sell prices with overriden values.
      entries.forEach(dpEntry => {
        var priceOverride = this.iQuoteStore_GetDesignPack.priceOverrides.find(x => x.description === dpEntry.description && this.iQuoteFunctions_getSF_COE_NameFromId(x.salesforceCoeCodeId) === dpEntry.SF_COE)
        if (priceOverride !== undefined) {
          // Default initially to what the dp calculated
          var upfrontExternalPrice = dpEntry.upfrontExternalPrice
          var upfrontInternalPrice = dpEntry.upfrontInternalPrice
          var recurringExternalPrice = dpEntry.recurringExternalPrice
          var recurringInternalPrice = dpEntry.recurringInternalPrice
          dpEntry.priceOverriden = false

          if (priceOverride.upfrontExternalSellPrice !== null) {
            upfrontExternalPrice = priceOverride.upfrontExternalSellPrice
            dpEntry.priceOverriden = true
          }

          if (priceOverride.upfrontInternalSellPrice !== null) {
            upfrontInternalPrice = priceOverride.upfrontInternalSellPrice
            dpEntry.priceOverriden = true
          }

          if (priceOverride.recurringExternalSellPrice !== null) {
            recurringExternalPrice = priceOverride.recurringExternalSellPrice
            dpEntry.priceOverriden = true
          }

          if (priceOverride.recurringInternalSellPrice !== null) {
            recurringInternalPrice = priceOverride.recurringInternalSellPrice
            dpEntry.priceOverriden = true
          }

          dpEntry.upfrontSellPrice = upfrontExternalPrice + upfrontInternalPrice
          dpEntry.recurringSellPrice = recurringExternalPrice + recurringInternalPrice
        }
      })

      var enabledEntries = entries.filter(x => this.iQuoteStore_GetDesignPack.disabledEntries.findIndex(y => y.source === x.source && y.description === x.description && y.salesforceCoeCodeId === this.iQuoteFunctions_getSF_COE_IdFromName(x.SF_COE)) === -1)

      if (this.iQuoteStore_GetDesignPack.contractFrameworkId !== this.iQuoteConstants.designPackContractFrameworkIds.NONE) {
        var contractFramework = this.iQuoteCalculationsDesignPackMixin.contractFrameworks.find(x => x.id === this.iQuoteStore_GetDesignPack.contractFrameworkId)

        if (contractFramework !== undefined) {
          var totalUpfrontPrice = enabledEntries.filter(x => x.upfrontSellPrice > 0).reduce((previousValue, entry) => previousValue + entry.upfrontSellPrice, 0).toFixed(2)

          if (totalUpfrontPrice > 0) {
            entries.push({
              id: id--,
              description: contractFramework.name + ' upfront management charge',
              SF_COE: this.iQuoteFunctions_getSF_COE_NameFromId(this.iQuoteConstants.salesforceCoeCodes.TECHNOLOGY),
              upfrontExternalCost: Number((totalUpfrontPrice * (contractFramework.managementCharge / 100)).toFixed(2)),
              upfrontInternalCost: 0,
              upfrontSellPrice: 0,
              recurringExternalCost: 0,
              recurringInternalCost: 0,
              upfrontExternalPrice: 0,
              upfrontInternalPrice: 0,
              recurringExternalPrice: 0,
              recurringInternalPrice: 0,
              recurringSellPrice: 0,
              designPackTableSectionId: null,
              usesGlobalMargins: false,
              canBeDiscounted: false,
              source: 'Additional Charges',
              disabled: false,
              supportsPausing: false
            })
          }

          var totalRecurringPrice = enabledEntries.filter(x => x.recurringSellPrice > 0).reduce((previousValue, entry) => previousValue + entry.recurringSellPrice, 0).toFixed(2)

          if (totalRecurringPrice > 0) {
            // Ticket 3506 - The salesforce code used by the recurring element should be the code
            // used on the highest value entry in the list
            var sfCoeOfEntryWithMaxValue = entries[0].SF_COE
            var maxRecurringSellPrice = entries[0].recurringSellPrice
            for (let index = 1; index < entries.length; index++) {
              const entry = entries[index]

              if (entry.recurringSellPrice > maxRecurringSellPrice) {
                sfCoeOfEntryWithMaxValue = entry.SF_COE
                maxRecurringSellPrice = entry.recurringSellPrice
              }
            }

            entries.push({
              id: id--,
              description: contractFramework.name + ' recurring management charge',
              SF_COE: sfCoeOfEntryWithMaxValue,
              upfrontExternalCost: 0,
              upfrontInternalCost: 0,
              upfrontSellPrice: 0,
              recurringExternalCost: Number((totalRecurringPrice * (contractFramework.managementCharge / 100)).toFixed(2)),
              recurringInternalCost: 0,
              upfrontExternalPrice: 0,
              upfrontInternalPrice: 0,
              recurringExternalPrice: 0,
              recurringInternalPrice: 0,
              recurringSellPrice: 0,
              designPackTableSectionId: null,
              usesGlobalMargins: false,
              canBeDiscounted: false,
              source: 'Additional Charges',
              disabled: false,
              supportsPausing: false
            })
          }
        } else {
          console.log('Could not find contract framework' + designPack.contractFrameworkId)
        }
      }

      return entries
    },
    iQuoteCalculationsDesignPackMixin_businessValues () {
      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined) {
        return []
      }

      var businessValuesArray = []
      var businessValue

      this.iQuoteCalculationsDesignPackMixin_sfAndBusinessValues.forEach(entry => {
        businessValue = businessValuesArray.find(listItem => listItem.businessCoe === entry.businessCoe)

        if (businessValue === undefined) {
          businessValue = {
            businessCoe: entry.businessCoe,
            upfrontSellPrice: 0,
            upfrontExternalCost: 0,
            upfrontInternalCost: 0,
            recurringSellPrice: 0,
            recurringExternalCost: 0,
            recurringInternalCost: 0
          }

          businessValuesArray.push(businessValue)
        }

        businessValue.upfrontSellPrice += entry.upfrontSellPrice || 0
        businessValue.upfrontExternalCost += entry.upfrontExternalCost || 0
        businessValue.upfrontInternalCost += entry.upfrontInternalCost || 0
        businessValue.recurringSellPrice += entry.recurringSellPrice || 0
        businessValue.recurringExternalCost += entry.recurringExternalCost || 0
        businessValue.recurringInternalCost += entry.recurringInternalCost || 0
      })

      return businessValuesArray
    },
    iQuoteCalculationsDesignPackMixin_sfAndBusinessValues () {
      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined) {
        return []
      }

      var sfAndBusinessValuesArray = []
      var sfToBusinessValueThatMatchesDesignPackEntry
      var sfToBusinessValue
      var sfCoe
      var businessCoe

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(designPackEntry => {
        sfToBusinessValueThatMatchesDesignPackEntry = null
        this.iQuoteCalculationsDesignPackMixin.sfToBusinessCoeList.forEach(sfToBusinessCoeItem => {
          if (sfToBusinessCoeItem.sfCoe === designPackEntry.SF_COE) {
            sfToBusinessValueThatMatchesDesignPackEntry = sfToBusinessCoeItem
          }
        })

        // If we found the salesforce code in the business COE mappings code, then use the found Business COE
        // Otherwise just use the SF COE for the Businesss COE
        if (sfToBusinessValueThatMatchesDesignPackEntry === null) {
          sfCoe = designPackEntry.SF_COE
          businessCoe = designPackEntry.SF_COE
        } else {
          sfCoe = sfToBusinessValueThatMatchesDesignPackEntry.sfCoe
          businessCoe = sfToBusinessValueThatMatchesDesignPackEntry.businessCoe
        }

        // Check if we already have values for the business COE
        sfToBusinessValue = sfAndBusinessValuesArray.find(listItem => listItem.sfCoe === sfCoe && listItem.businessCoe === businessCoe)

        if (sfToBusinessValue === undefined) {
          // We don't have any values for this business COE yet, so add a new zeroed entry to the array
          sfToBusinessValue = {
            sfCoe: sfCoe,
            businessCoe: businessCoe,
            upfrontSellPrice: 0,
            upfrontExternalCost: 0,
            upfrontInternalCost: 0,
            recurringSellPrice: 0,
            recurringExternalCost: 0,
            recurringInternalCost: 0
          }

          sfAndBusinessValuesArray.push(sfToBusinessValue)
        }

        if (!this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(designPackEntry)) {
          sfToBusinessValue.upfrontSellPrice += designPackEntry.upfrontSellPrice || 0
          sfToBusinessValue.upfrontExternalCost += designPackEntry.upfrontExternalCost || 0
          sfToBusinessValue.upfrontInternalCost += designPackEntry.upfrontInternalCost || 0
          sfToBusinessValue.recurringSellPrice += designPackEntry.recurringSellPrice || 0
          sfToBusinessValue.recurringExternalCost += designPackEntry.recurringExternalCost || 0

          if (designPackEntry.SF_COE === 'New Managed Services') {
            // 'New Managed Services' is a special-case SF_COE code from the managed service
            // calculator, where the cost (IN THIS TABLE ONLY!) is always 66.67% of the price
            // See VSO Ticket 3431 for more details

            sfToBusinessValue.recurringInternalCost += designPackEntry.recurringSellPrice * 0.67
          } else {
            sfToBusinessValue.recurringInternalCost += designPackEntry.recurringInternalCost || 0
          }
        }
      })

      return sfAndBusinessValuesArray
    },
    iQuoteCalculationsDesignPackMixin_totalContractSellPrice () {
      var totalPrice = 0

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(entry => {
        totalPrice += this.iQuoteCalculationsDesignPackMixin_entryContractSellPrice(entry)
      })

      return totalPrice
    },
    iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice () {
      var totalPrice = 0.0

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(entry => {
        if (!this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(entry)) {
          totalPrice += entry.upfrontSellPrice
        }
      })

      return totalPrice
    },
    iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost () {
      var totalCost = 0

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(entry => {
        totalCost += this.iQuoteCalculationsDesignPackMixin_entryContractThirdPartyCost(entry)
      })

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost () {
      var totalCost = 0

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(entry => {
        if (!this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(entry)) {
          totalCost += entry.upfrontExternalCost
        }
      })

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalContractInternalCost () {
      var totalCost = 0

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(entry => {
        totalCost += this.iQuoteCalculationsDesignPackMixin_entryContractInternalCost(entry)
      })

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost () {
      var totalCost = 0

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(entry => {
        if (entry.upfrontInternalCost !== 0 && !this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(entry)) {
          totalCost += entry.upfrontInternalCost
        }
      })

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalUpfrontRlecValue () {
      return this.iQuoteCalculationsDesignPackMixin_getRlecValue(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost)
    },
    iQuoteCalculationsDesignPackMixin_totalUpfrontRlecPercent () {
      return this.iQuoteCalculationsDesignPackMixin_getRlecPercent(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost)
    },
    iQuoteCalculationsDesignPackMixin_totalUpfrontGmValue () {
      return this.iQuoteCalculationsDesignPackMixin_getGmValue(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost)
    },
    iQuoteCalculationsDesignPackMixin_totalUpfrontGmPercent () {
      return this.iQuoteCalculationsDesignPackMixin_getGmPercent(this.iQuoteCalculationsDesignPackMixin_totalUpfrontSellPrice, this.iQuoteCalculationsDesignPackMixin_totalUpfrontThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalUpfrontInternalCost)
    },
    iQuoteCalculationsDesignPackMixin_yr1RecurringSellPrice () {
      if (this.iQuoteStore_GetDesignPack.termLengthMonths >= 12) {
        return this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice
      } else {
        return (this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice / 12) * this.iQuoteStore_GetDesignPack.termLengthMonths
      }
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice () {
      var totalPrice = 0

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(entry => {
        if (entry.recurringSellPrice !== 0 && !this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(entry)) {
          totalPrice += entry.recurringSellPrice
        }
      })

      return totalPrice
    },
    iQuoteCalculationsDesignPackMixin_yr1RecurringThirdPartyCost () {
      if (this.iQuoteStore_GetDesignPack.termLengthMonths >= 12) {
        return this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost
      } else {
        return (this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost / 12) * this.iQuoteStore_GetDesignPack.termLengthMonths
      }
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost () {
      var totalCost = 0

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(entry => {
        if (entry.recurringExternalCost !== 0 && !this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(entry)) {
          totalCost += entry.recurringExternalCost
        }
      })

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringGmValue () {
      return this.iQuoteCalculationsDesignPackMixin_getGmValue(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost)
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringGmValueSalesforceView () {
      return this.iQuoteCalculationsDesignPackMixin_getGmValue(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCostSalesforceView)
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringGmPercent () {
      return this.iQuoteCalculationsDesignPackMixin_getGmPercent(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost)
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringGmPercentSalesforceView () {
      return this.iQuoteCalculationsDesignPackMixin_getGmPercent(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCostSalesforceView)
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringRlecValue () {
      return this.iQuoteCalculationsDesignPackMixin_getRlecValue(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost)
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringRlecPercent () {
      return this.iQuoteCalculationsDesignPackMixin_getRlecPercent(this.iQuoteCalculationsDesignPackMixin_totalRecurringSellPrice, this.iQuoteCalculationsDesignPackMixin_totalRecurringThirdPartyCost)
    },
    iQuoteCalculationsDesignPackMixin_yr1RecurringInternalCostSalesforceView () {
      if (this.iQuoteStore_GetDesignPack.termLengthMonths >= 12) {
        return this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCostSalesforceView
      } else {
        return (this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCostSalesforceView / 12) * this.iQuoteStore_GetDesignPack.termLengthMonths
      }
    },
    iQuoteCalculationsDesignPackMixin_yr1RecurringInternalCost () {
      if (this.iQuoteStore_GetDesignPack.termLengthMonths >= 12) {
        return this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost
      } else {
        return (this.iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost / 12) * this.iQuoteStore_GetDesignPack.termLengthMonths
      }
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringInternalCost () {
      var totalCost = 0

      this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.forEach(entry => {
        if (entry.recurringInternalCost !== 0 && !this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(entry)) {
          totalCost += entry.recurringInternalCost
        }
      })

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalRecurringInternalCostSalesforceView () {
      var totalCost = 0

      this.iQuoteCalculationsDesignPackMixin_sfAndBusinessValues.forEach(entry => {
        if (entry.recurringInternalCost !== 0 && !this.iQuoteCalculationsDesignPackMixin_isDisabledSfCoe(entry.sfCoe)) {
          totalCost += entry.recurringInternalCost
        }
      })

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalContractGmValue () {
      return this.iQuoteCalculationsDesignPackMixin_getGmValue(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice, this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalContractInternalCost)
    },
    iQuoteCalculationsDesignPackMixin_totalContractGmPercent () {
      return this.iQuoteCalculationsDesignPackMixin_getGmPercent(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice, this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost + this.iQuoteCalculationsDesignPackMixin_totalContractInternalCost)
    },
    iQuoteCalculationsDesignPackMixin_totalContractRlecValue () {
      return this.iQuoteCalculationsDesignPackMixin_getRlecValue(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice, this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost)
    },
    iQuoteCalculationsDesignPackMixin_totalContractRlecPercent () {
      return this.iQuoteCalculationsDesignPackMixin_getRlecPercent(this.iQuoteCalculationsDesignPackMixin_totalContractSellPrice, this.iQuoteCalculationsDesignPackMixin_totalContractThirdPartyCost)
    },
    iQuoteCalculationsDesignPackMixin_totalHardwareTabCost () {
      var designPack = this.iQuoteStore_GetDesignPack
      var totalCost = 0

      if (designPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      for (let index = 0; index < designPack.hardwareMainEntries.length; index++) {
        const entry = designPack.hardwareMainEntries[index]
        if (!entry.disabled) {
          totalCost += this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitCostGBP(entry)
        }
      }

      for (let index = 0; index < designPack.hardwareMiscEntries.length; index++) {
        const entry = designPack.hardwareMiscEntries[index]
        if (!entry.disabled) {
          totalCost += this.iQuoteCalculationsDesignPackMixin_calcHardwareMiscEntryTotalCost(entry)
        }
      }

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalHardwareTabPrice () {
      var designPack = this.iQuoteStore_GetDesignPack
      var totalPrice = 0

      if (designPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      for (let index = 0; index < designPack.hardwareMainEntries.length; index++) {
        const entry = designPack.hardwareMainEntries[index]
        if (!entry.disabled) {
          totalPrice += this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitPriceGBP(entry)
        }
      }

      for (let index = 0; index < designPack.hardwareMiscEntries.length; index++) {
        const entry = designPack.hardwareMiscEntries[index]
        if (!entry.disabled) {
          totalPrice += this.iQuoteCalculationsDesignPackMixin_calcHardwareMiscEntryTotalPrice(entry)
        }
      }

      return totalPrice
    },
    iQuoteCalculationsDesignPackMixin_totalPSTabCost () {
      return this.iQuoteCalculationsDesignPackMixin_totalPSTabInternalCost + this.iQuoteCalculationsDesignPackMixin_totalPSTabExternalCost
    },
    iQuoteCalculationsDesignPackMixin_totalPSTabInternalCost () {
      return this.iQuoteCalculationsDesignPackMixin_totalPsCoreCost +
             this.iQuoteCalculationsDesignPackMixin_totalPsOptionsCost
    },
    iQuoteCalculationsDesignPackMixin_totalPSTabExternalCost () {
      return this.iQuoteCalculationsDesignPackMixin_totalPsThirdPartyCost +
             this.iQuoteCalculationsDesignPackMixin_totalPsExpensesCost
    },
    iQuoteCalculationsDesignPackMixin_totalCircuitsTabUpfrontCost () {
      var designPack = this.iQuoteStore_GetDesignPack
      var totalCost = 0

      if (designPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      designPack.circuitsEquinixCloudConnectEntries.forEach(entry => {
        // There is no upfront for Equinix.  If this changes, then need to add a costUpfront property to it
        // and set the value

        // totalCost += entry.costUpfront
      })

      designPack.circuitsWanConnectivityEntries.forEach(entry => {
        if (!entry.disabled) {
          totalCost += entry.costUpfront * entry.quantity
        }
      })
      designPack.circuitsHostingAndCablingEntries.forEach(entry => {
        if (!entry.disabled) {
          totalCost += entry.costUpfront * entry.quantity
        }
      })

      designPack.circuitsBroadbandEntries.forEach(entry => {
        if (!entry.disabled) {
          totalCost += entry.costUpfront * entry.quantity
        }
      })

      designPack.circuitsFixedLineVoiceEntries.forEach(entry => {
        if (!entry.disabled) {
          totalCost += entry.costUpfront * entry.quantity
        }
      })

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalCircuitsTabRecurringCost () {
      var designPack = this.iQuoteStore_GetDesignPack
      var totalCost = 0

      if (designPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      designPack.circuitsEquinixCloudConnectEntries.forEach(entry => {
        if (!entry.disabled) {
          totalCost += entry.costMonthly * 12 /* There is no quantity for Equinix */
        }
      })

      designPack.circuitsWanConnectivityEntries.forEach(entry => {
        if (!entry.disabled) {
          totalCost += entry.costMonthly * entry.quantity * 12
        }
      })
      designPack.circuitsHostingAndCablingEntries.forEach(entry => {
        if (!entry.disabled) {
          totalCost += entry.costMonthly * entry.quantity * 12
        }
      })

      designPack.circuitsBroadbandEntries.forEach(entry => {
        if (!entry.disabled) {
          totalCost += entry.costMonthly * entry.quantity * 12
        }
      })

      designPack.circuitsFixedLineVoiceEntries.forEach(entry => {
        if (!entry.disabled) {
          totalCost += entry.costMonthly * entry.quantity * 12
        }
      })

      return totalCost
    },
    iQuoteCalculationsDesignPackMixin_totalCircuitsTabUpfrontPrice () {
      var designPack = this.iQuoteStore_GetDesignPack
      var totalPrice = 0

      if (designPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      designPack.circuitsEquinixCloudConnectEntries.forEach(entry => {
        // There is no upfront for Equinix.  If this changes, then need to add a costUpfront property to it
        // and set the value

        if (!entry.disabled) {
          // totalPrice += (entry.costUpfront * entry.quantity) / ((100 - entry.marginCapexPercent) / 100)
        }
      })

      designPack.circuitsWanConnectivityEntries.forEach(entry => {
        if (!entry.disabled) {
          totalPrice += (entry.costUpfront * entry.quantity) / ((100 - entry.marginCapexPercent) / 100)
        }
      })
      designPack.circuitsHostingAndCablingEntries.forEach(entry => {
        if (!entry.disabled) {
          totalPrice += (entry.costUpfront * entry.quantity) / ((100 - entry.marginCapexPercent) / 100)
        }
      })

      designPack.circuitsBroadbandEntries.forEach(entry => {
        if (!entry.disabled) {
          totalPrice += (entry.costUpfront * entry.quantity) / ((100 - entry.marginCapexPercent) / 100)
        }
      })

      designPack.circuitsFixedLineVoiceEntries.forEach(entry => {
        if (!entry.disabled) {
          totalPrice += (entry.costUpfront * entry.quantity) / ((100 - entry.marginCapexPercent) / 100)
        }
      })

      return totalPrice
    },
    iQuoteCalculationsDesignPackMixin_totalCircuitsTabRecurringPrice () {
      var designPack = this.iQuoteStore_GetDesignPack
      var totalPrice = 0

      if (designPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      designPack.circuitsEquinixCloudConnectEntries.forEach(entry => {
        if (!entry.disabled) {
          totalPrice += (entry.costMonthly * 12) / ((100 - entry.marginOpexPercent) / 100)
        }
      })

      designPack.circuitsWanConnectivityEntries.forEach(entry => {
        if (!entry.disabled) {
          totalPrice += (entry.costMonthly * entry.quantity * 12) / ((100 - entry.marginOpexPercent) / 100)
        }
      })
      designPack.circuitsHostingAndCablingEntries.forEach(entry => {
        if (!entry.disabled) {
          totalPrice += (entry.costMonthly * entry.quantity * 12) / ((100 - entry.marginOpexPercent) / 100)
        }
      })

      designPack.circuitsBroadbandEntries.forEach(entry => {
        if (!entry.disabled) {
          totalPrice += (entry.costMonthly * entry.quantity * 12) / ((100 - entry.marginOpexPercent) / 100)
        }
      })

      designPack.circuitsFixedLineVoiceEntries.forEach(entry => {
        if (!entry.disabled) {
          totalPrice += (entry.costMonthly * entry.quantity * 12) / ((100 - entry.marginOpexPercent) / 100)
        }
      })

      return totalPrice
    },

    /*
     *  PS CALCULATIONS
    */
    iQuoteCalculationsDesignPackMixin_totalPsCoreCost () {
      var total = 0.0

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      this.iQuoteStore_GetDesignPack.psCoreEntries.forEach(entry => {
        if (!entry.disabled) {
          total += this.iQuoteStore_getPsProfileScheduleCost(entry.designPackPsRoleProfileId, entry.designPackPsScheduleId) * entry.units
        }
      })

      return total
    },
    iQuoteCalculationsDesignPackMixin_totalPsOptionsCost () {
      var total = 0.0

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      this.iQuoteStore_GetDesignPack.psOptionsEntries.forEach(entry => {
        if (!entry.disabled) {
          total += this.iQuoteStore_getPsProfileScheduleCost(entry.designPackPsRoleProfileId, entry.designPackPsScheduleId) * entry.units
        }
      })

      return total
    },
    iQuoteCalculationsDesignPackMixin_totalPsExpensesCost () {
      var total = 0.0

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      this.iQuoteStore_GetDesignPack.psExpenseEntries.forEach(entry => {
        if (!entry.disabled) {
          total += entry.unitCost * entry.units
        }
      })

      return total
    },
    iQuoteCalculationsDesignPackMixin_totalPsThirdPartyCost () {
      return this.iQuoteCalculationsDesignPackMixin_totalPsThirdPartyInstallationCost + this.iQuoteCalculationsDesignPackMixin_totalPsThirdPartyOtherCost
    },
    iQuoteCalculationsDesignPackMixin_totalPsThirdPartyInstallationCost () {
      var total = 0.0

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      this.iQuoteStore_GetDesignPack.psThirdPartyInstallationEntries.forEach(entry => {
        if (!entry.disabled) {
          total += entry.unitCost * entry.units
        }
      })

      return total
    },
    iQuoteCalculationsDesignPackMixin_totalPsThirdPartyOtherCost () {
      var total = 0.0

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackRevisionId === undefined) {
        return 0
      }

      this.iQuoteStore_GetDesignPack.psThirdPartyOtherEntries.forEach(entry => {
        if (!entry.disabled) {
          total += entry.unitCost * entry.units
        }
      })

      return total
    }
  },
  created () {
    this.updateDPDebounced = debounce(this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack, 5000)
    this.iQuoteCalculationsDesignPackMixin_updateStoreWithLatestCircuitsLookupsDebounced = debounce(this.iQuoteCalculationsDesignPackMixin_updateStoreWithLatestCircuitsLookups, 60000)
  },
  methods: {
    ...mapActions([
      'setDesignPack',
      'setCircuitsTableLookups',
      'setHardwareTableLookups',
      'setDesignPackDetailedDataEntries'
    ]),
    async iQuoteCalculationsDesignPackMixin_updateStoreWithLatestCircuitsLookups () {
      var circuitLookupTables = await this.iQuoteQuoteDesignPacksAPIMixin_getCircuitLookupTablesFromDb()
      var hardwareLookupTables = await this.iQuoteQuoteDesignPacksAPIMixin_getHardwareLookupTablesFromDb()

      this.setCircuitsTableLookups(circuitLookupTables)
      this.setHardwareTableLookups(hardwareLookupTables)
    },
    iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced (designPack, reason = 'not specified') {
      this.updateDPDebounced(designPack, reason)
      iQuoteEventBus.$emit('designpack-saving', designPack)
    },
    iQuoteCalculationsDesignPackMixin_getDesignPackItemLookupId () {
      this.iQuoteStore_GetDesignPack.lastDesignPackItemLookupId++
      return this.iQuoteStore_GetDesignPack.lastDesignPackItemLookupId
    },
    async iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack (designPack, reason = 'not specified') {
      console.log('Saving DP.  Reason:' + reason)

      this.setDesignPack(designPack) // Need to save it to the store before recalculating discounts and margins

      // Store the current state of the detail data so that any new margins / discounts can be added

      this.setDesignPackDetailedDataEntries(this.iQuoteCalculationsDesignPackDetailDataMixin_AllData())

      // Recalc margins and discounts based on what's on the designpack and quote
      this.iQuoteCalculationsDesignPackMixin_recalcDiscountsInUse()
      this.iQuoteCalculationsDesignPackMixin_recalcMarginsInUse()
      this.iQuoteCalculationsDesignPackMixin_recalcPriceOverrides()

      // Store the current state of the detail data now that margins and discounts are present to recalculate prices
      this.setDesignPackDetailedDataEntries(this.iQuoteCalculationsDesignPackDetailDataMixin_AllData())

      if (designPack.useDetailDataForSummaries) {
        // Alter the overridden price to match the totals in the detail data

        designPack.priceOverrides.forEach(entry => {
          var dpSectionDescription = entry.description
          var dpSectionSalesforceCoeCodeId = entry.salesforceCoeCodeId

          designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'recurringExternalSellPrice')
          designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'recurringInternalSellPrice')
          designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'upfrontExternalSellPrice')
          designPack = this.iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries(designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, 'upfrontInternalSellPrice')
        })
      }

      // Make a copy of the DP object so that it will trigger any watches on it (So the DP discounts table will update to new values)
      designPack = { ...designPack }

      this.setDesignPack(designPack)

      if (designPack.isReadOnly) {
        return designPack
      }

      // Save designpack to DB
      designPack = await this.iQuoteQuoteDesignPacksAPIMixin_saveQuoteDesignPackToDB(designPack)

      this.iQuoteQuoteDesignPacksAPIMixin_saveDetailData(designPack.quoteDesignPackRevisionId, this.iQuoteStore_GetDesignPackDetailedDataEntries)

      // Update the local store
      this.setDesignPack(designPack)
      iQuoteEventBus.$emit('designpack-saved', designPack)
      return designPack
    },
    iQuoteCalculationsDesignPackMixin_setMarginAsUsed (margin, overwriteValues = false) {
      // Initally the margins that we have in our list are loaded from what was saved in the DB against the
      // designpack. We don't know if that margin is still in use (just that it was at some point).  We set
      // a flag on those that are still in use, so we only allow the user to see the ones that will
      // affect the designpack, but we still keep the unused ones that were previously configured,
      // just in case the user puts back on the designpack the entries that used it

      var designPack = this.iQuoteStore_GetDesignPack

      var existingMargin = designPack.margins.find(x => x.salesforceCoeCodeId === margin.salesforceCoeCodeId && x.description === margin.description)

      if (existingMargin === undefined) {
        margin.inUse = true
        designPack.margins.push(margin)
      } else {
        existingMargin.inUse = true

        // This is a client-side property and is worked out based on whether the margin came from another source
        if (existingMargin.isReadOnly === undefined && margin.isReadOnly !== undefined) {
          existingMargin.isReadOnly = margin.isReadOnly
        }

        // The margin settings entry has been found.  Set any margins that haven't already been set to those on the margin passed in (default values)

        if (existingMargin.upfrontExternalMargin === null || overwriteValues) {
          existingMargin.upfrontExternalMargin = margin.upfrontExternalMargin
        }

        if (existingMargin.upfrontInternalMargin === null || overwriteValues) {
          existingMargin.upfrontInternalMargin = margin.upfrontInternalMargin
        }

        if (existingMargin.recurringExternalMargin === null || overwriteValues) {
          existingMargin.recurringExternalMargin = margin.recurringExternalMargin
        }

        if (existingMargin.recurringInternalMargin === null || overwriteValues) {
          existingMargin.recurringInternalMargin = margin.recurringInternalMargin
        }

        // refresh the min margins according to whatever the current limits are
        existingMargin.upfrontExternalIquoteUserMinMargin = margin.upfrontExternalIquoteUserMinMargin
        existingMargin.upfrontInternalIquoteUserMinMargin = margin.upfrontInternalIquoteUserMinMargin
        existingMargin.recurringExternalIquoteUserMinMargin = margin.recurringExternalIquoteUserMinMargin
        existingMargin.recurringInternalIquoteUserMinMargin = margin.recurringInternalIquoteUserMinMargin
      }

      this.setDesignPack(designPack)
    },
    iQuoteCalculationsDesignPackMixin_setDiscountAsUsed (discount) {
      var designPack = this.iQuoteStore_GetDesignPack
      var existingDiscount = designPack.discounts.find(x => x.salesforceCoeCodeId === discount.salesforceCoeCodeId && x.description === discount.description)

      if (existingDiscount === undefined) {
        discount.inUse = true
        designPack.discounts.push(discount)
      } else {
        existingDiscount.inUse = true

        // The discount settings entry has been found.  Set any discounts that haven't already been set to those on the discount passed in (default values)

        if (existingDiscount.upfrontExternalDiscount === null) {
          existingDiscount.upfrontExternalDiscount = discount.upfrontExternalDiscount
        }

        if (existingDiscount.upfrontInternalDiscount === null) {
          existingDiscount.upfrontInternalDiscount = discount.upfrontInternalDiscount
        }

        if (existingDiscount.recurringExternalDiscount === null) {
          existingDiscount.recurringExternalDiscount = discount.recurringExternalDiscount
        }

        if (existingDiscount.recurringInternalDiscount === null) {
          existingDiscount.recurringInternalDiscount = discount.recurringInternalDiscount
        }

        existingDiscount.upfrontExternalMaxDiscount = discount.upfrontExternalMaxDiscount
        existingDiscount.upfrontInternalMaxDiscount = discount.upfrontInternalMaxDiscount
        existingDiscount.recurringExternalMaxDiscount = discount.recurringExternalMaxDiscount
        existingDiscount.recurringInternalMaxDiscount = discount.recurringInternalMaxDiscount
      }

      this.setDesignPack(designPack)
    },
    iQuoteCalculationsDesignPackMixin_getTempMarginId () {
      var designPack = this.iQuoteStore_GetDesignPack

      /* items that are not saved to the db yet will have negative ids */
      var minId = -1
      var arr = designPack.margins

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    iQuoteCalculationsDesignPackMixin_getTempDiscountId () {
      var designPack = this.iQuoteStore_GetDesignPack

      /* items that are not saved to the db yet will have negative ids */
      var minId = -1
      var arr = designPack.discounts
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    async iQuoteCalculationsDesignPackMixin_recalcDiscountsInUse () {
      var designPackTableSections = this.iQuoteStore_GetDesignPackTableSections
      var designPackEntries = this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries
      var foundDesignPacktableSectionDefaults

      for (let i = 0; i < designPackEntries.length; i++) {
        const designPackEntry = designPackEntries[i]
        foundDesignPacktableSectionDefaults = false

        for (let j = 0; j < designPackTableSections.length; j++) {
          const designPackSection = designPackTableSections[j]

          if (designPackEntry.designPackTableSectionId === designPackSection.id) {
            foundDesignPacktableSectionDefaults = true

            if ((
              (designPackEntry.priceOverriden === true || /* Need this in case the price was overridden to £0 */
              designPackEntry.upfrontExternalPrice !== 0 ||
              designPackEntry.upfrontInternalPrice !== 0 ||
              designPackEntry.recurringExternalPrice !== 0 ||
              designPackEntry.recurringInternalPrice !== 0) &&
              ((designPackSection.canBeDiscounted && designPackSection.precalculatedPricing === true) ||
               designPackEntry.canBeDiscounted)
            )) {
              this.iQuoteCalculationsDesignPackMixin_setDiscountAsUsed({
                id: this.iQuoteCalculationsDesignPackMixin_getTempDiscountId(),
                description: designPackEntry.description,
                salesforceCoeCodeId: designPackSection.salesforceCoeCodeId,
                upfrontExternalDiscount: designPackSection.upfrontExternalDefaultDiscount,
                upfrontInternalDiscount: designPackSection.upfrontInternalDefaultDiscount,
                recurringExternalDiscount: designPackSection.recurringExternalDefaultDiscount,
                recurringInternalDiscount: designPackSection.recurringInternalDefaultDiscount,
                upfrontExternalMaxDiscount: designPackSection.upfrontExternalMaxDiscount,
                upfrontInternalMaxDiscount: designPackSection.upfrontInternalMaxDiscount,
                recurringExternalMaxDiscount: designPackSection.recurringExternalMaxDiscount,
                recurringInternalMaxDiscount: designPackSection.recurringInternalMaxDiscount
              })
            }
          }
        }

        if (!foundDesignPacktableSectionDefaults) {
          if ((
            (designPackEntry.priceOverriden === true || /* Need this in case the price was overridden to £0 */
             designPackEntry.upfrontSellPrice !== 0 ||
             designPackEntry.recurringSellPrice !== 0
            ) && designPackEntry.canBeDiscounted
          )) {
            this.iQuoteCalculationsDesignPackMixin_setDiscountAsUsed({
              id: this.iQuoteCalculationsDesignPackMixin_getTempDiscountId(),
              description: designPackEntry.description,
              salesforceCoeCodeId: this.iQuoteFunctions_getSF_COE_IdFromName(designPackEntry.SF_COE),
              upfrontExternalDiscount: null,
              upfrontInternalDiscount: null,
              recurringExternalDiscount: null,
              recurringInternalDiscount: null
            })
          }
        }
      }

      var designPack = this.iQuoteStore_GetDesignPack
      designPack.discounts = designPack.discounts.filter(x => x.inUse === true)

      // Suppress the input of discounts that are not used on this quote, and set discount to 0 for any that don't already have a value
      for (let index = 0; index < designPack.discounts.length; index++) {
        const discount = designPack.discounts[index]

        var designPackDiscountEntriesUsingUpfrontExternal = designPackEntries.filter(x => x.description === discount.description && this.iQuoteFunctions_getSF_COE_IdFromName(x.SF_COE) === discount.salesforceCoeCodeId && x.upfrontExternalPrice !== 0 && x.canBeDiscounted)
        var designPackDiscountEntriesUsingUpfrontInternal = designPackEntries.filter(x => x.description === discount.description && this.iQuoteFunctions_getSF_COE_IdFromName(x.SF_COE) === discount.salesforceCoeCodeId && x.upfrontInternalPrice !== 0 && x.canBeDiscounted)
        var designPackDiscountEntriesUsingRecurringExternal = designPackEntries.filter(x => x.description === discount.description && this.iQuoteFunctions_getSF_COE_IdFromName(x.SF_COE) === discount.salesforceCoeCodeId && x.recurringExternalPrice !== 0 && x.canBeDiscounted)
        var designPackDiscountEntriesUsingRecurringInternal = designPackEntries.filter(x => x.description === discount.description && this.iQuoteFunctions_getSF_COE_IdFromName(x.SF_COE) === discount.salesforceCoeCodeId && x.recurringInternalPrice !== 0 && x.canBeDiscounted)

        if (designPackDiscountEntriesUsingUpfrontExternal.length === 0) {
          discount.upfrontExternalDiscount = null
        } else {
          if (discount.upfrontExternalDiscount === null) {
            discount.upfrontExternalDiscount = 0
          }
        }

        if (designPackDiscountEntriesUsingRecurringExternal.length === 0) {
          discount.recurringExternalDiscount = null
        } else {
          if (discount.recurringExternalDiscount === null) {
            discount.recurringExternalDiscount = 0
          }
        }

        if (designPackDiscountEntriesUsingUpfrontInternal.length === 0) {
          discount.upfrontInternalDiscount = null
        } else {
          if (discount.upfrontInternalDiscount === null) {
            discount.upfrontInternalDiscount = 0
          }
        }

        if (designPackDiscountEntriesUsingRecurringInternal.length === 0) {
          discount.recurringInternalDiscount = null
        } else {
          if (discount.recurringInternalDiscount === null) {
            discount.recurringInternalDiscount = 0
          }
        }
      }
    },
    iQuoteCalculationsDesignPackMixin_recalcPriceOverrides () {
      var designPack = this.iQuoteStore_GetDesignPack
      var priceOverrides = designPack.priceOverrides
      var designPackEntries = this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries

      if (priceOverrides === undefined) {
        priceOverrides = []
      } else {
        priceOverrides.forEach(entry => {
          // Initially set all overrides as unused.  We'll set back to used if we find any matching entries that use it
          entry.inUse = false
        })
      }

      var priceOverride

      for (let i = 0; i < designPackEntries.length; i++) {
        const designPackEntry = designPackEntries[i]

        priceOverride = priceOverrides.find(x => x.description === designPackEntry.description && x.salesforceCoeCodeId === this.iQuoteFunctions_getSF_COE_IdFromName(designPackEntry.SF_COE))

        if ((
          (designPackEntry.priceOverriden === true || /* Need this in case the price was overridden to £0 */
          designPackEntry.upfrontExternalPrice !== 0 ||
          designPackEntry.upfrontInternalPrice !== 0 ||
          designPackEntry.recurringExternalPrice !== 0 ||
          designPackEntry.recurringInternalPrice !== 0) &&
          designPackEntry.priceOverrideAllowed === true
        )) {
          if (priceOverride === undefined) {
            // This is an entry that we've not got a saved price override entry for.  So add one
            priceOverride = {
              description: designPackEntry.description,
              salesforceCoeCodeId: this.iQuoteFunctions_getSF_COE_IdFromName(designPackEntry.SF_COE),
              recurringInternalSellPrice: null,
              recurringExternalSellPrice: null,
              upfrontInternalSellPrice: null,
              upfrontExternalSellPrice: null
            }
            priceOverrides.push(priceOverride)
          } else {
            // We already have a price override stored for this description / SF COE Code

            // Set to null any price entries with no price
            priceOverride.recurringInternalSellPrice = designPackEntry.recurringInternalPrice === 0 ? null : priceOverride.recurringInternalSellPrice
            priceOverride.recurringExternalSellPrice = designPackEntry.recurringExternalPrice === 0 ? null : priceOverride.recurringExternalSellPrice
            priceOverride.upfrontInternalSellPrice = designPackEntry.upfrontInternalPrice === 0 ? null : priceOverride.upfrontInternalSellPrice
            priceOverride.upfrontExternalSellPrice = designPackEntry.upfrontExternalPrice === 0 ? null : priceOverride.upfrontExternalSellPrice
          }

          priceOverride.upfrontInternalSellPriceInUse = designPackEntry.upfrontInternalPrice !== 0 && designPackEntry.upfrontInternalPrice !== null
          priceOverride.upfrontExternalSellPriceInUse = designPackEntry.upfrontExternalPrice !== 0 && designPackEntry.upfrontExternalPrice !== null
          priceOverride.recurringInternalSellPriceInUse = designPackEntry.recurringInternalPrice !== 0 && designPackEntry.recurringInternalPrice !== null
          priceOverride.recurringExternalSellPriceInUse = designPackEntry.recurringExternalPrice !== 0 && designPackEntry.recurringExternalPrice !== null

          priceOverride.inUse = true
        }
      }

      // Remove any price overrides that aren't applicable any more, because there are no discounts are margins that
      // have values
      designPack.priceOverrides = priceOverrides.filter(x => x.inUse === true)

      this.setDesignPack(designPack)
    },
    async iQuoteCalculationsDesignPackMixin_recalcMarginsInUse () {
      var designPackTableSections = this.iQuoteStore_GetDesignPackTableSections
      var designPackEntries = this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries
      var foundDesignPacktableSectionDefaults

      /* Calls and lines calculator has margins within it, we'll use those by default */
      if (this.externalQuotesModelMixin_State.quoteCallsAndLines.id !== undefined) {
        this.externalQuotesModelMixin_State.quoteCallsAndLines.costSummaryEntries.forEach(entry => {
          //  If the entry has at least one cell with a cost
          if ((
            entry.upfrontExternalCost !== 0 ||
            entry.upfrontInternalCost !== 0 ||
            entry.recurringExternalCost !== 0 ||
            entry.recurringInternalCost !== 0
          )) {
            /*
             *  These margins are often calculated using formulas in Excel,
                so the values can be many decimal places.  So if there is a margin
                found, we'll reduce it to just 2 decimal places for the design pack
             */
            if (entry.upfrontMargin !== null) {
              entry.upfrontMargin = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontMargin)
            }
            if (entry.recurringMargin !== null) {
              entry.recurringMargin = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringMargin)
            }

            /*
              Create a new margin with the margin values found for this entry
              (some can be null, which means no margin set)
            */
            var newMargin = {
              id: this.iQuoteCalculationsDesignPackMixin_getTempMarginId(),
              description: entry.description,
              salesforceCoeCodeId: entry.salesforceCoeCodeId,
              upfrontExternalMargin: entry.upfrontMargin,
              upfrontInternalMargin: entry.upfrontMargin,
              recurringExternalMargin: entry.recurringMargin,
              recurringInternalMargin: entry.recurringMargin,
              isReadOnly: true
            }

            /* iQuote users are allowed to set margins to +/- 10% from the default,
               so set those values if there is a margin.  0% is the minimum allowed. */
            if (entry.upfrontMargin !== null) {
              newMargin.upfrontExternalIquoteUserMinMargin = (entry.upfrontMargin < 10) ? 0 : entry.upfrontMargin - 10
              newMargin.upfrontInternalIquoteUserMinMargin = newMargin.upfrontExternalIquoteUserMinMargin
            } else {
              newMargin.upfrontExternalIquoteUserMinMargin = null
              newMargin.upfrontInternalIquoteUserMinMargin = null
            }

            if (entry.recurringMargin !== null) {
              newMargin.recurringExternalIquoteUserMinMargin = (entry.recurringMargin < 10) ? 0 : entry.recurringMargin - 10
              newMargin.recurringInternalIquoteUserMinMargin = newMargin.recurringExternalIquoteUserMinMargin
            } else {
              newMargin.recurringExternalIquoteUserMinMargin = null
              newMargin.recurringInternalIquoteUserMinMargin = null
            }
            this.iQuoteCalculationsDesignPackMixin_setMarginAsUsed(newMargin, true)
          }
        })
      }

      /* Horizon calculator has margins within it, we'll use those by default */
      if (this.externalQuotesModelMixin_State.quoteHorizon.id !== undefined) {
        this.externalQuotesModelMixin_State.quoteHorizon.costSummaryEntries.forEach(entry => {
          //  If the entry has at least one cell with a cost
          if ((
            entry.upfrontExternalCost > 0 ||
            entry.upfrontInternalCost > 0 ||
            entry.recurringExternalCost > 0 ||
            entry.recurringInternalCost > 0
          )) {
            /*
             *  These margins are often calculated using formulas in Excel,
                so the values can be many decimal places.  So if there is a margin
                found, we'll reduce it to just 2 decimal places for the design pack
             */
            if (entry.upfrontMargin !== null) {
              entry.upfrontMargin = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.upfrontMargin)
            }
            if (entry.recurringMargin !== null) {
              entry.recurringMargin = this.iQuoteFunctions_RoundToTwoDecimalPlaces(entry.recurringMargin)
            }

            /*
              Create a new margin with the margin values found for this entry
              (some can be null, which means no margin set)
            */
            var newMargin = {
              id: this.iQuoteCalculationsDesignPackMixin_getTempMarginId(),
              description: entry.description,
              salesforceCoeCodeId: entry.salesforceCoeCodeId,
              upfrontExternalMargin: entry.upfrontMargin,
              upfrontInternalMargin: entry.upfrontMargin,
              recurringExternalMargin: entry.recurringMargin,
              recurringInternalMargin: entry.recurringMargin,
              isReadOnly: true
            }

            /* iQuote users are allowed to set margins to +/- 10% from the default,
               so set those values if there is a margin.  0% is the minimum allowed. */
            if (entry.upfrontMargin !== null) {
              newMargin.upfrontExternalIquoteUserMinMargin = (entry.upfrontMargin < 10) ? 0 : entry.upfrontMargin - 10
              newMargin.upfrontInternalIquoteUserMinMargin = newMargin.upfrontExternalIquoteUserMinMargin
            } else {
              newMargin.upfrontExternalIquoteUserMinMargin = null
              newMargin.upfrontInternalIquoteUserMinMargin = null
            }

            if (entry.recurringMargin !== null) {
              newMargin.recurringExternalIquoteUserMinMargin = (entry.recurringMargin < 10) ? 0 : entry.recurringMargin - 10
              newMargin.recurringInternalIquoteUserMinMargin = newMargin.recurringExternalIquoteUserMinMargin
            } else {
              newMargin.recurringExternalIquoteUserMinMargin = null
              newMargin.recurringInternalIquoteUserMinMargin = null
            }

            this.iQuoteCalculationsDesignPackMixin_setMarginAsUsed(newMargin, true)
          }
        })
      }

      /*
          First we look through all of the designpack entries and mark the designpack sections that are used
          by those entries
      */
      for (let i = 0; i < designPackEntries.length; i++) {
        const designPackEntry = designPackEntries[i]
        foundDesignPacktableSectionDefaults = false

        for (let j = 0; j < designPackTableSections.length; j++) {
          const designPackSection = designPackTableSections[j]

          if (designPackEntry.designPackTableSectionId === designPackSection.id ||
            (designPackEntry.designPackTableSectionId === 0 &&
              designPackEntry.description === designPackSection.description &&
              designPackEntry.SF_COE === designPackSection.coe
            )) {
            foundDesignPacktableSectionDefaults = true

            if ((
              (designPackEntry.upfrontExternalCost !== 0 ||
              designPackEntry.upfrontInternalCost !== 0 ||
              designPackEntry.recurringExternalCost !== 0 ||
              designPackEntry.recurringInternalCost !== 0) &&
              designPackEntry.usesGlobalMargins &&
              designPackSection.precalculatedPricing !== true
            )) {
              this.iQuoteCalculationsDesignPackMixin_setMarginAsUsed({
                id: this.iQuoteCalculationsDesignPackMixin_getTempMarginId(),
                description: designPackEntry.description,
                salesforceCoeCodeId: designPackSection.salesforceCoeCodeId,
                upfrontExternalMargin: designPackSection.upfrontExternalDefaultMargin,
                upfrontInternalMargin: designPackSection.upfrontInternalDefaultMargin,
                recurringExternalMargin: designPackSection.recurringExternalDefaultMargin,
                recurringInternalMargin: designPackSection.recurringInternalDefaultMargin,
                upfrontExternalIquoteUserMinMargin: designPackSection.upfrontExternalIquoteUserMinMargin,
                upfrontInternalIquoteUserMinMargin: designPackSection.upfrontInternalIquoteUserMinMargin,
                recurringExternalIquoteUserMinMargin: designPackSection.recurringExternalIquoteUserMinMargin,
                recurringInternalIquoteUserMinMargin: designPackSection.recurringInternalIquoteUserMinMargin
              })
            }
          }
        }

        if (!foundDesignPacktableSectionDefaults) {
          if ((
            (designPackEntry.upfrontExternalCost !== 0 ||
            designPackEntry.upfrontInternalCost !== 0 ||
            designPackEntry.recurringExternalCost !== 0 ||
            designPackEntry.recurringInternalCost !== 0) && designPackEntry.usesGlobalMargins
          )) {
            this.iQuoteCalculationsDesignPackMixin_setMarginAsUsed({
              id: this.iQuoteCalculationsDesignPackMixin_getTempMarginId(),
              description: designPackEntry.description,
              salesforceCoeCodeId: this.iQuoteFunctions_getSF_COE_IdFromName(designPackEntry.SF_COE),
              upfrontExternalMargin: null,
              upfrontInternalMargin: null,
              recurringExternalMargin: null,
              recurringInternalMargin: null
            })
          }
        }
      }

      var designPack = this.iQuoteStore_GetDesignPack
      designPack.margins = designPack.margins.filter(x => x.inUse === true)

      // Suppress the input of margins that are not used on this quote, and set default margin if not already set
      for (let index = 0; index < designPack.margins.length; index++) {
        const margin = designPack.margins[index]

        var designPackMarginEntriesUsingUpfrontExternal = designPackEntries.filter(x => x.description === margin.description && this.iQuoteFunctions_getSF_COE_IdFromName(x.SF_COE) === margin.salesforceCoeCodeId && x.upfrontExternalCost !== 0 && x.usesGlobalMargins)
        var designPackMarginEntriesUsingUpfrontInternal = designPackEntries.filter(x => x.description === margin.description && this.iQuoteFunctions_getSF_COE_IdFromName(x.SF_COE) === margin.salesforceCoeCodeId && x.upfrontInternalCost !== 0 && x.usesGlobalMargins)
        var designPackMarginEntriesUsingRecurringExternal = designPackEntries.filter(x => x.description === margin.description && this.iQuoteFunctions_getSF_COE_IdFromName(x.SF_COE) === margin.salesforceCoeCodeId && x.recurringExternalCost !== 0 && x.usesGlobalMargins)
        var designPackMarginEntriesUsingRecurringInternal = designPackEntries.filter(x => x.description === margin.description && this.iQuoteFunctions_getSF_COE_IdFromName(x.SF_COE) === margin.salesforceCoeCodeId && x.recurringInternalCost !== 0 && x.usesGlobalMargins)

        var defaultMargin = this.iQuoteConstants.salesMargins.DEFAULT_MARGIN

        if (margin.salesforceCoeCodeId === this.iQuoteConstants.salesforceCoeCodes.PROFESSIONAL_SERVICES) {
          defaultMargin = this.iQuoteConstants.salesMargins.PROFESSIONAL_SERVICES_DEFAULT_INTERNAL_MARGIN
        } else if (margin.salesforceCoeCodeId === this.iQuoteConstants.salesforceCoeCodes.THIRD_PARTY_PRO_SERVICES) {
          defaultMargin = this.iQuoteConstants.salesMargins.THIRD_PARTY_PRO_SERVICES_DEFAULT_EXTERNAL_MARGIN
        } else if (margin.salesforceCoeCodeId === this.iQuoteConstants.salesforceCoeCodes.TECHNOLOGY) {
          defaultMargin = this.iQuoteConstants.salesMargins.TECHNOLOGY_DEFAULT_MARGIN
        }

        /* Set default margins for any combinations of description / SF COE that
           weren't in the designpackstablesection list (or no margin was specified in that
           list) */
        if (designPackMarginEntriesUsingUpfrontExternal.length === 0) {
          margin.upfrontExternalMargin = null
        } else if (margin.upfrontExternalMargin === null) {
          margin.upfrontExternalMargin = defaultMargin
        }

        if (designPackMarginEntriesUsingUpfrontInternal.length === 0) {
          margin.upfrontInternalMargin = null
        } else if (margin.upfrontInternalMargin === null) {
          margin.upfrontInternalMargin = defaultMargin
        }

        if (designPackMarginEntriesUsingRecurringExternal.length === 0) {
          margin.recurringExternalMargin = null
        } else if (margin.recurringExternalMargin === null) {
          margin.recurringExternalMargin = defaultMargin
        }

        if (designPackMarginEntriesUsingRecurringInternal.length === 0) {
          margin.recurringInternalMargin = null
        } else if (margin.recurringInternalMargin === null) {
          margin.recurringInternalMargin = defaultMargin
        }

        /* Set the upper and lower limits for margins for any values that didn't
           have this set from the designpacktablesections list */

        if (margin.upfrontExternalMargin !== null && margin.upfrontExternalIquoteUserMinMargin === undefined) {
          margin.upfrontExternalIquoteUserMinMargin = this.iQuoteCalculationsDesignPackMixin_defaultMinMarginForSalesforceCode(margin.salesforceCoeCodeId)
        }

        if (margin.upfrontInternalMargin !== null && margin.upfrontInternalIquoteUserMinMargin === undefined) {
          margin.upfrontInternalIquoteUserMinMargin = this.iQuoteCalculationsDesignPackMixin_defaultMinMarginForSalesforceCode(margin.salesforceCoeCodeId)
        }

        if (margin.recurringExternalMargin !== null && margin.recurringExternalIquoteUserMinMargin === undefined) {
          margin.recurringExternalIquoteUserMinMargin = this.iQuoteCalculationsDesignPackMixin_defaultMinMarginForSalesforceCode(margin.salesforceCoeCodeId)
        }
        if (margin.recurringInternalMargin !== null && margin.recurringInternalIquoteUserMinMargin === undefined) {
          margin.recurringInternalIquoteUserMinMargin = this.iQuoteCalculationsDesignPackMixin_defaultMinMarginForSalesforceCode(margin.salesforceCoeCodeId)
        }
      }
    },
    iQuoteCalculationsDesignPackMixin_defaultMinMarginForSalesforceCode (salesforceCoeCodeId) {
      var returnVal = this.iQuoteConstants.salesMargins.DEFAULT_MARGIN

      switch (salesforceCoeCodeId) {
        case this.iQuoteConstants.salesforceCoeCodes.TECHNOLOGY:
          returnVal = 0
          break
        case this.iQuoteConstants.salesforceCoeCodes.NEW_VENDOR_SUPPORT_SA:
          returnVal = 0
          break
        case this.iQuoteConstants.salesforceCoeCodes.NEW_THIRD_PARTY_SUPPORT:
          returnVal = 0
          break
        case this.iQuoteConstants.salesforceCoeCodes.THIRD_PARTY_PRO_SERVICES:
          returnVal = 0
          break
      }

      return returnVal
    },
    iQuoteCalculationsDesignPackMixin_entryContractSellPrice (entry) {
      if (!this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(entry)) {
        return entry.upfrontSellPrice + (this.iQuoteStore_GetDesignPack.termLengthMonths * (entry.recurringSellPrice / 12))
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackMixin_entryContractThirdPartyCost (entry) {
      if (!this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(entry)) {
        return entry.upfrontExternalCost + (this.iQuoteStore_GetDesignPack.termLengthMonths * (entry.recurringExternalCost / 12))
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackMixin_entryContractInternalCost (entry) {
      if (!this.iQuoteCalculationsDesignPackMixin_isDisabledEntry(entry)) {
        return entry.upfrontInternalCost + (this.iQuoteStore_GetDesignPack.termLengthMonths * (entry.recurringInternalCost / 12))
      } else {
        return 0
      }
    },
    iQuoteCalculationsDesignPackMixin_isDisabledEntry (entry) {
      var designPack = this.iQuoteStore_GetDesignPack
      var salesforceCoeCodeId = this.iQuoteFunctions_getSF_COE_IdFromName(entry.SF_COE)
      var disabledEntryItemIndex = designPack.disabledEntries.findIndex(x => x.source === entry.source && x.description === entry.description && x.salesforceCoeCodeId === salesforceCoeCodeId)
      return disabledEntryItemIndex >= 0
    },
    iQuoteCalculationsDesignPackMixin_disableEntry (entry) {
      var designPack = this.iQuoteStore_GetDesignPack
      var salesforceCoeCodeId = this.iQuoteFunctions_getSF_COE_IdFromName(entry.SF_COE)
      var disabledEntryItemIndex = designPack.disabledEntries.findIndex(x => x.source === entry.source && x.description === entry.description && x.salesforceCoeCodeId === salesforceCoeCodeId)

      if (disabledEntryItemIndex === -1) {
        designPack.disabledEntries.push({
          source: entry.source,
          description: entry.description,
          salesforceCoeCodeId: salesforceCoeCodeId
        })
        this.iQuoteFunctions_ShowMessage('The entry has been disabled.  Costs now removed from designpack')
        this.setDesignPack(designPack)
        this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack, 'dpEntry Disabled: ' + entry.description)
      }
    },
    iQuoteCalculationsDesignPackMixin_enableEntry (entry) {
      var designPack = this.iQuoteStore_GetDesignPack
      var salesforceCoeCodeId = this.iQuoteFunctions_getSF_COE_IdFromName(entry.SF_COE)
      var disabledEntryItemIndex = designPack.disabledEntries.findIndex(x => x.source === entry.source && x.description === entry.description && x.salesforceCoeCodeId === salesforceCoeCodeId)

      if (disabledEntryItemIndex !== -1) {
        designPack.disabledEntries.splice(disabledEntryItemIndex, 1)
        this.iQuoteFunctions_ShowMessage('The entry has been enabled.  Costs have been restored to this designpack')
        this.setDesignPack(designPack)
        this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPack(designPack, 'dpEntry Enabled: ' + entry.description)
      }
    },
    iQuoteCalculationsDesignPackMixin_isDisabledSfCoe (sfCoe) {
      var designPack = this.iQuoteStore_GetDesignPack
      var salesforceCoeCodeId = this.iQuoteFunctions_getSF_COE_IdFromName(sfCoe)

      var instancesDisabled = designPack.disabledEntries.filter(x => x.salesforceCoeCodeId === salesforceCoeCodeId)
      var instancesInDesignPack = this.iQuoteCalculationsDesignPackMixin_designPackSummaryEntries.filter(x => x.SF_COE === sfCoe)

      return instancesDisabled.length === instancesInDesignPack.length
    },
    iQuoteCalculationsDesignPackMixin_updateMargins (newMargins) {
      this.iQuoteStore_GetDesignPack.margins = newMargins
    },
    iQuoteCalculationsDesignPackMixin_updateDiscounts (newDiscounts) {
      this.iQuoteStore_GetDesignPack.discounts = newDiscounts
    },
    iQuoteCalculationsDesignPackMixin_getUpfrontExternalMargin (designPackEntry) {
      var returnVal = 0

      this.iQuoteStore_GetDesignPack.margins.forEach(margin => {
        if (margin.description.toLowerCase() === designPackEntry.description.toLowerCase() &&
          this.iQuoteFunctions_getSF_COE_NameFromId(margin.salesforceCoeCodeId).toLowerCase() === designPackEntry.SF_COE.toLowerCase()) {
          returnVal = margin.upfrontExternalMargin
        }
      })

      return returnVal
    },
    iQuoteCalculationsDesignPackMixin_getRecurringExternalMargin (designPackEntry) {
      var returnVal = 0

      this.iQuoteStore_GetDesignPack.margins.forEach(margin => {
        if (margin.description.toLowerCase() === designPackEntry.description.toLowerCase() &&
          this.iQuoteFunctions_getSF_COE_NameFromId(margin.salesforceCoeCodeId).toLowerCase() === designPackEntry.SF_COE.toLowerCase()) {
          returnVal = margin.recurringExternalMargin
        }
      })

      return returnVal
    },
    iQuoteCalculationsDesignPackMixin_getUpfrontInternalMargin (designPackEntry) {
      var returnVal = 0

      this.iQuoteStore_GetDesignPack.margins.forEach(margin => {
        if (margin.description.toLowerCase() === designPackEntry.description.toLowerCase() &&
          this.iQuoteFunctions_getSF_COE_NameFromId(margin.salesforceCoeCodeId).toLowerCase() === designPackEntry.SF_COE.toLowerCase()) {
          returnVal = margin.upfrontInternalMargin
        }
      })

      return returnVal
    },
    iQuoteCalculationsDesignPackMixin_getRecurringInternalMargin (designPackEntry) {
      var returnVal = 0

      this.iQuoteStore_GetDesignPack.margins.forEach(margin => {
        if (margin.description.toLowerCase() === designPackEntry.description.toLowerCase() &&
          this.iQuoteFunctions_getSF_COE_NameFromId(margin.salesforceCoeCodeId).toLowerCase() === designPackEntry.SF_COE.toLowerCase()) {
          returnVal = margin.recurringInternalMargin
        }
      })

      return returnVal
    },
    iQuoteCalculationsDesignPackMixin_getUpfrontExternalDiscount (designPackEntry) {
      var returnVal = 0

      this.iQuoteStore_GetDesignPack.discounts.forEach(discount => {
        if (discount.description.toLowerCase() === designPackEntry.description.toLowerCase() &&
          this.iQuoteFunctions_getSF_COE_NameFromId(discount.salesforceCoeCodeId).toLowerCase() === designPackEntry.SF_COE.toLowerCase()) {
          returnVal = discount.upfrontExternalDiscount
        }
      })

      return returnVal
    },
    iQuoteCalculationsDesignPackMixin_getRecurringExternalDiscount (designPackEntry) {
      var returnVal = 0

      this.iQuoteStore_GetDesignPack.discounts.forEach(discount => {
        if (discount.description.toLowerCase() === designPackEntry.description.toLowerCase() &&
          this.iQuoteFunctions_getSF_COE_NameFromId(discount.salesforceCoeCodeId).toLowerCase() === designPackEntry.SF_COE.toLowerCase()) {
          returnVal = discount.recurringExternalDiscount
        }
      })

      return returnVal
    },
    iQuoteCalculationsDesignPackMixin_getUpfrontInternalDiscount (designPackEntry) {
      var returnVal = 0

      this.iQuoteStore_GetDesignPack.discounts.forEach(discount => {
        if (discount.description.toLowerCase() === designPackEntry.description.toLowerCase() &&
          this.iQuoteFunctions_getSF_COE_NameFromId(discount.salesforceCoeCodeId).toLowerCase() === designPackEntry.SF_COE.toLowerCase()) {
          returnVal = discount.upfrontInternalDiscount
        }
      })

      return returnVal
    },
    iQuoteCalculationsDesignPackMixin_getRecurringInternalDiscount (designPackEntry) {
      var returnVal = 0

      this.iQuoteStore_GetDesignPack.discounts.forEach(discount => {
        if (discount.description.toLowerCase() === designPackEntry.description.toLowerCase() &&
          this.iQuoteFunctions_getSF_COE_NameFromId(discount.salesforceCoeCodeId).toLowerCase() === designPackEntry.SF_COE.toLowerCase()) {
          returnVal = discount.recurringInternalDiscount
        }
      })

      return returnVal
    },
    iQuoteCalculationsDesignPackMixin_getCostDisplay (value, maxDecimalPlaces = 2) {
      if (value === 0 || value === null || isNaN(value) || !Number.isFinite(value)) {
        return '-'
      } else {
        return '£' + this.iQuoteFunctions_ThousandSeparator(value.toFixed(maxDecimalPlaces))
      }
    },
    iQuoteCalculationsDesignPackMixin_getRlecValue (salePrice, thirdPartyCost) {
      if (salePrice === 0 || salePrice === null || isNaN(salePrice) || !Number.isFinite(salePrice)) {
        return '-'
      } else if (thirdPartyCost === 0 || thirdPartyCost === null) {
        return this.iQuoteCalculationsDesignPackMixin_getCostDisplay(salePrice)
      } else {
        return this.iQuoteCalculationsDesignPackMixin_getCostDisplay(salePrice - thirdPartyCost)
      }
    },
    iQuoteCalculationsDesignPackMixin_getRlecPercent (salePrice, thirdPartyCost) {
      if (salePrice === 0 || salePrice === null || isNaN(salePrice) || !Number.isFinite(salePrice)) {
        return '-'
      } else if (thirdPartyCost === 0 || thirdPartyCost === null) {
        return '100%'
      } else {
        return (((salePrice - thirdPartyCost) / salePrice) * 100).toFixed(2) + '%'
      }
    },
    iQuoteCalculationsDesignPackMixin_getGmValue (salePrice, totalCost) {
      if (totalCost === 0 || totalCost === null || isNaN(totalCost) || !Number.isFinite(totalCost)) {
        return this.iQuoteCalculationsDesignPackMixin_getCostDisplay(salePrice)
      } else {
        return this.iQuoteCalculationsDesignPackMixin_getCostDisplay(salePrice - totalCost)
      }
    },
    iQuoteCalculationsDesignPackMixin_getGmPercent (salePrice, totalCost) {
      if (salePrice === 0 || salePrice === null || isNaN(salePrice) || !Number.isFinite(salePrice)) {
        return '-'
      } else if (totalCost === 0 || totalCost === null) {
        return '100.00%'
      } else {
        return (((salePrice - totalCost) / salePrice) * 100).toFixed(2) + '%'
      }
    },

    /*
     Hardware methods
    */
    iQuoteCalculationsDesignPackMixin_getHardwareMiscEntryCode (hardwareMiscEntry) {
      if (hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId !== 0) {
        return this.iQuoteStore_GetHardwareTableLookups.miscEquipmentSubTypes.filter(x => x.id === hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId)[0].code
      } else {
        return '' /* Can't be calculated yet */
      }
    },
    iQuoteCalculationsDesignPackMixin_getHardwareMiscEntryUnitPrice (hardwareMiscEntry) {
      if (hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId !== 0) {
        return this.iQuoteStore_GetHardwareTableLookups.miscEquipmentSubTypes.filter(x => x.id === hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId)[0].price
      } else {
        return -1 /* Can't be calculated yet */
      }
    },
    iQuoteCalculationsDesignPackMixin_getHardwareMiscEntryUnitCost (hardwareMiscEntry) {
      if (hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId !== 0) {
        return this.iQuoteStore_GetHardwareTableLookups.miscEquipmentSubTypes.filter(x => x.id === hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId)[0].cost
      } else {
        return -1 /* Can't be calculated yet */
      }
    },
    iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryUnitCostGBP (hardwareMainEntry) {
      switch (hardwareMainEntry.designPackHardwareCurrencyId) {
        case this.iQuoteCalculationsDesignPackMixin.hardwareCurrencies.filter(x => x.description === 'GBP')[0].id:
          return hardwareMainEntry.unitCost
        case this.iQuoteCalculationsDesignPackMixin.hardwareCurrencies.filter(x => x.description === 'USD')[0].id:
          return hardwareMainEntry.unitCost * this.iQuoteStore_GetDesignPack.exchangeRateUSDToGBP
        case this.iQuoteCalculationsDesignPackMixin.hardwareCurrencies.filter(x => x.description === 'EUR')[0].id:
          return hardwareMainEntry.unitCost * (1 / this.iQuoteStore_GetDesignPack.exchangeRateGBPToEUR)
      }
      hardwareMainEntry.unitCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces(hardwareMainEntry.unitCost)
      return -1 /* Ooops - This is an error */
    },
    iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitCostGBP  (hardwareMainEntry) {
      var val = this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryUnitCostGBP(hardwareMainEntry)

      if (val === -1 || hardwareMainEntry.quantity === 0) {
        return -1 /* Ooops - This is an error */
      } else {
        return val * hardwareMainEntry.quantity
      }
    },
    iQuoteCalculationsDesignPackMixin_calcHardwareMiscEntryTotalCost (hardwareMiscEntry) {
      if (hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId !== 0 && hardwareMiscEntry.quantity !== 0) {
        var unitCost = this.iQuoteCalculationsDesignPackMixin_getHardwareMiscEntryUnitCost(hardwareMiscEntry)
        return unitCost * hardwareMiscEntry.quantity
      } else {
        return -1 /* Can't be calculated yet */
      }
    },
    iQuoteCalculationsDesignPackMixin_calcHardwareMiscEntryTotalPrice (hardwareMiscEntry) {
      var unitCost = this.iQuoteCalculationsDesignPackMixin_getHardwareMiscEntryUnitCost(hardwareMiscEntry)

      if (unitCost !== -1) {
        return this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitCost / ((100 - hardwareMiscEntry.marginPercent) / 100)) * hardwareMiscEntry.quantity
      } else {
        return -1 /* Can't be calculated yet */
      }
    },
    iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitPriceGBP (hardwareMainEntry) {
      var unitCost = this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryUnitCostGBP(hardwareMainEntry)

      if (unitCost === -1 || hardwareMainEntry.quantity === 0) {
        return -1 /* Can't be calculated yet */
      } else {
        return this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitCost / ((100 - hardwareMainEntry.marginPercent) / 100)) * hardwareMainEntry.quantity
      }
    },
    iQuoteCalculationsDesignPackMixin_filterQuoteLicenseTypes (applicationLicenseTypes) {
      let licenseTypes = []
      applicationLicenseTypes.forEach((item) => {
        if (item.id === this.iQuoteConstants.quoteLicenceTypes.SUBSCRIPTION) {
          licenseTypes.push(item)
        }
        if (item.id === this.iQuoteConstants.quoteLicenceTypes.PERPETUAL && this.iQuoteCalculationsReviewScreenMixin_ShowPerpetualLicenseType) {
          licenseTypes.push(item)
        }
        if (item.id === this.iQuoteConstants.quoteLicenceTypes.SURRENDER && this.iQuoteCalculationsReviewScreenMixin_ShowSurrenderLicenseType) {
          licenseTypes.push(item)
        }
      })

      return licenseTypes
    },
    iQuoteCalculationsDesignPackMixin_filterQuoteServiceLevels (serviceLevels) {
      return serviceLevels.filter((item) => item.id >= 3) // exclude Standard/Premium (inactives)
    },
    iQuoteCalculationsDesignPackMixin_updatePriceOverride (entryDescription, dpEntryLookupSource, dpEntryLookupLookupId, objectToUpdate, fieldToUpdate, newPriceOverride) {
      if (objectToUpdate === undefined) {
        console.log('Error finding ' + dpEntryLookupSource + ' ' + entryDescription + ' entry with lookup id ' + dpEntryLookupLookupId)
        return
      }

      if (objectToUpdate[fieldToUpdate] !== newPriceOverride) {
        console.log('Updating source ' + dpEntryLookupSource + ' item ' + dpEntryLookupLookupId + ' from ' + objectToUpdate[fieldToUpdate] + ' to ' + newPriceOverride)
        objectToUpdate[fieldToUpdate] = newPriceOverride
      }
    },
    async iQuoteCalculationsDesignPackMixin_storePriceOverridesInDetailDataSources (detailData) {
      var designPack = this.iQuoteStore_GetDesignPack
      var dpEntryLookupSource = ''
      var dpEntryLookupPrimaryLookupId = ''
      var dpEntryLookupSecondaryLookupId = ''
      var lookupItem
      var newPriceOverride

      detailData.forEach(entry => {
        var itemIdParts = entry.itemId.split('.')

        // Item ids are of the format sourcename.itemId
        dpEntryLookupSource = itemIdParts[0]

        if (itemIdParts.length > 1) {
          dpEntryLookupPrimaryLookupId = Number(itemIdParts[1])
        }

        if (itemIdParts.length > 2) {
          dpEntryLookupSecondaryLookupId = Number(itemIdParts[2])
        }

        switch (dpEntryLookupSource) {
          case 'UnitCosts':
            lookupItem = this.iQuoteStore_GetQuoteUnitCostsRequired.find(x => x.unitCostId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.recurringCost ? entry.unitARPriceOverride : entry.unitOOPriceOverride
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
            break
          case 'Licenses':
            lookupItem = this.iQuoteStore_GetQuoteLicensesRequired.find(x => x.licenseId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.recurringCost ? entry.unitARPriceOverride : entry.unitOOPriceOverride
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
            break
          case 'HardwareMain':
            lookupItem = designPack.hardwareMainEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.unitOOPriceOverride
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
            break
          case 'HardwareMisc':
            lookupItem = designPack.hardwareMiscEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.unitOOPriceOverride
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
            break
          case 'CircuitsEquinix':
            // On circuits equinix, there's no quantity column.  But you can select the same circuit type multiple times.  So
            // update the price of each entry
            var lookupItems = designPack.circuitsEquinixCloudConnectEntries.filter(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.unitARPriceOverride
            if (lookupItems.length > 0) {
              lookupItems.forEach(equinixCloudConnectEntry => {
                this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, equinixCloudConnectEntry, 'priceOverride', newPriceOverride)
              })
            }
            break
          case 'CircuitsBroadband':
            lookupItem = designPack.circuitsBroadbandEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            if (entry.recurringCost) {
              newPriceOverride = entry.unitARPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverrideAR', newPriceOverride)
            } else {
              newPriceOverride = entry.unitOOPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverrideOO', newPriceOverride)
            }
            break
          case 'CircuitsFixedLineVoice':
            lookupItem = designPack.circuitsFixedLineVoiceEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            if (entry.recurringCost) {
              newPriceOverride = entry.unitARPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverrideAR', newPriceOverride)
            } else {
              newPriceOverride = entry.unitOOPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverrideOO', newPriceOverride)
            }
            break
          case 'CircuitsHostingAndCabling':
            lookupItem = designPack.circuitsHostingAndCablingEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            if (entry.recurringCost) {
              newPriceOverride = entry.unitARPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverrideAR', newPriceOverride)
            } else {
              newPriceOverride = entry.unitOOPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverrideOO', newPriceOverride)
            }
            break
          case 'CircuitsWanConnectivity':
            lookupItem = designPack.circuitsWanConnectivityEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            if (entry.recurringCost) {
              newPriceOverride = entry.unitARPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverrideAR', newPriceOverride)
            } else {
              newPriceOverride = entry.unitOOPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverrideOO', newPriceOverride)
            }
            break
          case 'PSCore':
            lookupItem = designPack.psCoreEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.unitOOPriceOverride
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
            break
          case 'PSOptions':
            lookupItem = designPack.psOptionsEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.unitOOPriceOverride
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
            break
          case 'PSExpenses':
            lookupItem = designPack.psExpenseEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.unitOOPriceOverride
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
            break
          case 'PSThirdPartyInstallation':
            lookupItem = designPack.psThirdPartyInstallationEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.unitOOPriceOverride
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
            break
          case 'PSThirdPartyOther':
            lookupItem = designPack.psThirdPartyOtherEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            newPriceOverride = entry.unitOOPriceOverride
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
            break
          case 'BOM':
            var bom = this.externalQuotesModelMixin_State.quoteBoms.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
            if (bom === undefined) {
              console.log('Error finding BOM with id ' + dpEntryLookupPrimaryLookupId)
            } else {
              lookupItem = bom.entries.find(x => x.designPackItemLookupId === dpEntryLookupSecondaryLookupId)
              if (lookupItem === undefined) {
                console.log('Error finding BOM Entry with designPackItemLookupId ' + dpEntryLookupSecondaryLookupId)
              } else {
                if (entry.costTypeId === this.iQuoteConstants.costTypes.internal) {
                  if (entry.recurringCost) {
                    newPriceOverride = entry.unitARPriceOverride
                    this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupSecondaryLookupId, lookupItem, 'priceOverrideARInternal', newPriceOverride)
                  } else {
                    newPriceOverride = entry.unitOOPriceOverride
                    this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupSecondaryLookupId, lookupItem, 'priceOverrideOOInternal', newPriceOverride)
                  }
                } else {
                  if (entry.recurringCost) {
                    newPriceOverride = entry.unitARPriceOverride
                    this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupSecondaryLookupId, lookupItem, 'priceOverrideARExternal', newPriceOverride)
                  } else {
                    newPriceOverride = entry.unitOOPriceOverride
                    this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupSecondaryLookupId, lookupItem, 'priceOverrideOOExternal', newPriceOverride)
                  }
                }
              }
            }
            break
          case 'ManagedServiceCostSummary':
            var managedService = this.externalQuotesModelMixin_State.quoteManagedService
            if (managedService.id === undefined) {
              console.log('Error finding Managed Service')
            } else {
              lookupItem = managedService.costSummaryEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
              if (lookupItem === undefined) {
                console.log('Error finding Managed Service Cost Summary Entry with designPackItemLookupId ' + dpEntryLookupPrimaryLookupId)
              } else {
                newPriceOverride = entry.recurringCost ? entry.unitARPriceOverride : entry.unitOOPriceOverride
                this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
              }
            }
            break
          case 'NewManagedServiceCostSummary':
            var newManagedService = this.externalQuotesModelMixin_State.quoteNewManagedService
            if (newManagedService.id === undefined) {
              console.log('Error finding New Managed Service')
            } else {
              lookupItem = newManagedService.costSummaryEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
              if (lookupItem === undefined) {
                console.log('Error finding New Managed Service Cost Summary Entry with designPackItemLookupId ' + dpEntryLookupPrimaryLookupId)
              } else {
                newPriceOverride = entry.recurringCost ? entry.unitARPriceOverride : entry.unitOOPriceOverride
                this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
              }
            }
            break
          case 'IconConnectCostSummary':
            var iconConnect = this.externalQuotesModelMixin_State.quoteIconConnect
            if (iconConnect.id === undefined) {
              console.log('Error finding Icon Connect')
            } else {
              lookupItem = iconConnect.costSummaryEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
              if (lookupItem === undefined) {
                console.log('Error finding Icon Connect Cost Summary Entry with designPackItemLookupId ' + dpEntryLookupPrimaryLookupId)
              } else {
                newPriceOverride = entry.recurringCost ? entry.unitARPriceOverride : entry.unitOOPriceOverride
                this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
              }
            }
            break
          case 'IconSecureCostSummary':
            var iconSecure = this.externalQuotesModelMixin_State.quoteIconSecure
            if (iconSecure.id === undefined) {
              console.log('Error finding Icon Secure')
            } else {
              lookupItem = iconSecure.costSummaryEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
              if (lookupItem === undefined) {
                console.log('Error finding Icon Secure Cost Summary Entry with designPackItemLookupId ' + dpEntryLookupPrimaryLookupId)
              } else {
                newPriceOverride = entry.recurringCost ? entry.unitARPriceOverride : entry.unitOOPriceOverride
                this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
              }
            }
            break
          case 'IconNowPriceSummary':
            var iconNow = this.externalQuotesModelMixin_State.quoteIconNow
            if (iconNow.id === undefined) {
              console.log('Error finding Icon Now')
            } else {
              lookupItem = iconNow.priceSummaryEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
              if (lookupItem === undefined) {
                console.log('Error finding Icon Now Price Summary Entry with designPackItemLookupId ' + dpEntryLookupPrimaryLookupId)
              } else {
                newPriceOverride = entry.recurringCost ? entry.unitARPriceOverride : entry.unitOOPriceOverride
                this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
              }
            }
            break
          case 'TwilioPriceSummary':
            var twilio = this.externalQuotesModelMixin_State.quoteTwilio
            if (twilio.id === undefined) {
              console.log('Error finding Twilio')
            } else {
              lookupItem = twilio.priceSummaryEntries.find(x => x.designPackItemLookupId === dpEntryLookupPrimaryLookupId)
              if (lookupItem === undefined) {
                console.log('Error finding Twilio Price Summary Entry with designPackItemLookupId ' + dpEntryLookupPrimaryLookupId)
              } else {
                newPriceOverride = entry.recurringCost ? entry.unitARPriceOverride : entry.unitOOPriceOverride
                this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverride', newPriceOverride)
              }
            }
            break
          case 'AddProjectCoordination':
            newPriceOverride = entry.unitOOPriceOverride
            lookupItem = designPack
            this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupPrimaryLookupId, lookupItem, 'priceOverrideOrderCoordination', newPriceOverride)
            break
          case 'FrameworkCharge':
            lookupItem = designPack
            if (entry.recurringCost) {
              newPriceOverride = entry.unitARPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupSecondaryLookupId, lookupItem, 'PriceOverrideARFrameworkCharge', newPriceOverride)
            } else {
              newPriceOverride = entry.unitOOPriceOverride
              this.iQuoteCalculationsDesignPackMixin_updatePriceOverride(entry.description, dpEntryLookupSource, dpEntryLookupSecondaryLookupId, lookupItem, 'PriceOverrideARFrameworkCharge', newPriceOverride)
            }
            break
        }
      })

      if (this.iQuoteStore_GetQuote.id !== undefined) {
        if (detailData.filter(x => x.itemId.startsWith('UnitCosts.')).length > 0) {
          await this.saveQuoteUnitCostsToDB()
        }

        if (detailData.filter(x => x.itemId.startsWith('Licenses.')).length > 0) {
          await this.saveQuoteLicensesToDB()
        }
      }

      if (detailData.filter(x => x.itemId.startsWith('BOM.'))) {
        for (let index = 0; index < this.externalQuotesModelMixin_State.quoteBoms.length; index++) {
          const bom = this.externalQuotesModelMixin_State.quoteBoms[index]
          await this.externalQuotesModelMixin_handleBomUpdated(bom)
        }
      }

      if (detailData.filter(x => x.itemId.startsWith('IconConnect')).length > 0) {
        await this.externalQuotesModelMixin_handleIconConnectUpdated(this.externalQuotesModelMixin_State.quoteIconConnect)
      }

      if (detailData.filter(x => x.itemId.startsWith('IconSecure')).length > 0) {
        await this.externalQuotesModelMixin_handleIconSecureUpdated(this.externalQuotesModelMixin_State.quoteIconSecure)
      }

      if (detailData.filter(x => x.itemId.startsWith('IconNow')).length > 0) {
        await this.externalQuotesModelMixin_handleIconNowUpdated(this.externalQuotesModelMixin_State.quoteIconNow)
      }

      if (detailData.filter(x => x.itemId.startsWith('ManagedService')).length > 0) {
        await this.externalQuotesModelMixin_handleManagedServiceUpdated(this.externalQuotesModelMixin_State.quoteManagedService)
      }

      if (detailData.filter(x => x.itemId.startsWith('NewManagedService')).length > 0) {
        await this.externalQuotesModelMixin_handleNewManagedServiceUpdated(this.externalQuotesModelMixin_State.quoteNewManagedService)
      }

      if (detailData.filter(x => x.itemId.startsWith('Twilio')).length > 0) {
        await this.externalQuotesModelMixin_handleTwilioUpdated(this.externalQuotesModelMixin_State.quoteTwilio)
      }

      if (detailData.filter(x => x.itemId.startsWith('HardwareMain.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('HardwareMisc.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('HardwareMisc.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('CircuitsEquinix.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('CircuitsBroadband.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('CircuitsFixedLineVoice.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('CircuitsHostingAndCabling.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('CircuitsWanConnectivity.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('PSCore.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('PSExpenses.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('PSOptions.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('PSThirdPartyInstallation.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('PSThirdPartyOther.')).length > 0 ||
          detailData.filter(x => x.itemId.startsWith('AddProjectCoordination.')).length > 0
      ) {
        // No need to save here, as the DP will be saved shortly afterwards when we recalc the actual priceoverrides based on the detail data
        // this.iQuoteCalculationsDesignPackMixin_updateAndSaveDesignPackDebounced(designPack)
      }
    },
    iQuoteCalculationsDesignPackMixin_setPriceOverrideFieldToMatchDetailedEntries (designPack, dpSectionDescription, dpSectionSalesforceCoeCodeId, priceOverrideField) {
      var priceOveridesFound = false
      var detailDataWithinDPSection = this.iQuoteStore_GetDesignPackDetailedDataEntries.filter(x => x.visibleToCustomer === true &&
       x.dpSectionDescription === dpSectionDescription &&
       x.dpSectionSalesforceCoeCodeId === dpSectionSalesforceCoeCodeId &&
       x.recurringCost === (priceOverrideField.includes('recurring')) &&
       ((priceOverrideField.includes('Internal') && x.costTypeId === this.iQuoteConstants.costTypes.internal) ||
        (priceOverrideField.includes('External') && x.costTypeId === this.iQuoteConstants.costTypes.external))
      )

      var priceOverrideEntry = designPack.priceOverrides.find(x => x.description === dpSectionDescription &&
          x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

      var totalAssignedToEntries = 0

      if (priceOverrideField.includes('recurring')) {
        detailDataWithinDPSection.forEach(detailDataEntry => {
          if (detailDataEntry.unitARPriceOverride !== null) {
            totalAssignedToEntries += this.iQuoteFunctions_RoundToTwoDecimalPlaces(detailDataEntry.unitARPriceOverride * Math.abs(detailDataEntry.quantity))
            priceOveridesFound = true
          } else if (priceOverrideField.includes('Internal')) {
            totalAssignedToEntries += this.iQuoteFunctions_RoundToTwoDecimalPlaces(detailDataEntry.unitARInternalPrice * Math.abs(detailDataEntry.quantity))
          } else {
            totalAssignedToEntries += this.iQuoteFunctions_RoundToTwoDecimalPlaces(detailDataEntry.unitARExternalPrice * Math.abs(detailDataEntry.quantity))
          }
        })
      } else {
        detailDataWithinDPSection.forEach(detailDataEntry => {
          if (detailDataEntry.unitOOPriceOverride !== null) {
            totalAssignedToEntries += this.iQuoteFunctions_RoundToTwoDecimalPlaces(detailDataEntry.unitOOPriceOverride * Math.abs(detailDataEntry.quantity))
            priceOveridesFound = true
          } else if (priceOverrideField.includes('Internal')) {
            totalAssignedToEntries += this.iQuoteFunctions_RoundToTwoDecimalPlaces(detailDataEntry.unitOOInternalPrice * Math.abs(detailDataEntry.quantity))
          } else {
            totalAssignedToEntries += this.iQuoteFunctions_RoundToTwoDecimalPlaces(detailDataEntry.unitOOExternalPrice * Math.abs(detailDataEntry.quantity))
          }
        })
      }

      if (!priceOveridesFound) {
        priceOverrideEntry[priceOverrideField] = null
      } else if (priceOverrideEntry[priceOverrideField] !== this.iQuoteFunctions_RoundToTwoDecimalPlaces(totalAssignedToEntries)) {
        console.log('Updating Price Override for ' + priceOverrideEntry.description + ' from ' + priceOverrideEntry[priceOverrideField] + ' to ' + this.iQuoteFunctions_RoundToTwoDecimalPlaces(totalAssignedToEntries))
        // update the price override to match the actual value of the associated entries
        priceOverrideEntry[priceOverrideField] = this.iQuoteFunctions_RoundToTwoDecimalPlaces(totalAssignedToEntries)
      }

      return designPack
    }
  }
}
