import { iQuoteConstants } from '../iQuoteConstants'
import { iQuoteCalculationsUtilFunctionsMixin } from '../iQuoteCalculations_UtilFunctions'
import { iQuoteStoreMixin } from '../iQuoteStoreMixin'
import { iQuoteFunctions } from '../iQuoteFunctions'
import { externalQuotesModelMixin } from '../../../mixins/iQuote/Models/externalQuotesModelMixin'

export const iQuoteCalculationsDesignPackDetailDataMixin = {
  mixins: [
    iQuoteConstants,
    iQuoteCalculationsUtilFunctionsMixin,
    iQuoteStoreMixin,
    iQuoteFunctions,
    externalQuotesModelMixin
  ],
  data () {
    return {
      iQuoteCalculationsDesignPackDetailDataMixin: {
      }
    }
  },
  computed: {
  },
  methods: {
    iQuoteCalculationsDesignPackDetailDataMixin_AllData () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack
      var designPackTableSections = this.iQuoteStore_GetDesignPackTableSections
      var entryNumber = 1

      if (this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length > 0) {
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_EditedDPEntries())
      } else if (this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId === this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP) {
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_ExternalDPEntries())
      } else {
        // iQuote Entries
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_UnitCosts())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_Licenses())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_AvayaATEntries())

        // Hardware Entries
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_HardwareMain())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_HardwareMisc())

        // Circuits Entries
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsBroadband())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsWanConnectivity())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsHostingAndCabling())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsFixedLineVoice())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsEquinixCloudConnect())

        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsContingencyEntry())

        // PS Entries
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_PSCore())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_PSOptions())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_PSExpenses())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_PSThirdPartyInstallation())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_PSThirdPartyOther())

        // Calculators
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_BOMs())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_IconConnect())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CallsAndLines())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_NewManagedService())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_ManagedService())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_Horizon())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_IconSecure())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_IconNow())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_Twilio())

        // Other
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_PCTime())
        entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_SupportContingencyEntry())
      }

      entries.forEach(entry => {
      // Set any detail entries to paused if the dp section they are in is paused.

        if (!entry.paused) {
          // If not already paused at the detail level, check if it's paused at the summary level
          entry.paused = designPack.disabledEntries.findIndex(x => x.source === entry.dpSource &&
                                                              x.description === entry.dpSectionDescription &&
                                                              x.salesforceCoeCodeId === entry.dpSectionSalesforceCoeCodeId
          ) !== -1
        }

        entry.id = 0 // No id, so this will be inserted into the DB rather than replacing existing row

        /*
          START - Set Default values for any fields that were not supplied
        */

        if (entry.itemId === undefined) {
          entry.itemId = ''
        }

        if (entry.unitOOPriceOverride === undefined) {
          entry.unitOOPriceOverride = null
        }

        if (entry.unitARPriceOverride === undefined) {
          entry.unitARPriceOverride = null
        }

        if (entry.unitARExternalRRP === undefined) {
          entry.unitARExternalRRP = null
        }

        if (entry.unitOOExternalRRP === undefined) {
          entry.unitOOExternalRRP = null
        }

        /*
          END - Set Default values for any fields that were not supplied
        */

        entryNumber++

        var quantityForTotalCalcs = entry.quantity

        if (entry.quantity < 0) {
          // a credited item should have a negative quantity and negative costs / prices.  So make sure that any item with a negative quantity has a negative cost and negative price

          entry.unitARInternalCost = -Math.abs(entry.unitARInternalCost)
          entry.unitARExternalCost = -Math.abs(entry.unitARExternalCost)
          entry.unitOOInternalCost = -Math.abs(entry.unitOOInternalCost)
          entry.unitOOExternalCost = -Math.abs(entry.unitOOExternalCost)

          entry.unitARInternalPrice = -Math.abs(entry.unitARInternalPrice)
          entry.unitARExternalPrice = -Math.abs(entry.unitARExternalPrice)
          entry.unitOOInternalPrice = -Math.abs(entry.unitOOInternalPrice)
          entry.unitOOExternalPrice = -Math.abs(entry.unitOOExternalPrice)

          // Two Negatives multiplied will equal a positive.  We need a negative total for credit items, so invert the quantity for calculating the totals
          quantityForTotalCalcs = -entry.quantity
        }

        entry.totalInternalAnnualPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations || entry.visibleToCustomer ? entry.unitARInternalPrice : 0) * quantityForTotalCalcs)
        entry.totalExternalAnnualPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations || entry.visibleToCustomer ? entry.unitARExternalPrice : 0) * quantityForTotalCalcs)
        entry.totalInternalUpfrontPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations || entry.visibleToCustomer ? entry.unitOOInternalPrice : 0) * quantityForTotalCalcs)
        entry.totalExternalUpfrontPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations || entry.visibleToCustomer ? entry.unitOOExternalPrice : 0) * quantityForTotalCalcs)

        // Cost shown on DP, Price shown on Quote
        entry.totalInternalAnnualCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations && entry.visibleToCustomer ? entry.unitARInternalCost : 0) * quantityForTotalCalcs)
        entry.totalExternalAnnualCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations && entry.visibleToCustomer ? entry.unitARExternalCost : 0) * quantityForTotalCalcs)
        entry.totalInternalUpfrontCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations && entry.visibleToCustomer ? entry.unitOOInternalCost : 0) * quantityForTotalCalcs)
        entry.totalExternalUpfrontCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations && entry.visibleToCustomer ? entry.unitOOExternalCost : 0) * quantityForTotalCalcs)

        // Cost shown on DP, No entry on Quote
        entry.totalInternalAnnualHiddenCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations && !entry.visibleToCustomer ? entry.unitARInternalCost : 0) * quantityForTotalCalcs)
        entry.totalExternalAnnualHiddenCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations && !entry.visibleToCustomer ? entry.unitARExternalCost : 0) * quantityForTotalCalcs)
        entry.totalInternalUpfrontHiddenCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations && !entry.visibleToCustomer ? entry.unitOOInternalCost : 0) * quantityForTotalCalcs)
        entry.totalExternalUpfrontHiddenCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((entry.includeInCalculations && !entry.visibleToCustomer ? entry.unitOOExternalCost : 0) * quantityForTotalCalcs)

        // No Cost on DP, Price shown on Quote (Typically the this is a summary line for quote, and individuals costs are shown on DP but not shown to customer)
        entry.totalInternalAnnualQuoteOnlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((!entry.includeInCalculations && entry.visibleToCustomer ? entry.unitARInternalCost : 0) * quantityForTotalCalcs)
        entry.totalExternalAnnualQuoteOnlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((!entry.includeInCalculations && entry.visibleToCustomer ? entry.unitARExternalCost : 0) * quantityForTotalCalcs)
        entry.totalInternalUpfrontQuoteOnlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((!entry.includeInCalculations && entry.visibleToCustomer ? entry.unitOOInternalCost : 0) * quantityForTotalCalcs)
        entry.totalExternalUpfrontQuoteOnlyCost = this.iQuoteFunctions_RoundToTwoDecimalPlaces((!entry.includeInCalculations && entry.visibleToCustomer ? entry.unitOOExternalCost : 0) * quantityForTotalCalcs)

        var dpSectionSortOrder
        var existingDPSectionInData = entries.map(x => ({ dpSectionSalesforceCoeCodeId: x.dpSectionSalesforceCoeCodeId, dpSectionDescription: x.dpSectionDescription, dpSectionSortOrder: x.dpSectionSortOrder })).find(x => x.salesforceCoeCodeId === entry.salesforceCoeCodeId && x.dpSectionDescription === entry.dpSectionDescription)
        var designPackTableSection = designPackTableSections.find(x => x.id === entry.designPackTableSectionId)

        // Commented out the below IF statement, as it would affect automation, but ordering rignt now is overwriting detail level sorting
        // if (entry.dpSectionSortOrder === undefined) {
        if (designPackTableSection !== undefined) {
          dpSectionSortOrder = designPackTableSection.sortOrder
        } else if (existingDPSectionInData !== undefined && existingDPSectionInData.dpSectionSortOrder !== undefined) {
          dpSectionSortOrder = existingDPSectionInData.dpSectionSortOrder
        } else {
          dpSectionSortOrder = 999 + entryNumber
        }

        entry.dpSectionSortOrder = dpSectionSortOrder
        // }
      })

      // Framework charges must be calcualted at the very end
      entries = entries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_FrameworkCharges(entries))

      return entries.sort((a, b) => (a.category + a.sortOrder > b.category + b.sortOrder) ? 1 : ((b.category + b.sortOrder > a.category + a.sortOrder) ? -1 : 0))
    },
    iQuoteCalculationsDesignPackDetailDataMixin_EditedDPEntries () {
      var entries = []

      if (this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.length > 0) {
        this.iQuoteStore_GetDesignPack.editedDetailedSummaryBreakdownEntries.forEach(entry => {
          var newEntry = {
            description: entry.description,
            quoteDescription: null,
            quantity: 1,
            quoteRef: '',
            supplier: '',
            code: entry.description,
            sellTermMonths: 0,
            costTermMonths: 0,
            recurringCost: entry.upfrontThirdPartyCost !== 0 || entry.recurringThirdPartyCost !== 0,
            categoryName: 'Manually Edited DP',
            dpSectionDescription: entry.description,
            dpSectionSalesforceCoeCodeId: entry.salesforceCoeCodeId,
            designPackTableSectionId: 0,
            unitARExternalCost: entry.recurringThirdPartyCost,
            unitARInternalCost: entry.recurringInternalCost,
            unitOOExternalCost: entry.upfrontThirdPartyCost,
            unitOOInternalCost: entry.upfrontInternalCost,
            unitARExternalPrice: entry.recurringSellPrice,
            unitARInternalPrice: 0,
            unitOOExternalPrice: entry.upfrontSellPrice,
            unitOOInternalPrice: 0,
            unitMRExternalCost: 0,
            unitMRInternalCost: 0,
            unitMRExternalPrice: 0,
            unitMRInternalPrice: 0,
            dpSource: 'Manually Edited DP',
            costTypeId: this.iQuoteConstants.costTypes.external,
            visibleToCustomer: false,
            showOnExportATCodesList: false,
            includeInCalculations: true,
            canBeDiscounted: false,
            priceOverrideAllowed: false,
            usesGlobalMargins: false,
            canBePaused: false,
            sortOrder: ''
          }

          entries.push(
            newEntry
          )
        })

        return entries
      }
    },
    iQuoteCalculationsDesignPackDetailDataMixin_ExternalDPEntries () {
      var entries = []

      if (this.iQuoteStore_GetDesignPack.quoteDesignPackTypeId === this.iQuoteConstants.QuoteDesignPackTypes.FROM_EXTERNAL_DP) {
        this.iQuoteStore_GetDesignPack.externalDPEntries.forEach(entry => {
          var newEntry = {
            description: entry.description,
            quoteDescription: null,
            quantity: 1,
            quoteRef: '',
            supplier: '',
            code: entry.description,
            sellTermMonths: 0,
            costTermMonths: 0,
            recurringCost: entry.upfrontThirdPartyCost !== 0 || entry.recurringThirdPartyCost !== 0,
            categoryName: 'External DP Template',
            dpSectionDescription: entry.description,
            dpSectionSalesforceCoeCodeId: entry.salesforceCoeCodeId,
            designPackTableSectionId: 0,
            unitARExternalCost: entry.recurringThirdPartyCost,
            unitARInternalCost: entry.recurringInternalCost,
            unitOOExternalCost: entry.upfrontThirdPartyCost,
            unitOOInternalCost: entry.upfrontInternalCost,
            unitARExternalPrice: entry.recurringSellPrice,
            unitARInternalPrice: 0,
            unitOOExternalPrice: entry.upfrontSellPrice,
            unitOOInternalPrice: 0,
            unitMRExternalCost: 0,
            unitMRInternalCost: 0,
            unitMRExternalPrice: 0,
            unitMRInternalPrice: 0,
            dpSource: 'External DP Template',
            costTypeId: this.iQuoteConstants.costTypes.external,
            visibleToCustomer: false,
            showOnExportATCodesList: false,
            includeInCalculations: true,
            canBeDiscounted: true,
            priceOverrideAllowed: false,
            usesGlobalMargins: false,
            canBePaused: true,
            sortOrder: ''
          }

          entries.push(
            newEntry
          )
        })
        return entries
      }
    },
    iQuoteCalculationsDesignPackDetailDataMixin_AvayaATEntries () {
      var entries = []
      var auraLicenses = this.iQuoteStore_GetQuoteLicensesRequired.filter(x => x.code.toLowerCase().includes('avaya aura'))

      if (auraLicenses.length !== 0) {
        var newEntry = {
          description: 'VIR-AVAIL-1001>5000',
          quoteDescription: null,
          quantity: auraLicenses.reduce((accumulator, licenseEntry) => accumulator + licenseEntry.quantity, 0),
          quoteRef: '',
          supplier: '',
          code: 'VIR-AVAIL-1001>5000',
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Maintel UC Private +',
          dpSectionDescription: null,
          dpSectionSalesforceCoeCodeId: null,
          designPackTableSectionId: 0,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: 0,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'iQuote',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: false,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: false,
          canBePaused: false,
          sortOrder: '85-UnitCosts-9999999999'
        }

        entries.push(
          newEntry
        )
      }

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_UnitCosts () {
      var entries = []
      var quoteUnitCosts = this.iQuoteStore_GetQuoteUnitCostsRequired
      var quote = this.iQuoteStore_GetQuote
      var designPackTableSections = this.iQuoteStore_GetDesignPackTableSections
      var designPack = this.iQuoteStore_GetDesignPack
      var services = this.iQuoteStore_GetServices
      var designPackTableSection
      var service

      quoteUnitCosts.forEach(unitCost => {
        designPackTableSection = designPackTableSections.find(x => x.id === unitCost.designPackTableSectionId)
        service = services.find(x => x.id === unitCost.serviceId)
        var dpSectionMargins
        var dpSectionDiscounts
        var dpSectionDescription = null
        var dpSectionSalesforceCoeCodeId = null
        var designPackTableSectionId = 0

        if (designPackTableSection !== undefined) {
          if (!quote.chargeUpfront && !unitCost.recurringCost) {
            if (designPackTableSection.id === this.iQuoteConstants.designPackTableSections.ICON_INFRASTRUCTURE_NS_UPFRONT) {
              // Need to amortise this unitcost and move to recurring table section
              unitCost.designPackTableSectionId = this.iQuoteConstants.designPackTableSections.ICON_INFRASTRUCTURE
              unitCost.cost = unitCost.cost / (quote.termLengthMonths / 12)
              unitCost.recurringCost = true
              unitCost.paymentsPerYear = 1
              designPackTableSection = designPackTableSections.find(x => x.id === unitCost.designPackTableSectionId)
            }
          }

          dpSectionDescription = designPackTableSection.description
          dpSectionSalesforceCoeCodeId = designPackTableSection.salesforceCoeCodeId
          designPackTableSectionId = designPackTableSection.id
        }

        dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)
        dpSectionDiscounts = designPack.discounts.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        if (dpSectionMargins === undefined && designPackTableSection !== undefined && !designPackTableSection.precalculatedPricing) {
          dpSectionMargins = []
          dpSectionMargins.push({
            id: this.iQuoteCalculationsDesignPackDetailDataMixin_getTempMarginId(),
            inUse: true,
            description: designPackTableSection.description,
            salesforceCoeCodeId: designPackTableSection.salesforceCoeCodeId,
            upfrontExternalMargin: designPackTableSection.upfrontExternalDefaultMargin,
            upfrontInternalMargin: designPackTableSection.upfrontInternalDefaultMargin,
            recurringExternalMargin: designPackTableSection.recurringExternalDefaultMargin,
            recurringInternalMargin: designPackTableSection.recurringInternalDefaultMargin,
            upfrontExternalIquoteUserMinMargin: designPackTableSection.upfrontExternalIquoteUserMinMargin,
            upfrontInternalIquoteUserMinMargin: designPackTableSection.upfrontInternalIquoteUserMinMargin,
            recurringExternalIquoteUserMinMargin: designPackTableSection.recurringExternalIquoteUserMinMargin,
            recurringInternalIquoteUserMinMargin: designPackTableSection.recurringInternalIquoteUserMinMargin
          })
        }

        if (dpSectionDiscounts === undefined && designPackTableSection !== undefined && designPackTableSection.canBeDiscounted) {
          dpSectionDiscounts = []
          dpSectionDiscounts.push({
            id: this.iQuoteCalculationsDesignPackDetailDataMixin_getTempDiscountId(),
            inUse: true,
            description: designPackTableSection.description,
            salesforceCoeCodeId: designPackTableSection.salesforceCoeCodeId,
            upfrontExternalDiscount: designPackTableSection.upfrontExternalDefaultDiscount,
            upfrontInternalDiscount: designPackTableSection.upfrontInternalDefaultDiscount,
            recurringExternalDiscount: designPackTableSection.recurringExternalDefaultDiscount,
            recurringInternalDiscount: designPackTableSection.recurringInternalDefaultDiscount
          })
        }

        var entry = {
          description: unitCost.description,
          quoteDescription: unitCost.quoteDescription,
          quantity: unitCost.quantity,
          quoteRef: '',
          supplier: '',
          code: this.iQuoteStore_GetUnitCosts.find(x => x.id === unitCost.unitCostId).sku,
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: unitCost.recurringCost,
          categoryName: service.name,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: 0,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'iQuote',
          costTypeId: unitCost.costTypeId,
          visibleToCustomer: unitCost.visibleToCustomer,
          showOnExportATCodesList: unitCost.showOnExportATCodesList,
          includeInCalculations: unitCost.includeInCalculations,
          canBeDiscounted: designPackTableSection === undefined ? false : designPackTableSection.canBeDiscounted,
          priceOverrideAllowed: true,
          usesGlobalMargins: designPackTableSection === undefined ? false : !designPackTableSection.precalculatedPricing,
          canBePaused: true,
          sortOrder: '85-UnitCosts-' + unitCost.id.toString().padStart(10, '0'),
          itemId: 'UnitCosts.' + unitCost.unitCostId.toString(),
          unitOOPriceOverride: unitCost.recurringCost ? null : unitCost.priceOverride,
          unitARPriceOverride: !unitCost.recurringCost ? null : unitCost.priceOverride
        }

        if (unitCost.visibleToCustomer) {
          // Unit costs that are shown on quote, need to work out the prices

          if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
            if (unitCost.recurringCost) {
              entry.unitARInternalPrice = this.calculateUnitCostPrice(unitCost, designPackTableSection, dpSectionMargins, dpSectionDiscounts)
            } else {
              entry.unitOOInternalPrice = this.calculateUnitCostPrice(unitCost, designPackTableSection, dpSectionMargins, dpSectionDiscounts)
            }
          } else {
            if (unitCost.recurringCost) {
              entry.unitARExternalPrice = this.calculateUnitCostPrice(unitCost, designPackTableSection, dpSectionMargins, dpSectionDiscounts)
            } else {
              entry.unitOOExternalPrice = this.calculateUnitCostPrice(unitCost, designPackTableSection, dpSectionMargins, dpSectionDiscounts)
            }
          }
        }

        if (unitCost.paymentsPerYear === -1) {
          unitCost.paymentsPerYear = 12 / designPack.termLengthMonths
        }

        if (unitCost.recurringCost) {
          if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
            entry.unitARInternalCost = unitCost.cost * unitCost.paymentsPerYear
          } else {
            entry.unitARExternalCost = unitCost.cost * unitCost.paymentsPerYear
          }
        } else {
          if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
            entry.unitOOInternalCost = unitCost.cost
          } else {
            entry.unitOOExternalCost = unitCost.cost
          }
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackMixin_getPsScheduleQuoteDescription (psEntry) {
      var quoteDescription = psEntry.task

      // Append the schedule to the task description for quotes
      switch (psEntry.designPackPsScheduleId) {
        case this.iQuoteConstants.designPackPsSchedules.HALF_DAY:
          quoteDescription += ' (Half Day)'
          break
        case this.iQuoteConstants.designPackPsSchedules.SATURDAY_HALF_DAY:
          quoteDescription += ' (Saturday Half Day)'
          break
        case this.iQuoteConstants.designPackPsSchedules.SUNDAY_HALF_DAY:
          quoteDescription += ' (Sunday/Bank Holiday Half Day)'
          break
        case this.iQuoteConstants.designPackPsSchedules.DAY:
          quoteDescription += ' (Full Day)'
          break
        case this.iQuoteConstants.designPackPsSchedules.SATURDAY:
          quoteDescription += ' (Saturday Full Day)'
          break
        case this.iQuoteConstants.designPackPsSchedules.SUNDAY_OR_BANK_HOL:
          quoteDescription += ' (Sunday/Bank Holiday Full Day)'
          break
        case this.iQuoteConstants.designPackPsSchedules.EVENING_4HRS:
          quoteDescription += ' (Evening 4hrs)'
          break
      }

      return quoteDescription
    },
    iQuoteCalculationsDesignPackDetailDataMixin_PSCore () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription = 'Professional Services (Tab) - Internal'
      var dpSectionSalesforceCoeCodeId = 14 /* Professional Services */
      var designPackTableSectionId = this.iQuoteConstants.designPackTableSections.PROFESSIONAL_SERVICES

      var dpSectionDiscounts = designPack.discounts.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

      if (dpSectionDiscounts === undefined) {
        dpSectionDiscounts = { upfrontInternalDiscount: 0 }
      }

      designPack.psCoreEntries.filter(x => !x.disabled && x.units !== 0 && x.designPackPsScheduleId !== this.iQuoteConstants.designPackPsSchedules.NONE).forEach(psCoreEntry => {
        var cost = this.iQuoteStore_getPsProfileScheduleCost(psCoreEntry.designPackPsRoleProfileId, psCoreEntry.designPackPsScheduleId)

        var entry = {
          description: this.iQuoteCalculationsDesignPackMixin_getPsScheduleQuoteDescription(psCoreEntry),
          quoteDescription: this.iQuoteCalculationsDesignPackMixin_getPsScheduleQuoteDescription(psCoreEntry),
          quantity: psCoreEntry.units,
          quoteRef: '',
          supplier: '',
          code: this.iQuoteStore_getPsProfileScheduleCode(psCoreEntry.designPackPsRoleProfileId, psCoreEntry.designPackPsScheduleId),
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Professional Services',
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: 0,
          unitOOInternalCost: cost,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(cost * 2 * ((100 - dpSectionDiscounts.upfrontInternalDiscount) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Tabs',
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: true,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: true,
          priceOverrideAllowed: true,
          usesGlobalMargins: false,
          canBePaused: false,
          sortOrder: '01-PS Core-' + psCoreEntry.id.toString().padStart(10, '0'),
          itemId: 'PSCore.' + psCoreEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: psCoreEntry.priceOverride,
          unitARPriceOverride: null
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_BOMs () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSalesforceCoeCodeId
      var designPackTableSectionId = 0
      var bomDescription
      var bomNumber = 0

      var unitOOExternalCost
      var unitARExternalCost
      var unitOOExternalPrice
      var unitARExternalPrice
      var unitOOInternalCost
      var unitARInternalCost
      var unitOOInternalPrice
      var unitARInternalPrice

      this.externalQuotesModelMixin_State.quoteBoms.forEach(bom => {
        bomNumber++
        
        if (bom.description === '') {
          bomDescription = 'BOM ' + bomNumber
        } else {
          bomDescription = bom.description
        }
        
        bom.entries.forEach(bomEntry => {
          dpSectionDescription = bomEntry.description
          dpSectionSalesforceCoeCodeId = bomEntry.salesforceCoeCodeId
          var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)
          
          if (dpSectionMargins === undefined) {
            dpSectionMargins = {
              upfrontInternalMargin: 20,
              upfrontExternalMargin: 20,
              recurringInternalMargin: 20,
              recurringExternalMargin: 20
            }
          }

          // ticket 10364 - 'NS Upfront' COE in BOMs (which maps to the Business COE of 'Tech') should have the default margin of 40%
          if (dpSectionSalesforceCoeCodeId === this.iQuoteConstants.salesforceCoeCodes.NS_UPFRONT) {
            dpSectionMargins.upfrontInternalMargin = this.iQuoteConstants.salesMargins.TECHNOLOGY_DEFAULT_MARGIN
            dpSectionMargins.upfrontExternalMargin = this.iQuoteConstants.salesMargins.TECHNOLOGY_DEFAULT_MARGIN
            dpSectionMargins.recurringInternalMargin = null
            dpSectionMargins.recurringExternalMargin = null
          }

          // For each entry we want to add a line for the upfront portion, and a line for the recurring portion
          // The first loop round (upfrontOrRecurringIndex=0) will be upfront, the second loop round (upfrontOrRecurringIndex=1) will be recurring
          for (let upfrontOrRecurringIndex = 0; upfrontOrRecurringIndex <= 1; upfrontOrRecurringIndex++) {
            var isRecurring = (upfrontOrRecurringIndex === 1)

            // For each entry we want to add a line for the internal portion, and a line for the external portion
            // The first loop round (internalOrExternalIndex=0) will be internal, the second loop round (internalOrExternalIndex=1) will be external
            for (let internalOrExternalIndex = 0; internalOrExternalIndex <= 1; internalOrExternalIndex++) {
              var isInternal = (internalOrExternalIndex === 0)

              unitOOExternalCost = 0
              unitOOInternalCost = 0
              unitOOInternalPrice = 0
              unitOOExternalPrice = 0
              unitARExternalCost = 0
              unitARInternalCost = 0
              unitARExternalPrice = 0
              unitARInternalPrice = 0

              if (isInternal) {
                if (!isRecurring) {
                  // Upfront Internal Entry
                  unitOOInternalCost = bomEntry.upfrontInternalCost
                  unitOOInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(bomEntry.upfrontInternalCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100))
                } else {
                  // Recurring internal Entry
                  unitARInternalCost = bomEntry.recurringInternalCost
                  unitARInternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(bomEntry.recurringInternalCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100))
                }
              } else {
                if (!isRecurring) {
                  // Upfront external Entry
                  unitOOExternalCost = bomEntry.upfrontExternalCost
                  unitOOExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(bomEntry.upfrontExternalCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100))
                } else {
                  // Recurring external Entry
                  unitARExternalCost = bomEntry.recurringExternalCost
                  unitARExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(bomEntry.recurringExternalCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100))
                }
              }

              if (unitOOExternalCost !== 0 || unitOOInternalCost !== 0 ||
                  unitARExternalCost !== 0 || unitARInternalCost !== 0) {
                var subItemId

                if (isRecurring) {
                  if (isInternal) {
                    subItemId = 1
                  } else {
                    subItemId = 2
                  }
                } else {
                  if (isInternal) {
                    subItemId = 3
                  } else {
                    subItemId = 4
                  }
                }

                var entry = {
                  description: bomEntry.description !== '' ? bomEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(bomEntry.salesforceCoeCodeId),
                  quoteDescription: null,
                  quantity: 1,
                  quoteRef: '',
                  supplier: '',
                  code: 'iQuote BOM',
                  sellTermMonths: designPack.termLengthMonths,
                  costTermMonths: designPack.termLengthMonths,
                  recurringCost: isRecurring,
                  dpSectionDescription: dpSectionDescription,
                  dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
                  dpSectionSortOrder: 0,
                  designPackTableSectionId: designPackTableSectionId,
                  unitARExternalCost: unitARExternalCost,
                  unitARInternalCost: unitARInternalCost,
                  unitOOExternalCost: unitOOExternalCost,
                  unitOOInternalCost: unitOOInternalCost,
                  unitARExternalPrice: unitARExternalPrice,
                  unitARInternalPrice: unitARInternalPrice,
                  unitOOExternalPrice: unitOOExternalPrice,
                  unitOOInternalPrice: unitOOInternalPrice,
                  unitMRExternalCost: 0,
                  unitMRInternalCost: 0,
                  unitMRExternalPrice: 0,
                  unitMRInternalPrice: 0,
                  dpSource: 'External Quotes - BOM (' + bomDescription + ')',
                  categoryName: bomDescription,
                  costTypeId: isInternal ? this.iQuoteConstants.costTypes.internal : this.iQuoteConstants.costTypes.external,
                  visibleToCustomer: true,
                  showOnExportATCodesList: true,
                  includeInCalculations: true,
                  canBeDiscounted: false,
                  priceOverrideAllowed: true,
                  usesGlobalMargins: true,
                  canBePaused: true,
                  sortOrder: '10-BOM-' + bomEntry.id.toString().padStart(10, '0'),
                  itemId: 'BOM.' + bom.designPackItemLookupId.toString() + '.' + bomEntry.designPackItemLookupId.toString() + '.' + subItemId,
                  unitOOPriceOverride: !isRecurring ? (isInternal ? bomEntry.priceOverrideOOInternal : bomEntry.priceOverrideOOExternal) : null,
                  unitARPriceOverride: isRecurring ? (isInternal ? bomEntry.priceOverrideARInternal : bomEntry.priceOverrideARExternal) : null
                }

                if (bomEntry.recurringExternalCost < 0 || bomEntry.recurringInternalCost < 0 || bomEntry.upfrontExternalCost < 0 || bomEntry.upfrontInternalCost < 0) {
                  entry.quantity = -1
                }

                entries.push(entry)
              }
            }
          }
        })
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_IconConnect () {
      if (this.externalQuotesModelMixin_State.quoteIconConnect.id === undefined) {
        return []
      }

      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSalesforceCoeCodeId
      var designPackTableSectionId = 0
      var dpSource = 'Icon Connect'
      var iconConnect = this.externalQuotesModelMixin_State.quoteIconConnect

      if (iconConnect.description !== '') {
        dpSource += ' (' + iconConnect.description + ')'
      }

      var costSummaryEntries = iconConnect.costSummaryEntries.filter(x => x.recurringExternalCost + x.recurringInternalCost + x.upfrontExternalCost + x.upfrontInternalCost > 0)

      costSummaryEntries.forEach(iconConnectEntry => {
        dpSectionDescription = iconConnectEntry.description
        dpSectionSalesforceCoeCodeId = iconConnectEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        var isRecurringCost = iconConnectEntry.recurringExternalCost + iconConnectEntry.recurringInternalCost !== 0

        if (dpSectionMargins === undefined) {
          dpSectionMargins = {
            upfrontInternalMargin: 20,
            upfrontExternalMargin: 20,
            recurringInternalMargin: 20,
            recurringExternalMargin: 20
          }
        }
        var entry = {
          description: iconConnectEntry.description !== '' ? iconConnectEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(iconConnectEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote Icon Connect',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: isRecurringCost,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: iconConnectEntry.recurringExternalCost,
          unitARInternalCost: iconConnectEntry.recurringInternalCost,
          unitOOExternalCost: iconConnectEntry.upfrontExternalCost,
          unitOOInternalCost: iconConnectEntry.upfrontInternalCost,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconConnectEntry.recurringExternalCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100)),
          unitARInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconConnectEntry.recurringInternalCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100)),
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconConnectEntry.upfrontExternalCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconConnectEntry.upfrontInternalCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: (iconConnectEntry.recurringInternalCost !== 0 || iconConnectEntry.upfrontInternalCost !== 0) ? this.iQuoteConstants.costTypes.internal : this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '20-Icon Connect-' + iconConnectEntry.id.toString().padStart(10, '0'),
          itemId: 'IconConnectCostSummary.' + iconConnectEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: (!isRecurringCost) ? iconConnectEntry.priceOverride : null,
          unitARPriceOverride: (isRecurringCost) ? iconConnectEntry.priceOverride : null
        }

        if (iconConnectEntry.recurringExternalCost < 0 || iconConnectEntry.recurringInternalCost < 0 || iconConnectEntry.upfrontExternalCost < 0 || iconConnectEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_CallsAndLines () {
      if (this.externalQuotesModelMixin_State.quoteCallsAndLines.id === undefined) {
        return []
      }

      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSalesforceCoeCodeId
      var designPackTableSectionId = 0
      var dpSource = 'Calls and Lines'
      var callsAndLines = this.externalQuotesModelMixin_State.quoteCallsAndLines

      if (callsAndLines.description !== '') {
        dpSource += ' (' + callsAndLines.description + ')'
      }

      var costSummaryEntries = callsAndLines.costSummaryEntries.filter(x => x.recurringExternalCost + x.recurringInternalCost + x.upfrontExternalCost + x.upfrontInternalCost > 0)

      costSummaryEntries.forEach(callsAndLinesEntry => {
        dpSectionDescription = callsAndLinesEntry.description
        dpSectionSalesforceCoeCodeId = callsAndLinesEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        if (dpSectionMargins === undefined) {
          dpSectionMargins = {
            upfrontInternalMargin: 20,
            upfrontExternalMargin: 20,
            recurringInternalMargin: 20,
            recurringExternalMargin: 20
          }
        }
        var entry = {
          description: callsAndLinesEntry.description !== '' ? callsAndLinesEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(callsAndLinesEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote Calls and Lines',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: callsAndLinesEntry.recurringExternalCost + callsAndLinesEntry.recurringInternalCost !== 0,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: callsAndLinesEntry.recurringExternalCost,
          unitARInternalCost: callsAndLinesEntry.recurringInternalCost,
          unitOOExternalCost: callsAndLinesEntry.upfrontExternalCost,
          unitOOInternalCost: callsAndLinesEntry.upfrontInternalCost,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(callsAndLinesEntry.recurringExternalCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100)),
          unitARInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(callsAndLinesEntry.recurringInternalCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100)),
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(callsAndLinesEntry.upfrontExternalCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(callsAndLinesEntry.upfrontInternalCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: true,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '61-Calls and Lines Cost Summary-' + callsAndLinesEntry.id.toString().padStart(10, '0')
        }

        if (callsAndLinesEntry.recurringExternalCost < 0 || callsAndLinesEntry.recurringInternalCost < 0 || callsAndLinesEntry.upfrontExternalCost < 0 || callsAndLinesEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      callsAndLines.autotaskEntries.forEach(callsAndLinesEntry => {
        dpSectionDescription = callsAndLinesEntry.description
        dpSectionSalesforceCoeCodeId = callsAndLinesEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        // Most autotask entries are not shown to the customer, as we use the cost summary entry table.  But some entries have 0 cost, and so we can't use a margin for those.  If there's no
        // cost, but there is a price then we will take the price from the spreadsheet autotask table and allow it to be discounted
        var visibleToCustomer = (callsAndLinesEntry.unitARCost === 0 && callsAndLinesEntry.unitARPrice !== 0) || (callsAndLinesEntry.unitOOCost === 0 && callsAndLinesEntry.unitOOPrice !== 0)

        if (dpSectionMargins === undefined) {
          dpSectionMargins = {
            upfrontInternalMargin: 20,
            upfrontExternalMargin: 20,
            recurringInternalMargin: 20,
            recurringExternalMargin: 20
          }
        }
        var entry = {
          description: callsAndLinesEntry.description,
          quoteDescription: null,
          quantity: callsAndLinesEntry.quantity,
          quoteRef: callsAndLinesEntry.quoteRef,
          supplier: callsAndLinesEntry.supplier,
          code: callsAndLinesEntry.code,
          sellTermMonths: callsAndLinesEntry.sellTermMonths,
          costTermMonths: callsAndLinesEntry.costTermMonths,
          recurringCost: callsAndLinesEntry.unitARCost !== 0 || callsAndLinesEntry.unitARPrice !== 0,
          dpSectionDescription: visibleToCustomer ? callsAndLinesEntry.description : callsAndLinesEntry.salesforceDescription,
          dpSectionSalesforceCoeCodeId: callsAndLinesEntry.salesforceCoeCodeId,
          designPackTableSectionId: 0,
          unitARExternalCost: callsAndLinesEntry.unitARCost,
          unitARInternalCost: 0,
          unitOOExternalCost: callsAndLinesEntry.unitOOCost,
          unitOOInternalCost: 0,
          unitARExternalPrice: callsAndLinesEntry.unitARPrice,
          unitARInternalPrice: 0,
          unitOOExternalPrice: callsAndLinesEntry.unitOOPrice,
          unitOOInternalPrice: 0,
          unitMRExternalCost: callsAndLinesEntry.unitMRCost,
          unitMRInternalCost: 0,
          unitMRExternalPrice: callsAndLinesEntry.unitMRPrice,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: visibleToCustomer,
          includeInCalculations: visibleToCustomer,
          showOnExportATCodesList: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '60-Calls and Lines Autotask-' + callsAndLinesEntry.id.toString().padStart(10, '0')
        }

        if (callsAndLinesEntry.recurringExternalCost < 0 || callsAndLinesEntry.recurringInternalCost < 0 || callsAndLinesEntry.upfrontExternalCost < 0 || callsAndLinesEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_NewManagedService () {
      if (this.externalQuotesModelMixin_State.quoteNewManagedService.id === undefined) {
        return []
      }

      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSalesforceCoeCodeId
      var designPackTableSectionId = 0
      var dpSource = 'New Managed Service'
      var newManagedService = this.externalQuotesModelMixin_State.quoteNewManagedService

      if (newManagedService.description !== '') {
        dpSource += ' (' + newManagedService.description + ')'
      }

      var costSummaryEntries = newManagedService.costSummaryEntries.filter(x => x.recurringExternalCost + x.recurringInternalCost + x.upfrontExternalCost + x.upfrontInternalCost > 0)

      costSummaryEntries.forEach(newManagedServiceEntry => {
        dpSectionDescription = newManagedServiceEntry.description
        dpSectionSalesforceCoeCodeId = newManagedServiceEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        var isRecurringCost = newManagedServiceEntry.recurringExternalCost + newManagedServiceEntry.recurringInternalCost !== 0

        if (dpSectionMargins === undefined) {
          dpSectionMargins = {
            upfrontInternalMargin: 20,
            upfrontExternalMargin: 20,
            recurringInternalMargin: 20,
            recurringExternalMargin: 20
          }
        }
        var entry = {
          description: newManagedServiceEntry.description !== '' ? newManagedServiceEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(newManagedServiceEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote New Managed Service',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: isRecurringCost,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: newManagedServiceEntry.recurringExternalCost,
          unitARInternalCost: newManagedServiceEntry.recurringInternalCost,
          unitOOExternalCost: newManagedServiceEntry.upfrontExternalCost,
          unitOOInternalCost: newManagedServiceEntry.upfrontInternalCost,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(newManagedServiceEntry.recurringExternalCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100)),
          unitARInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(newManagedServiceEntry.recurringInternalCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100)),
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(newManagedServiceEntry.upfrontExternalCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(newManagedServiceEntry.upfrontInternalCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: (newManagedServiceEntry.recurringInternalCost !== 0 || newManagedServiceEntry.upfrontInternalCost !== 0) ? this.iQuoteConstants.costTypes.internal : this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '56-New Managed Service Cost Summary-' + newManagedServiceEntry.id.toString().padStart(10, '0'),
          itemId: 'NewManagedServiceCostSummary.' + newManagedServiceEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: (!isRecurringCost) ? newManagedServiceEntry.priceOverride : null,
          unitARPriceOverride: (isRecurringCost) ? newManagedServiceEntry.priceOverride : null
        }

        if (newManagedServiceEntry.recurringExternalCost < 0 || newManagedServiceEntry.recurringInternalCost < 0 || newManagedServiceEntry.upfrontExternalCost < 0 || newManagedServiceEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      newManagedService.autotaskEntries.forEach(newManagedServiceEntry => {
        dpSectionDescription = newManagedServiceEntry.salesforceDescription
        dpSectionSalesforceCoeCodeId = newManagedServiceEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        if (dpSectionMargins === undefined) {
          dpSectionMargins = {
            upfrontInternalMargin: 20,
            upfrontExternalMargin: 20,
            recurringInternalMargin: 20,
            recurringExternalMargin: 20
          }
        }
        var entry = {
          description: newManagedServiceEntry.code,
          quoteDescription: null,
          quantity: newManagedServiceEntry.quantity,
          quoteRef: '',
          supplier: '',
          code: newManagedServiceEntry.code,
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: newManagedServiceEntry.unitARCost !== 0 || newManagedServiceEntry.unitARPrice !== 0,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: newManagedServiceEntry.salesforceCoeCodeId,
          designPackTableSectionId: 0,
          unitARExternalCost: dpSectionDescription === 'Support Pricing - Internal' ? 0 : newManagedServiceEntry.unitARCost,
          unitARInternalCost: dpSectionDescription !== 'Support Pricing - Internal' ? 0 : newManagedServiceEntry.unitARCost,
          unitOOExternalCost: dpSectionDescription === 'Support Pricing - Internal' ? 0 : newManagedServiceEntry.unitOOCost,
          unitOOInternalCost: dpSectionDescription !== 'Support Pricing - Internal' ? 0 : newManagedServiceEntry.unitOOCost,
          unitARExternalPrice: dpSectionDescription === 'Support Pricing - Internal' ? 0 : this.iQuoteFunctions_RoundToTwoDecimalPlaces(newManagedServiceEntry.unitARCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100)),
          unitARInternalPrice: dpSectionDescription !== 'Support Pricing - Internal' ? 0 : this.iQuoteFunctions_RoundToTwoDecimalPlaces(newManagedServiceEntry.unitARCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100)),
          unitOOExternalPrice: dpSectionDescription === 'Support Pricing - Internal' ? 0 : this.iQuoteFunctions_RoundToTwoDecimalPlaces(newManagedServiceEntry.unitOOCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: dpSectionDescription !== 'Support Pricing - Internal' ? 0 : this.iQuoteFunctions_RoundToTwoDecimalPlaces(newManagedServiceEntry.unitOOCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100)),
          unitMRExternalCost: dpSectionDescription === 'Support Pricing - Internal' ? 0 : newManagedServiceEntry.unitMRCost,
          unitMRInternalCost: dpSectionDescription !== 'Support Pricing - Internal' ? 0 : newManagedServiceEntry.unitMRCost,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: dpSectionDescription === 'Support Pricing - Internal' ? this.iQuoteConstants.costTypes.internal : this.iQuoteConstants.costTypes.external,
          visibleToCustomer: false,
          includeInCalculations: false,
          showOnExportATCodesList: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '55-New Managed Service Autotask-' + newManagedServiceEntry.id.toString().padStart(10, '0')
        }

        if (newManagedServiceEntry.unitMRCost === 0) {
          entry.unitMRExternalPrice = 0
          entry.unitMRInternalPrice = 0
        } else {
          entry.unitMRExternalPrice = entry.unitARExternalPrice / 12
          entry.unitMRInternalPrice = entry.unitARInternalPrice / 12
        }

        if (newManagedServiceEntry.recurringExternalCost < 0 || newManagedServiceEntry.recurringInternalCost < 0 || newManagedServiceEntry.upfrontExternalCost < 0 || newManagedServiceEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_ManagedService () {
      if (this.externalQuotesModelMixin_State.quoteManagedService.id === undefined) {
        return []
      }

      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSalesforceCoeCodeId
      var designPackTableSectionId = 0
      var dpSource = 'Managed Service'
      var ManagedService = this.externalQuotesModelMixin_State.quoteManagedService

      if (ManagedService.description !== '') {
        dpSource += ' (' + ManagedService.description + ')'
      }

      var costSummaryEntries = ManagedService.costSummaryEntries.filter(x => x.recurringExternalCost + x.recurringInternalCost + x.upfrontExternalCost + x.upfrontInternalCost > 0)

      costSummaryEntries.forEach(managedServiceEntry => {
        dpSectionDescription = managedServiceEntry.description
        dpSectionSalesforceCoeCodeId = managedServiceEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        var isRecurringCost = managedServiceEntry.recurringExternalCost + managedServiceEntry.recurringInternalCost !== 0

        if (dpSectionMargins === undefined) {
          dpSectionMargins = {
            upfrontInternalMargin: 20,
            upfrontExternalMargin: 20,
            recurringInternalMargin: 20,
            recurringExternalMargin: 20
          }
        }
        var entry = {
          description: managedServiceEntry.description !== '' ? managedServiceEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(managedServiceEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote Managed Service',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: managedServiceEntry.recurringExternalCost + managedServiceEntry.recurringInternalCost !== 0,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: managedServiceEntry.recurringExternalCost,
          unitARInternalCost: managedServiceEntry.recurringInternalCost,
          unitOOExternalCost: managedServiceEntry.upfrontExternalCost,
          unitOOInternalCost: managedServiceEntry.upfrontInternalCost,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(managedServiceEntry.recurringExternalCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100)),
          unitARInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(managedServiceEntry.recurringInternalCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100)),
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(managedServiceEntry.upfrontExternalCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(managedServiceEntry.upfrontInternalCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: (managedServiceEntry.recurringInternalCost !== 0 || managedServiceEntry.upfrontInternalCost !== 0) ? this.iQuoteConstants.costTypes.internal : this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '51-Managed Service Cost Summary-' + managedServiceEntry.id.toString().padStart(10, '0'),
          itemId: 'ManagedServiceCostSummary.' + managedServiceEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: (!isRecurringCost) ? managedServiceEntry.priceOverride : null,
          unitARPriceOverride: (isRecurringCost) ? managedServiceEntry.priceOverride : null
        }

        if (managedServiceEntry.recurringExternalCost < 0 || managedServiceEntry.recurringInternalCost < 0 || managedServiceEntry.upfrontExternalCost < 0 || managedServiceEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      ManagedService.autotaskEntries.forEach(managedServiceEntry => {
        dpSectionDescription = managedServiceEntry.salesforceDescription
        dpSectionSalesforceCoeCodeId = managedServiceEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        if (dpSectionMargins === undefined) {
          dpSectionMargins = {
            upfrontInternalMargin: 20,
            upfrontExternalMargin: 20,
            recurringInternalMargin: 20,
            recurringExternalMargin: 20
          }
        }
        var entry = {
          description: managedServiceEntry.description,
          quoteDescription: null,
          quantity: managedServiceEntry.quantity,
          quoteRef: managedServiceEntry.quoteRef,
          supplier: managedServiceEntry.supplier,
          code: managedServiceEntry.code,
          sellTermMonths: managedServiceEntry.sellTermMonths,
          costTermMonths: managedServiceEntry.costTermMonths,
          recurringCost: managedServiceEntry.unitARCost !== 0 || managedServiceEntry.unitARPrice !== 0,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: managedServiceEntry.salesforceCoeCodeId,
          designPackTableSectionId: 0,
          unitARExternalCost: dpSectionDescription === 'Support Pricing - Internal' ? 0 : managedServiceEntry.unitARCost,
          unitARInternalCost: dpSectionDescription !== 'Support Pricing - Internal' ? 0 : managedServiceEntry.unitARCost,
          unitOOExternalCost: dpSectionDescription === 'Support Pricing - Internal' ? 0 : managedServiceEntry.unitOOCost,
          unitOOInternalCost: dpSectionDescription !== 'Support Pricing - Internal' ? 0 : managedServiceEntry.unitOOCost,
          unitARExternalPrice: dpSectionDescription === 'Support Pricing - Internal' ? 0 : this.iQuoteFunctions_RoundToTwoDecimalPlaces(managedServiceEntry.unitARCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100)),
          unitARInternalPrice: dpSectionDescription !== 'Support Pricing - Internal' ? 0 : this.iQuoteFunctions_RoundToTwoDecimalPlaces(managedServiceEntry.unitARCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100)),
          unitOOExternalPrice: dpSectionDescription === 'Support Pricing - Internal' ? 0 : this.iQuoteFunctions_RoundToTwoDecimalPlaces(managedServiceEntry.unitOOCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: dpSectionDescription !== 'Support Pricing - Internal' ? 0 : this.iQuoteFunctions_RoundToTwoDecimalPlaces(managedServiceEntry.unitOOCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: dpSectionDescription === 'Support Pricing - Internal' ? this.iQuoteConstants.costTypes.internal : this.iQuoteConstants.costTypes.external,
          visibleToCustomer: false,
          includeInCalculations: false,
          showOnExportATCodesList: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '50-Managed Service Autotask-' + managedServiceEntry.id.toString().padStart(10, '0')
        }

        if (managedServiceEntry.recurringExternalCost < 0 || managedServiceEntry.recurringInternalCost < 0 || managedServiceEntry.upfrontExternalCost < 0 || managedServiceEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_Horizon () {
      if (this.externalQuotesModelMixin_State.quoteHorizon.id === undefined) {
        return []
      }

      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSalesforceCoeCodeId
      var designPackTableSectionId = 0
      var dpSource = 'Horizon'
      var horizon = this.externalQuotesModelMixin_State.quoteHorizon

      if (horizon.description !== '') {
        dpSource += ' (' + horizon.description + ')'
      }

      var costSummaryEntries = horizon.costSummaryEntries.filter(x => x.recurringExternalCost + x.recurringInternalCost + x.upfrontExternalCost + x.upfrontInternalCost > 0)

      costSummaryEntries.forEach(horizonEntry => {
        dpSectionDescription = horizonEntry.description
        dpSectionSalesforceCoeCodeId = horizonEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        if (dpSectionMargins === undefined) {
          dpSectionMargins = {
            upfrontInternalMargin: 20,
            upfrontExternalMargin: 20,
            recurringInternalMargin: 20,
            recurringExternalMargin: 20
          }
        }
        var entry = {
          description: horizonEntry.description !== '' ? horizonEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(horizonEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote Horizon',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: horizonEntry.recurringExternalCost + horizonEntry.recurringInternalCost !== 0,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: horizonEntry.recurringExternalCost,
          unitARInternalCost: horizonEntry.recurringInternalCost,
          unitOOExternalCost: horizonEntry.upfrontExternalCost,
          unitOOInternalCost: horizonEntry.upfrontInternalCost,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(horizonEntry.recurringExternalCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100)),
          unitARInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(horizonEntry.recurringInternalCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100)),
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(horizonEntry.upfrontExternalCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(horizonEntry.upfrontInternalCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: true,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '71-Horizon Cost Summary-' + horizonEntry.id.toString().padStart(10, '0')
        }

        if (horizonEntry.recurringExternalCost < 0 || horizonEntry.recurringInternalCost < 0 || horizonEntry.upfrontExternalCost < 0 || horizonEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      horizon.autotaskEntries.forEach(horizonEntry => {
        dpSectionDescription = horizonEntry.description
        dpSectionSalesforceCoeCodeId = horizonEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        if (dpSectionMargins === undefined) {
          dpSectionMargins = {
            upfrontInternalMargin: 20,
            upfrontExternalMargin: 20,
            recurringInternalMargin: 20,
            recurringExternalMargin: 20
          }
        }
        var entry = {
          description: horizonEntry.description,
          quoteDescription: null,
          quantity: horizonEntry.quantity,
          quoteRef: horizonEntry.quoteRef,
          supplier: horizonEntry.supplier,
          code: horizonEntry.code,
          sellTermMonths: horizonEntry.sellTermMonths,
          costTermMonths: horizonEntry.costTermMonths,
          recurringCost: horizonEntry.unitARCost !== 0 || horizonEntry.unitARPrice !== 0,
          dpSectionDescription: horizonEntry.description,
          dpSectionSalesforceCoeCodeId: horizonEntry.salesforceCoeCodeId,
          designPackTableSectionId: 0,
          unitARExternalCost: horizonEntry.unitARCost,
          unitARInternalCost: 0,
          unitOOExternalCost: horizonEntry.unitOOCost,
          unitOOInternalCost: 0,
          unitARExternalPrice: horizonEntry.unitARPrice,
          unitARInternalPrice: 0,
          unitOOExternalPrice: horizonEntry.unitOOPrice,
          unitOOInternalPrice: 0,
          unitMRExternalCost: horizonEntry.unitMRCost,
          unitMRInternalCost: 0,
          unitMRExternalPrice: horizonEntry.unitMRPrice,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: false,
          includeInCalculations: false,
          showOnExportATCodesList: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '70-Horizon Autotask-' + horizonEntry.id.toString().padStart(10, '0')
        }

        if (horizonEntry.recurringExternalCost < 0 || horizonEntry.recurringInternalCost < 0 || horizonEntry.upfrontExternalCost < 0 || horizonEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      return entries
    },

    iQuoteCalculationsDesignPackDetailDataMixin_IconSecure () {
      if (this.externalQuotesModelMixin_State.quoteIconSecure.id === undefined) {
        return []
      }

      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSalesforceCoeCodeId
      var designPackTableSectionId = 0
      var dpSource = 'Icon Secure'
      var iconSecure = this.externalQuotesModelMixin_State.quoteIconSecure

      if (iconSecure.description !== '') {
        dpSource += ' (' + iconSecure.description + ')'
      }

      var costSummaryEntries = iconSecure.costSummaryEntries.filter(x => x.recurringExternalCost + x.recurringInternalCost + x.upfrontExternalCost + x.upfrontInternalCost > 0)

      costSummaryEntries.forEach(iconSecureEntry => {
        dpSectionDescription = iconSecureEntry.description
        dpSectionSalesforceCoeCodeId = iconSecureEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        var isRecurringCost = iconSecureEntry.recurringExternalCost + iconSecureEntry.recurringInternalCost !== 0

        if (dpSectionMargins === undefined) {
          if (dpSectionSalesforceCoeCodeId === this.iQuoteConstants.salesforceCoeCodes.PROFESSIONAL_SERVICES) {
            dpSectionMargins = {
              upfrontInternalMargin: this.iQuoteConstants.salesMargins.PROFESSIONAL_SERVICES_DEFAULT_INTERNAL_MARGIN,
              upfrontExternalMargin: this.iQuoteConstants.salesMargins.PROFESSIONAL_SERVICES_DEFAULT_INTERNAL_MARGIN,
              recurringInternalMargin: this.iQuoteConstants.salesMargins.PROFESSIONAL_SERVICES_DEFAULT_INTERNAL_MARGIN,
              recurringExternalMargin: this.iQuoteConstants.salesMargins.PROFESSIONAL_SERVICES_DEFAULT_INTERNAL_MARGIN
            }
          } else {
            dpSectionMargins = {
              upfrontInternalMargin: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
              upfrontExternalMargin: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
              recurringInternalMargin: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
              recurringExternalMargin: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN
            }
          }
        }
        var entry = {
          description: iconSecureEntry.description !== '' ? iconSecureEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(iconSecureEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote Icon Secure',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: isRecurringCost,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: iconSecureEntry.recurringExternalCost,
          unitARInternalCost: iconSecureEntry.recurringInternalCost,
          unitOOExternalCost: iconSecureEntry.upfrontExternalCost,
          unitOOInternalCost: iconSecureEntry.upfrontInternalCost,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconSecureEntry.recurringExternalCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100)),
          unitARInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconSecureEntry.recurringInternalCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100)),
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconSecureEntry.upfrontExternalCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconSecureEntry.upfrontInternalCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: (iconSecureEntry.recurringInternalCost !== 0 || iconSecureEntry.upfrontInternalCost !== 0) ? this.iQuoteConstants.costTypes.internal : this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '41-Icon Secure Cost Summary-' + iconSecureEntry.id.toString().padStart(10, '0'),
          itemId: 'IconSecureCostSummary.' + iconSecureEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: (!isRecurringCost) ? iconSecureEntry.priceOverride : null,
          unitARPriceOverride: (isRecurringCost) ? iconSecureEntry.priceOverride : null
        }

        if (iconSecureEntry.recurringExternalCost < 0 || iconSecureEntry.recurringInternalCost < 0 || iconSecureEntry.upfrontExternalCost < 0 || iconSecureEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      iconSecure.autotaskEntries.forEach(iconSecureEntry => {
        dpSectionDescription = iconSecureEntry.description
        dpSectionSalesforceCoeCodeId = iconSecureEntry.salesforceCoeCodeId
        var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        if (dpSectionSalesforceCoeCodeId === this.iQuoteConstants.salesforceCoeCodes.PROFESSIONAL_SERVICES) {
          dpSectionMargins = {
            upfrontInternalMargin: this.iQuoteConstants.salesMargins.PROFESSIONAL_SERVICES_DEFAULT_INTERNAL_MARGIN,
            upfrontExternalMargin: this.iQuoteConstants.salesMargins.PROFESSIONAL_SERVICES_DEFAULT_INTERNAL_MARGIN,
            recurringInternalMargin: this.iQuoteConstants.salesMargins.PROFESSIONAL_SERVICES_DEFAULT_INTERNAL_MARGIN,
            recurringExternalMargin: this.iQuoteConstants.salesMargins.PROFESSIONAL_SERVICES_DEFAULT_INTERNAL_MARGIN
          }
        } else {
          dpSectionMargins = {
            upfrontInternalMargin: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
            upfrontExternalMargin: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
            recurringInternalMargin: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN,
            recurringExternalMargin: this.iQuoteConstants.salesMargins.DEFAULT_MARGIN
          }
        }

        var unitOOExternalCost = 0
        var unitOOInternalCost = 0
        var unitARExternalCost = 0
        var unitARInternalCost = 0

        if (iconSecureEntry.salesforceDescription === 'ICON Secure Access - Recurring') {
          unitOOExternalCost = iconSecureEntry.unitOOCost
          unitARExternalCost = iconSecureEntry.unitARCost
        } else {
          unitOOInternalCost = iconSecureEntry.unitOOCost
          unitARInternalCost = iconSecureEntry.unitARCost
        }

        var entry = {
          description: iconSecureEntry.description,
          quoteDescription: null,
          quantity: iconSecureEntry.quantity,
          quoteRef: iconSecureEntry.quoteRef,
          supplier: iconSecureEntry.supplier,
          code: iconSecureEntry.code,
          sellTermMonths: iconSecureEntry.sellTermMonths,
          costTermMonths: iconSecureEntry.costTermMonths,
          recurringCost: iconSecureEntry.unitARCost !== 0 || iconSecureEntry.unitARPrice !== 0,
          dpSectionDescription: iconSecureEntry.salesforceDescription,
          dpSectionSalesforceCoeCodeId: iconSecureEntry.salesforceCoeCodeId,
          designPackTableSectionId: 0,
          unitARExternalCost: unitARExternalCost,
          unitARInternalCost: unitARInternalCost,
          unitOOExternalCost: unitOOExternalCost,
          unitOOInternalCost: unitOOInternalCost,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitARExternalCost / ((100 - dpSectionMargins.recurringExternalMargin) / 100)),
          unitARInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitARInternalCost / ((100 - dpSectionMargins.recurringInternalMargin) / 100)),
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitOOExternalCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitOOInternalCost / ((100 - dpSectionMargins.upfrontInternalMargin) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: false,
          includeInCalculations: false,
          showOnExportATCodesList: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: true,
          canBePaused: true,
          sortOrder: '40-Icon Secure Autotask-' + iconSecureEntry.id.toString().padStart(10, '0')
        }

        if (iconSecureEntry.recurringExternalCost < 0 || iconSecureEntry.recurringInternalCost < 0 || iconSecureEntry.upfrontExternalCost < 0 || iconSecureEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_IconNow () {
      if (this.externalQuotesModelMixin_State.quoteIconNow.id === undefined) {
        return []
      }

      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSalesforceCoeCodeId
      var designPackTableSectionId = 0
      var dpSource = 'Icon Now'
      var iconNow = this.externalQuotesModelMixin_State.quoteIconNow

      if (iconNow.description !== '') {
        dpSource += ' (' + iconNow.description + ')'
      }

      var priceSummaryEntries = iconNow.priceSummaryEntries.filter(x => x.recurringPrice + x.upfrontPrice !== 0)

      priceSummaryEntries.forEach(iconNowEntry => {
        dpSectionDescription = iconNowEntry.description
        dpSectionSalesforceCoeCodeId = iconNowEntry.salesforceCoeCodeId
        var dpSectionDiscounts = designPack.discounts.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        var isRecurring = iconNowEntry.recurringPrice !== 0

        if (dpSectionDiscounts === undefined) {
          dpSectionDiscounts = {
            upfrontInternalDiscount: 0,
            upfrontExternalDiscount: 0,
            recurringInternalDiscount: 0,
            recurringExternalDiscount: 0
          }
        }
        var entry = {
          description: iconNowEntry.description !== '' ? iconNowEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(iconNowEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote Icon Now',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: isRecurring,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: 0,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconNowEntry.recurringPrice * ((100 - dpSectionDiscounts.recurringInternalDiscount) / 100)),
          unitOOExternalPrice: 0,
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconNowEntry.upfrontPrice * ((100 - dpSectionDiscounts.upfrontInternalDiscount) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: true,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: true,
          priceOverrideAllowed: true,
          usesGlobalMargins: false,
          canBePaused: true,
          sortOrder: '30-Icon Now Price Summary-' + iconNowEntry.id.toString().padStart(10, '0'),
          itemId: 'IconNowPriceSummary.' + iconNowEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: (!isRecurring) ? iconNowEntry.priceOverride : null,
          unitARPriceOverride: (isRecurring) ? iconNowEntry.priceOverride : null
        }

        if (iconNowEntry.recurringPrice < 0 || iconNowEntry.upfrontPrice < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      var costSummaryEntries = iconNow.costSummaryEntries.filter(x => x.recurringExternalCost + x.recurringInternalCost + x.upfrontExternalCost + x.upfrontInternalCost !== 0)

      costSummaryEntries.forEach(iconNowEntry => {
        dpSectionDescription = iconNowEntry.description
        dpSectionSalesforceCoeCodeId = iconNowEntry.salesforceCoeCodeId

        var entry = {
          description: iconNowEntry.description !== '' ? iconNowEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(iconNowEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote Icon Now',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: iconNowEntry.recurringExternalCost + iconNowEntry.recurringInternalCost !== 0,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: iconNowEntry.recurringExternalCost,
          unitARInternalCost: iconNowEntry.recurringInternalCost,
          unitOOExternalCost: iconNowEntry.upfrontExternalCost,
          unitOOInternalCost: iconNowEntry.upfrontInternalCost,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: false,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          usesGlobalMargins: false,
          priceOverrideAllowed: false,
          canBePaused: true,
          sortOrder: '31-Icon Now Cost Summary-' + iconNowEntry.id.toString().padStart(10, '0')
        }

        if (iconNowEntry.recurringExternalCost < 0 || iconNowEntry.recurringInternalCost < 0 || iconNowEntry.upfrontExternalCost < 0 || iconNowEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      iconNow.autotaskEntries.forEach(iconNowEntry => {
        dpSectionDescription = iconNowEntry.description
        dpSectionSalesforceCoeCodeId = iconNowEntry.salesforceCoeCodeId
        var dpSectionDiscounts = designPack.discounts.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        if (dpSectionDiscounts === undefined) {
          dpSectionDiscounts = {
            upfrontInternalDiscount: 0,
            upfrontExternalDiscount: 0,
            recurringInternalDiscount: 0,
            recurringExternalDiscount: 0
          }
        }
        var entry = {
          description: iconNowEntry.description,
          quoteDescription: null,
          quantity: iconNowEntry.quantity,
          quoteRef: iconNowEntry.quoteRef,
          supplier: iconNowEntry.supplier,
          code: iconNowEntry.code,
          sellTermMonths: iconNowEntry.sellTermMonths,
          costTermMonths: iconNowEntry.costTermMonths,
          recurringCost: iconNowEntry.unitARCost !== 0 || iconNowEntry.unitARPrice !== 0,
          dpSectionDescription: iconNowEntry.salesforceDescription,
          dpSectionSalesforceCoeCodeId: iconNowEntry.salesforceCoeCodeId,
          designPackTableSectionId: 0,
          unitARExternalCost: iconNowEntry.unitARCost,
          unitARInternalCost: 0,
          unitOOExternalCost: iconNowEntry.unitOOCost,
          unitOOInternalCost: 0,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconNowEntry.unitARPrice * ((100 - dpSectionDiscounts.recurringExternalDiscount) / 100)),
          unitARInternalPrice: 0,
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(iconNowEntry.unitOOPrice * ((100 - dpSectionDiscounts.recurringExternalDiscount) / 100)),
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: false,
          includeInCalculations: false,
          showOnExportATCodesList: true,
          canBeDiscounted: true,
          priceOverrideAllowed: false,
          usesGlobalMargins: false,
          canBePaused: true,
          sortOrder: '32-Icon Now Autotask-' + iconNowEntry.id.toString().padStart(10, '0')
        }

        if (iconNowEntry.recurringExternalCost < 0 || iconNowEntry.recurringInternalCost < 0 || iconNowEntry.upfrontExternalCost < 0 || iconNowEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_Twilio () {
      if (this.externalQuotesModelMixin_State.quoteTwilio.id === undefined) {
        return []
      }

      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSalesforceCoeCodeId
      var designPackTableSectionId = 0
      var dpSource = 'Twilio'
      var twilio = this.externalQuotesModelMixin_State.quoteTwilio

      if (twilio.description !== '') {
        dpSource += ' (' + twilio.description + ')'
      }

      var priceSummaryEntries = twilio.priceSummaryEntries.filter(x => x.recurringExternalPrice + x.upfrontExternalPrice + x.recurringInternalPrice + x.upfrontInternalPrice !== 0)

      priceSummaryEntries.forEach(twilioEntry => {
        dpSectionDescription = twilioEntry.description
        dpSectionSalesforceCoeCodeId = twilioEntry.salesforceCoeCodeId
        var dpSectionDiscounts = designPack.discounts.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        var isRecurring = twilioEntry.recurringExternalPrice + twilioEntry.recurringInternalPrice !== 0

        if (dpSectionDiscounts === undefined) {
          dpSectionDiscounts = {
            upfrontInternalDiscount: 0,
            upfrontExternalDiscount: 0,
            recurringInternalDiscount: 0,
            recurringExternalDiscount: 0
          }
        }
        var entry = {
          description: twilioEntry.description !== '' ? twilioEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(twilioEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote Twilio',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: isRecurring,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: 0,
          unitOOInternalCost: 0,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(twilioEntry.recurringExternalPrice * ((100 - dpSectionDiscounts.recurringExternalDiscount) / 100)),
          unitARInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(twilioEntry.recurringInternalPrice * ((100 - dpSectionDiscounts.recurringInternalDiscount) / 100)),
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(twilioEntry.upfrontExternalPrice * ((100 - dpSectionDiscounts.upfrontExternalDiscount) / 100)),
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(twilioEntry.upfrontInternalPrice * ((100 - dpSectionDiscounts.upfrontInternalDiscount) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: (twilioEntry.recurringInternalPrice !== 0 || twilioEntry.upfrontInternalPrice !== 0) ? this.iQuoteConstants.costTypes.internal : this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: false,
          canBePaused: true,
          sortOrder: '',
          itemId: 'TwilioPriceSummary.' + twilioEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: (!isRecurring) ? twilioEntry.priceOverride : null,
          unitARPriceOverride: (isRecurring) ? twilioEntry.priceOverride : null
        }

        if (twilioEntry.recurringExternalPrice < 0 || twilioEntry.upfrontExternalPrice < 0 || twilioEntry.recurringInternalPrice < 0 || twilioEntry.upfrontInternalPrice < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      var costSummaryEntries = twilio.costSummaryEntries.filter(x => x.recurringExternalCost + x.recurringInternalCost + x.upfrontExternalCost + x.upfrontInternalCost !== 0)

      costSummaryEntries.forEach(twilioEntry => {
        dpSectionDescription = twilioEntry.description
        dpSectionSalesforceCoeCodeId = twilioEntry.salesforceCoeCodeId

        var entry = {
          description: twilioEntry.description !== '' ? twilioEntry.description : this.iQuoteFunctions_getSF_COE_NameFromId(twilioEntry.salesforceCoeCodeId),
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'iQuote Twilio',
          sellTermMonths: designPack.termLengthMonths,
          costTermMonths: designPack.termLengthMonths,
          recurringCost: twilioEntry.recurringExternalCost + twilioEntry.recurringInternalCost !== 0,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: twilioEntry.recurringExternalCost,
          unitARInternalCost: twilioEntry.recurringInternalCost,
          unitOOExternalCost: twilioEntry.upfrontExternalCost,
          unitOOInternalCost: twilioEntry.upfrontInternalCost,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: false,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: false,
          canBePaused: true,
          sortOrder: '02-Twilio Cost Summary-' + twilioEntry.id.toString().padStart(10, '0')
        }

        if (twilioEntry.recurringExternalCost < 0 || twilioEntry.recurringInternalCost < 0 || twilioEntry.upfrontExternalCost < 0 || twilioEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      twilio.autotaskEntries.forEach(twilioEntry => {
        dpSectionDescription = twilioEntry.description
        dpSectionSalesforceCoeCodeId = twilioEntry.salesforceCoeCodeId
        var dpSectionDiscounts = designPack.discounts.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        if (dpSectionDiscounts === undefined) {
          dpSectionDiscounts = {
            upfrontInternalDiscount: 0,
            upfrontExternalDiscount: 0,
            recurringInternalDiscount: 0,
            recurringExternalDiscount: 0
          }
        }
        var entry = {
          description: twilioEntry.description,
          quoteDescription: null,
          quantity: twilioEntry.quantity,
          quoteRef: twilioEntry.quoteRef,
          supplier: twilioEntry.supplier,
          code: twilioEntry.code,
          sellTermMonths: twilioEntry.sellTermMonths,
          costTermMonths: twilioEntry.costTermMonths,
          recurringCost: twilioEntry.unitARCost !== 0 || twilioEntry.unitARPrice !== 0,
          dpSectionDescription: twilioEntry.salesforceDescription,
          dpSectionSalesforceCoeCodeId: twilioEntry.salesforceCoeCodeId,
          designPackTableSectionId: 0,
          unitARExternalCost: twilioEntry.unitARCost,
          unitARInternalCost: 0,
          unitOOExternalCost: twilioEntry.unitOOCost,
          unitOOInternalCost: 0,
          unitARExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(twilioEntry.unitARPrice * ((100 - dpSectionDiscounts.recurringExternalDiscount) / 100)),
          unitARInternalPrice: 0,
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(twilioEntry.unitOOPrice * ((100 - dpSectionDiscounts.recurringExternalDiscount) / 100)),
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'External Quotes - ' + dpSource,
          categoryName: dpSource,
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: false,
          includeInCalculations: false,
          showOnExportATCodesList: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: false,
          canBePaused: true,
          sortOrder: '01-Twilio Autotask-' + twilioEntry.id.toString().padStart(10, '0')
        }

        if (twilioEntry.recurringExternalCost < 0 || twilioEntry.recurringInternalCost < 0 || twilioEntry.upfrontExternalCost < 0 || twilioEntry.upfrontInternalCost < 0) {
          entry.quantity = -1
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_HardwareMain () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSortOrder
      var unitOOExternalCost
      var unitARExternalCost
      var unitOOExternalPrice
      var unitARExternalPrice
      var dpSectionSalesforceCoeCodeId

      dpSectionDescription = 'Hardware (Tab)'
      dpSectionSortOrder = 400
      dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.TECHNOLOGY

      designPack.hardwareMainEntries.filter(x => !x.disabled && x.quantity !== 0).forEach(hardwareMainEntry => {
        switch (hardwareMainEntry.designPackHardwareCurrencyId) {
          case (this.iQuoteConstants.hardwareCurrencies.GBP):
            unitOOExternalCost = hardwareMainEntry.unitCost
            break
          case (this.iQuoteConstants.hardwareCurrencies.USD):
            unitOOExternalCost = hardwareMainEntry.unitCost * designPack.exchangeRateUSDToGBP
            break
          case (this.iQuoteConstants.hardwareCurrencies.EUR):
            unitOOExternalCost = hardwareMainEntry.unitCost * (1 / designPack.exchangeRateGBPToEUR)
            break
        }

        unitOOExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitOOExternalCost / ((100 - hardwareMainEntry.marginPercent) / 100))
        unitARExternalCost = 0
        unitARExternalPrice = 0

        if (hardwareMainEntry.quantity < 0) {
          // It's a credit item, so cost & price must be inverted
          unitOOExternalCost = -unitOOExternalCost
          unitARExternalCost = -unitARExternalCost
          unitOOExternalPrice = -unitOOExternalPrice
          unitARExternalPrice = -unitARExternalPrice
        }

        var entry = {
          description: hardwareMainEntry.description !== '' ? hardwareMainEntry.description : 'Hardware Main - No Description',
          quoteDescription: null,
          quantity: hardwareMainEntry.quantity,
          quoteRef: '',
          supplier: this.iQuoteStore_GetHardwareTableLookups.vendors.find(x => x.id === hardwareMainEntry.designPackHardwareVendorId).description,
          code: hardwareMainEntry.partNumber,
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Hardware - Main',
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: 0,
          dpSectionSortOrder: dpSectionSortOrder,
          unitARExternalCost: unitARExternalCost,
          unitARInternalCost: 0,
          unitOOExternalCost: unitOOExternalCost,
          unitOOInternalCost: 0,
          unitARExternalPrice: unitARExternalPrice,
          unitARInternalPrice: 0,
          unitOOExternalPrice: unitOOExternalPrice,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Tabs',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: false,
          canBePaused: false,
          sortOrder: '96-Hardware Main-' + hardwareMainEntry.id.toString().padStart(10, '0'),
          itemId: 'HardwareMain.' + hardwareMainEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: hardwareMainEntry.priceOverride,
          unitARPriceOverride: null
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_HardwareMisc () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSortOrder
      var unitOOExternalCost
      var unitARExternalCost
      var unitOOExternalPrice
      var unitARExternalPrice
      var dpSectionSalesforceCoeCodeId

      dpSectionDescription = 'Hardware (Tab)'
      dpSectionSortOrder = 400
      dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.TECHNOLOGY

      designPack.hardwareMiscEntries.filter(x => !x.disabled && x.quantity !== 0 && x.designPackHardwareMiscEquipmentSubTypeId !== 0).forEach(hardwareMiscEntry => {
        unitOOExternalCost = this.iQuoteStore_GetHardwareTableLookups.miscEquipmentSubTypes.find(x => x.id === hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId).cost
        unitOOExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitOOExternalCost / ((100 - hardwareMiscEntry.marginPercent) / 100))
        unitARExternalCost = 0
        unitARExternalPrice = 0

        if (hardwareMiscEntry.quantity < 0) {
          // It's a credit item, so cost & price must be inverted
          unitOOExternalCost = -unitOOExternalCost
          unitARExternalCost = -unitARExternalCost
          unitOOExternalPrice = -unitOOExternalPrice
          unitARExternalPrice = -unitARExternalPrice
        }

        var entry = {
          description: this.iQuoteStore_GetHardwareTableLookups.miscEquipmentSubTypes.find(x => x.id === hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId).description,
          quoteDescription: null,
          quantity: hardwareMiscEntry.quantity,
          quoteRef: '',
          supplier: '',
          code: this.iQuoteStore_GetHardwareTableLookups.miscEquipmentSubTypes.find(x => x.id === hardwareMiscEntry.designPackHardwareMiscEquipmentSubTypeId).code,
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Hardware - Misc',
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: 0,
          dpSectionSortOrder: dpSectionSortOrder,
          unitARExternalCost: unitARExternalCost,
          unitARInternalCost: 0,
          unitOOExternalCost: unitOOExternalCost,
          unitOOInternalCost: 0,
          unitARExternalPrice: unitARExternalPrice,
          unitARInternalPrice: 0,
          unitOOExternalPrice: unitOOExternalPrice,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Tabs',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: false,
          canBePaused: false,
          sortOrder: '97-Hardware Misc-' + hardwareMiscEntry.id.toString().padStart(10, '0'),
          itemId: 'HardwareMisc.' + hardwareMiscEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: hardwareMiscEntry.priceOverride,
          unitARPriceOverride: null
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_CircuitsBroadband () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSortOrder
      var unitOOExternalCost
      var unitARExternalCost
      var unitOOExternalPrice
      var unitARExternalPrice
      var dpSectionSalesforceCoeCodeId

      // For each circuit entry we want to add a line for the upfront portion, and a line for the recurring portion
      // The first loop round (index=0) will be upfront, the second loop round (index=1) will be recurring
      for (let index = 0; index <= 1; index++) {
        if (index === 0) {
          // Upfront Entry
          dpSectionDescription = 'Circuit Install'
          dpSectionSortOrder = 500
          dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.NS_UPFRONT
        } else {
          // Recurring Entry
          dpSectionDescription = 'Circuit Recurring'
          dpSectionSortOrder = 502
          dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.DATA_CONNECTIVITY
        }

        designPack.circuitsBroadbandEntries.filter(x => !x.disabled && x.quantity !== 0 && x.designPackCircuitsBroadbandProductId !== 0).forEach(circuitsBroadbandEntry => {
          if (index === 0) {
            // Upfront Entry
            unitOOExternalCost = circuitsBroadbandEntry.costUpfront
            unitOOExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(circuitsBroadbandEntry.costUpfront / ((100 - circuitsBroadbandEntry.marginCapexPercent) / 100))
            unitARExternalCost = 0
            unitARExternalPrice = 0
          } else {
            // Recurring Entry
            unitOOExternalCost = 0
            unitOOExternalPrice = 0
            unitARExternalCost = circuitsBroadbandEntry.costMonthly * 12
            unitARExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitARExternalCost / ((100 - circuitsBroadbandEntry.marginOpexPercent) / 100))
          }

          if (circuitsBroadbandEntry.quantity < 0) {
            // It's a credit item, so cost & price must be inverted
            unitOOExternalCost = -unitOOExternalCost
            unitARExternalCost = -unitARExternalCost
            unitOOExternalPrice = -unitOOExternalPrice
            unitARExternalPrice = -unitARExternalPrice
          }

          var entry = {
            description: this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts.find(x => x.id === circuitsBroadbandEntry.designPackCircuitsBroadbandProductId).description,
            quoteDescription: null,
            quantity: circuitsBroadbandEntry.quantity,
            quoteRef: '',
            supplier: this.iQuoteStore_GetCircuitsTableLookups.broadbandCarriers.find(x => x.id === circuitsBroadbandEntry.designPackCircuitsBroadbandCarrierId).description,
            code: this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts.find(x => x.id === circuitsBroadbandEntry.designPackCircuitsBroadbandProductId).atCode,
            sellTermMonths: 0,
            costTermMonths: this.iQuoteStore_GetCircuitsTableLookups.broadbandProducts.find(x => x.id === circuitsBroadbandEntry.designPackCircuitsBroadbandProductId).termLengthMonths,
            recurringCost: (index === 1),
            categoryName: 'Circuits - Broadband',
            dpSectionDescription: dpSectionDescription,
            dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
            designPackTableSectionId: 0,
            dpSectionSortOrder: dpSectionSortOrder,
            unitARExternalCost: unitARExternalCost,
            unitARInternalCost: 0,
            unitOOExternalCost: unitOOExternalCost,
            unitOOInternalCost: 0,
            unitARExternalPrice: unitARExternalPrice,
            unitARInternalPrice: 0,
            unitOOExternalPrice: unitOOExternalPrice,
            unitOOInternalPrice: 0,
            unitMRExternalCost: 0,
            unitMRInternalCost: 0,
            unitMRExternalPrice: 0,
            unitMRInternalPrice: 0,
            dpSource: 'Design Pack Tabs',
            costTypeId: this.iQuoteConstants.costTypes.external,
            visibleToCustomer: true,
            showOnExportATCodesList: true,
            includeInCalculations: true,
            canBeDiscounted: false,
            priceOverrideAllowed: true,
            usesGlobalMargins: false,
            canBePaused: false,
            sortOrder: '90-Circuits Broadband-' + circuitsBroadbandEntry.id.toString().padStart(10, '0'),
            itemId: 'CircuitsBroadband.' + circuitsBroadbandEntry.designPackItemLookupId.toString(),
            unitOOPriceOverride: (index === 0) ? circuitsBroadbandEntry.priceOverrideOO : null,
            unitARPriceOverride: (index === 1) ? circuitsBroadbandEntry.priceOverrideAR : null
          }

          entries.push(entry)
        })
      }

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_CircuitsHostingAndCabling () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSortOrder
      var unitOOExternalCost
      var unitARExternalCost
      var unitOOExternalPrice
      var unitARExternalPrice
      var dpSectionSalesforceCoeCodeId

      // For each circuit entry we want to add a line for the upfront portion, and a line for the recurring portion
      // The first loop round (index=0) will be upfront, the second loop round (index=1) will be recurring
      for (let index = 0; index <= 1; index++) {
        if (index === 0) {
          // Upfront Entry
          dpSectionDescription = 'Circuit Install'
          dpSectionSortOrder = 500
          dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.NS_UPFRONT
        } else {
          // Recurring Entry
          dpSectionDescription = 'Circuit Recurring'
          dpSectionSortOrder = 502
          dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.DATA_CONNECTIVITY
        }

        designPack.circuitsHostingAndCablingEntries.filter(x => !x.disabled && x.quantity !== 0 && x.designPackCircuitsHostingAndCablingProductId !== 0).forEach(circuitsHostingAndCablingEntry => {
          if (index === 0) {
            // Upfront Entry
            unitOOExternalCost = circuitsHostingAndCablingEntry.costUpfront
            unitOOExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(circuitsHostingAndCablingEntry.costUpfront / ((100 - circuitsHostingAndCablingEntry.marginCapexPercent) / 100))
            unitARExternalCost = 0
            unitARExternalPrice = 0
          } else {
            // Recurring Entry
            unitOOExternalCost = 0
            unitOOExternalPrice = 0
            unitARExternalCost = circuitsHostingAndCablingEntry.costMonthly * 12
            unitARExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitARExternalCost / ((100 - circuitsHostingAndCablingEntry.marginOpexPercent) / 100))
          }

          if (circuitsHostingAndCablingEntry.quantity < 0) {
            // It's a credit item, so cost & price must be inverted
            unitOOExternalCost = -unitOOExternalCost
            unitARExternalCost = -unitARExternalCost
            unitOOExternalPrice = -unitOOExternalPrice
            unitARExternalPrice = -unitARExternalPrice
          }

          var entry = {
            description: this.iQuoteStore_GetCircuitsTableLookups.hostingAndCablingProducts.find(x => x.id === circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingProductId).description,
            quoteDescription: null,
            quantity: circuitsHostingAndCablingEntry.quantity,
            quoteRef: circuitsHostingAndCablingEntry.supplierQuoteRef,
            supplier: this.iQuoteStore_GetCircuitsTableLookups.hostingAndCablingCarriers.find(x => x.id === circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingCarrierId).description,
            code: this.iQuoteStore_GetCircuitsTableLookups.hostingAndCablingProducts.find(x => x.id === circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingProductId).atCode,
            sellTermMonths: 0,
            costTermMonths: this.iQuoteStore_GetCircuitsTableLookups.hostingAndCablingProducts.find(x => x.id === circuitsHostingAndCablingEntry.designPackCircuitsHostingAndCablingProductId).termLengthMonths,
            recurringCost: (index === 1),
            categoryName: 'Circuits - Hosting And Cabling',
            dpSectionDescription: dpSectionDescription,
            dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
            designPackTableSectionId: 0,
            dpSectionSortOrder: dpSectionSortOrder,
            unitARExternalCost: unitARExternalCost,
            unitARInternalCost: 0,
            unitOOExternalCost: unitOOExternalCost,
            unitOOInternalCost: 0,
            unitARExternalPrice: unitARExternalPrice,
            unitARInternalPrice: 0,
            unitOOExternalPrice: unitOOExternalPrice,
            unitOOInternalPrice: 0,
            unitMRExternalCost: 0,
            unitMRInternalCost: 0,
            unitMRExternalPrice: 0,
            unitMRInternalPrice: 0,
            dpSource: 'Design Pack Tabs',
            costTypeId: this.iQuoteConstants.costTypes.external,
            visibleToCustomer: true,
            showOnExportATCodesList: true,
            includeInCalculations: true,
            canBeDiscounted: false,
            priceOverrideAllowed: true,
            usesGlobalMargins: false,
            canBePaused: false,
            sortOrder: '92-Circuits Hosting and Cabling-' + circuitsHostingAndCablingEntry.id.toString().padStart(10, '0'),
            itemId: 'CircuitsHostingAndCabling.' + circuitsHostingAndCablingEntry.designPackItemLookupId.toString(),
            unitOOPriceOverride: (index === 0) ? circuitsHostingAndCablingEntry.priceOverrideOO : null,
            unitARPriceOverride: (index === 1) ? circuitsHostingAndCablingEntry.priceOverrideAR : null
          }

          entries.push(entry)
        })
      }

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_CircuitsWanConnectivity () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSortOrder
      var unitOOExternalCost
      var unitARExternalCost
      var unitOOExternalPrice
      var unitARExternalPrice
      var dpSectionSalesforceCoeCodeId

      // For each circuit entry we want to add a line for the upfront portion, and a line for the recurring portion
      // The first loop round (index=0) will be upfront, the second loop round (index=1) will be recurring
      for (let index = 0; index <= 1; index++) {
        if (index === 0) {
          // Upfront Entry
          dpSectionDescription = 'Circuit Install'
          dpSectionSortOrder = 500
          dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.NS_UPFRONT
        } else {
          // Recurring Entry
          dpSectionDescription = 'Circuit Recurring'
          dpSectionSortOrder = 502
          dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.DATA_CONNECTIVITY
        }

        designPack.circuitsWanConnectivityEntries.filter(x => !x.disabled && x.quantity !== 0 && x.designPackCircuitsWanConnectivityProductId !== 0).forEach(circuitsWanConnectivityEntry => {
          if (index === 0) {
            // Upfront Entry
            unitOOExternalCost = circuitsWanConnectivityEntry.costUpfront
            unitOOExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(circuitsWanConnectivityEntry.costUpfront / ((100 - circuitsWanConnectivityEntry.marginCapexPercent) / 100))
            unitARExternalCost = 0
            unitARExternalPrice = 0
          } else {
            // Recurring Entry
            unitOOExternalCost = 0
            unitOOExternalPrice = 0
            unitARExternalCost = circuitsWanConnectivityEntry.costMonthly * 12
            unitARExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitARExternalCost / ((100 - circuitsWanConnectivityEntry.marginOpexPercent) / 100))
          }
          if (circuitsWanConnectivityEntry.quantity < 0) {
            // It's a credit item, so cost & price must be inverted
            unitOOExternalCost = -unitOOExternalCost
            unitARExternalCost = -unitARExternalCost
            unitOOExternalPrice = -unitOOExternalPrice
            unitARExternalPrice = -unitARExternalPrice
          }

          var entry = {
            description: circuitsWanConnectivityEntry.description !== '' ? circuitsWanConnectivityEntry.description : 'WAN Connectivity - No Description',
            quoteDescription: null,
            quantity: circuitsWanConnectivityEntry.quantity,
            quoteRef: circuitsWanConnectivityEntry.supplierQuoteRef,
            supplier: circuitsWanConnectivityEntry.carrier,
            code: circuitsWanConnectivityEntry.description,
            sellTermMonths: 0,
            costTermMonths: circuitsWanConnectivityEntry.contractTermLengthMonths,
            recurringCost: (index === 1),
            categoryName: 'Circuits - WAN Connectivity',
            dpSectionDescription: dpSectionDescription,
            dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
            designPackTableSectionId: 0,
            dpSectionSortOrder: dpSectionSortOrder,
            unitARExternalCost: unitARExternalCost,
            unitARInternalCost: 0,
            unitOOExternalCost: unitOOExternalCost,
            unitOOInternalCost: 0,
            unitARExternalPrice: unitARExternalPrice,
            unitARInternalPrice: 0,
            unitOOExternalPrice: unitOOExternalPrice,
            unitOOInternalPrice: 0,
            unitMRExternalCost: 0,
            unitMRInternalCost: 0,
            unitMRExternalPrice: 0,
            unitMRInternalPrice: 0,
            dpSource: 'Design Pack Tabs',
            costTypeId: this.iQuoteConstants.costTypes.external,
            visibleToCustomer: true,
            showOnExportATCodesList: true,
            includeInCalculations: true,
            canBeDiscounted: false,
            priceOverrideAllowed: true,
            usesGlobalMargins: false,
            canBePaused: false,
            sortOrder: '94-Circuits Wan Connectivity-' + circuitsWanConnectivityEntry.id.toString().padStart(10, '0'),
            itemId: 'CircuitsWanConnectivity.' + circuitsWanConnectivityEntry.designPackItemLookupId.toString(),
            unitOOPriceOverride: (index === 0) ? circuitsWanConnectivityEntry.priceOverrideOO : null,
            unitARPriceOverride: (index === 1) ? circuitsWanConnectivityEntry.priceOverrideAR : null
          }

          entries.push(entry)
        })
      }

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_CircuitsFixedLineVoice () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSortOrder
      var unitOOExternalCost
      var unitARExternalCost
      var unitOOExternalPrice
      var unitARExternalPrice
      var dpSectionSalesforceCoeCodeId

      // For each circuit entry we want to add a line for the upfront portion, and a line for the recurring portion
      // The first loop round (index=0) will be upfront, the second loop round (index=1) will be recurring
      for (let index = 0; index <= 1; index++) {
        if (index === 0) {
          // Upfront Entry
          dpSectionDescription = 'Circuit Install'
          dpSectionSortOrder = 500
          dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.NS_UPFRONT
        } else {
          // Recurring Entry
          dpSectionDescription = 'Circuit Recurring'
          dpSectionSortOrder = 502
          dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.DATA_CONNECTIVITY
        }

        designPack.circuitsFixedLineVoiceEntries.filter(x => !x.disabled && x.quantity !== 0 && x.designPackCircuitsFixedLineVoiceProductId !== 0).forEach(circuitsFixedLineVoiceEntry => {
          if (index === 0) {
            // Upfront Entry
            unitOOExternalCost = circuitsFixedLineVoiceEntry.costUpfront
            unitOOExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(circuitsFixedLineVoiceEntry.costUpfront / ((100 - circuitsFixedLineVoiceEntry.marginCapexPercent) / 100))
            unitARExternalCost = 0
            unitARExternalPrice = 0
          } else {
            // Recurring Entry
            unitOOExternalCost = 0
            unitOOExternalPrice = 0
            unitARExternalCost = circuitsFixedLineVoiceEntry.costMonthly * 12
            unitARExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitARExternalCost / ((100 - circuitsFixedLineVoiceEntry.marginOpexPercent) / 100))
          }

          if (circuitsFixedLineVoiceEntry.quantity < 0) {
            // It's a credit item, so cost & price must be inverted
            unitOOExternalCost = -unitOOExternalCost
            unitARExternalCost = -unitARExternalCost
            unitOOExternalPrice = -unitOOExternalPrice
            unitARExternalPrice = -unitARExternalPrice
          }

          var entry = {
            description: this.iQuoteStore_GetCircuitsTableLookups.fixedLineVoiceProducts.find(x => x.id === circuitsFixedLineVoiceEntry.designPackCircuitsFixedLineVoiceProductId).description,
            quoteDescription: null,
            quantity: circuitsFixedLineVoiceEntry.quantity,
            quoteRef: '',
            supplier: this.iQuoteStore_GetCircuitsTableLookups.fixedLineVoiceCarriers.find(x => x.id === circuitsFixedLineVoiceEntry.designPackCircuitsFixedLineVoiceCarrierId).description,
            code: this.iQuoteStore_GetCircuitsTableLookups.fixedLineVoiceProducts.find(x => x.id === circuitsFixedLineVoiceEntry.designPackCircuitsFixedLineVoiceProductId).atCode,
            sellTermMonths: 0,
            costTermMonths: this.iQuoteStore_GetCircuitsTableLookups.fixedLineVoiceProducts.find(x => x.id === circuitsFixedLineVoiceEntry.designPackCircuitsFixedLineVoiceProductId).termLengthMonths,
            recurringCost: (index === 1),
            categoryName: 'Circuits - Fixed Line Voice',
            dpSectionDescription: dpSectionDescription,
            dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
            designPackTableSectionId: 0,
            dpSectionSortOrder: dpSectionSortOrder,
            unitARExternalCost: unitARExternalCost,
            unitARInternalCost: 0,
            unitOOExternalCost: unitOOExternalCost,
            unitOOInternalCost: 0,
            unitARExternalPrice: unitARExternalPrice,
            unitARInternalPrice: 0,
            unitOOExternalPrice: unitOOExternalPrice,
            unitOOInternalPrice: 0,
            unitMRExternalCost: 0,
            unitMRInternalCost: 0,
            unitMRExternalPrice: 0,
            unitMRInternalPrice: 0,
            dpSource: 'Design Pack Tabs',
            costTypeId: this.iQuoteConstants.costTypes.external,
            visibleToCustomer: true,
            showOnExportATCodesList: true,
            includeInCalculations: true,
            canBeDiscounted: false,
            priceOverrideAllowed: true,
            usesGlobalMargins: false,
            canBePaused: false,
            sortOrder: '91-Circuits Fixed Line Voice-' + circuitsFixedLineVoiceEntry.id.toString().padStart(10, '0'),
            itemId: 'CircuitsFixedLineVoice.' + circuitsFixedLineVoiceEntry.designPackItemLookupId.toString(),
            unitOOPriceOverride: (index === 0) ? circuitsFixedLineVoiceEntry.priceOverrideOO : null,
            unitARPriceOverride: (index === 1) ? circuitsFixedLineVoiceEntry.priceOverrideAR : null
          }

          entries.push(entry)
        })
      }

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_CircuitsEquinixCloudConnect () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription
      var dpSectionSortOrder
      var unitOOExternalCost
      var unitARExternalCost
      var unitOOExternalPrice
      var unitARExternalPrice
      var dpSectionSalesforceCoeCodeId

      dpSectionDescription = 'Circuit Recurring'
      dpSectionSortOrder = 502
      dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.DATA_CONNECTIVITY

      designPack.circuitsEquinixCloudConnectEntries.filter(x => !x.disabled && x.designPackCircuitsEquinixCloudConnectProductId !== 0).forEach(circuitsEquinixCloudConnectEntry => {
        unitOOExternalCost = 0
        unitOOExternalPrice = 0
        unitARExternalCost = circuitsEquinixCloudConnectEntry.costMonthly * 12
        unitARExternalPrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitARExternalCost / ((100 - circuitsEquinixCloudConnectEntry.marginOpexPercent) / 100))

        if (circuitsEquinixCloudConnectEntry.quantity < 0) {
        // It's a credit item, so cost & price must be inverted
          unitOOExternalCost = -unitOOExternalCost
          unitARExternalCost = -unitARExternalCost
          unitOOExternalPrice = -unitOOExternalPrice
          unitARExternalPrice = -unitARExternalPrice
        }

        var existingEntryForThisCircuitType = entries.find(x => x.description === this.iQuoteStore_GetCircuitsTableLookups.equinixCircuitTypes.find(x => x.id === circuitsEquinixCloudConnectEntry.designPackCircuitsEquinixCircuitTypeId).description)

        if (existingEntryForThisCircuitType !== undefined) {
          existingEntryForThisCircuitType.quantity++
        } else {
          var entry = {
            description: this.iQuoteStore_GetCircuitsTableLookups.equinixCircuitTypes.find(x => x.id === circuitsEquinixCloudConnectEntry.designPackCircuitsEquinixCircuitTypeId).description,
            quoteDescription: null,
            quantity: 1,
            quoteRef: '',
            supplier: '',
            code: this.iQuoteStore_GetCircuitsTableLookups.equinixCircuitTypes.find(x => x.id === circuitsEquinixCloudConnectEntry.designPackCircuitsEquinixCircuitTypeId).atCode,
            sellTermMonths: 0,
            costTermMonths: 0,
            recurringCost: true,
            categoryName: 'Circuits - Equinix Cloud Connect',
            dpSectionDescription: dpSectionDescription,
            dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
            designPackTableSectionId: 0,
            dpSectionSortOrder: dpSectionSortOrder,
            unitARExternalCost: unitARExternalCost,
            unitARInternalCost: 0,
            unitOOExternalCost: unitOOExternalCost,
            unitOOInternalCost: 0,
            unitARExternalPrice: unitARExternalPrice,
            unitARInternalPrice: 0,
            unitOOExternalPrice: unitOOExternalPrice,
            unitOOInternalPrice: 0,
            unitMRExternalCost: 0,
            unitMRInternalCost: 0,
            unitMRExternalPrice: 0,
            unitMRInternalPrice: 0,
            dpSource: 'Design Pack Tabs',
            costTypeId: this.iQuoteConstants.costTypes.external,
            visibleToCustomer: true,
            showOnExportATCodesList: true,
            includeInCalculations: true,
            canBeDiscounted: false,
            priceOverrideAllowed: true,
            usesGlobalMargins: false,
            canBePaused: false,
            sortOrder: '93-Circuits Equinix-' + circuitsEquinixCloudConnectEntry.id.toString().padStart(10, '0'),
            itemId: 'CircuitsEquinix.' + circuitsEquinixCloudConnectEntry.designPackItemLookupId.toString(),
            unitOOPriceOverride: null,
            unitARPriceOverride: circuitsEquinixCloudConnectEntry.priceOverride
          }

          entries.push(entry)
        }
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_CircuitsContingencyEntry () {
      var dpEntries = []

      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsBroadband())
      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsWanConnectivity())
      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsHostingAndCabling())
      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsFixedLineVoice())
      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_CircuitsEquinixCloudConnect())

      var recurringCircuitDpEntries = dpEntries.filter(x => x.dpSectionDescription === 'Circuit Recurring' && x.quantity > 0)
      var totalRecurringCircuitsCost = recurringCircuitDpEntries.reduce((accumulator, dataEntry) => accumulator + (dataEntry.unitARExternalCost * dataEntry.quantity), 0)

      var entries = []

      if (totalRecurringCircuitsCost !== 0) {
        var entry = {
          description: 'Circuit Contingency',
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'Circuit Contingency',
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Circuits - Contingency',
          dpSectionDescription: 'Circuit Contingency',
          dpSectionSalesforceCoeCodeId: this.iQuoteConstants.salesforceCoeCodes.NS_UPFRONT,
          designPackTableSectionId: 0,
          dpSectionSortOrder: 501,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: (totalRecurringCircuitsCost / 365) * 14,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Tabs',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: false,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: false,
          canBePaused: true,
          sortOrder: '06-Circuits Contingency'
        }

        entries.push(entry)
      }

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_SupportContingencyEntry () {
      var designPack = this.iQuoteStore_GetDesignPack

      if (designPack.disableSupportContingency) {
        return []
      }

      var dpEntries = []

      // First get a list of all entries that could contain the codes we are looking for
      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_NewManagedService())
      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_ManagedService())
      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_BOMs())
      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_Twilio())
      dpEntries = dpEntries.concat(this.iQuoteCalculationsDesignPackDetailDataMixin_IconSecure())

      var recurringSupportDpEntries = dpEntries.filter(x => (x.dpSectionSalesforceCoeCodeId === this.iQuoteConstants.salesforceCoeCodes.NEW_VENDOR_SUPPORT ||
                                        x.dpSectionSalesforceCoeCodeId === this.iQuoteConstants.salesforceCoeCodes.NEW_THIRD_PARTY_SUPPORT ||
                                        x.dpSectionSalesforceCoeCodeId === this.iQuoteConstants.salesforceCoeCodes.NEW_VENDOR_SUPPORT_SA) &&
                                        x.quantity > 0 && x.includeInCalculations === true)

      console.log('foo', 'recurringSupportDpEntries', recurringSupportDpEntries)

      var totalRecurringSupportCost = recurringSupportDpEntries.reduce((accumulator, dataEntry) => accumulator + (dataEntry.unitARExternalCost * dataEntry.quantity), 0)

      var entries = []

      if (totalRecurringSupportCost !== 0) {
        var entry = {
          description: 'Support Contingency',
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'Support Contingency',
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Support - Contingency',
          dpSectionDescription: 'Support Contingency',
          dpSectionSalesforceCoeCodeId: this.iQuoteConstants.salesforceCoeCodes.NS_UPFRONT,
          designPackTableSectionId: 0,
          dpSectionSortOrder: 100000,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: (totalRecurringSupportCost / 4),
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Options',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: false,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: false,
          canBePaused: false,
          sortOrder: '998-Support Contingency'
        }

        entries.push(entry)
      }

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_PSOptions () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription = 'Professional Services (Tab) - Internal'
      var dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.PROFESSIONAL_SERVICES
      var designPackTableSectionId = this.iQuoteConstants.designPackTableSections.PROFESSIONAL_SERVICES

      var dpSectionDiscounts = designPack.discounts.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

      if (dpSectionDiscounts === undefined) {
        dpSectionDiscounts = { upfrontInternalDiscount: 0 }
      }

      designPack.psOptionsEntries.filter(x => !x.disabled && x.units !== 0 && x.designPackPsScheduleId !== this.iQuoteConstants.designPackPsSchedules.NONE).forEach(psOptionsEntry => {
        var cost = this.iQuoteStore_getPsProfileScheduleCost(psOptionsEntry.designPackPsRoleProfileId, psOptionsEntry.designPackPsScheduleId)

        var entry = {
          description: this.iQuoteCalculationsDesignPackMixin_getPsScheduleQuoteDescription(psOptionsEntry),
          quoteDescription: this.iQuoteCalculationsDesignPackMixin_getPsScheduleQuoteDescription(psOptionsEntry),
          quantity: psOptionsEntry.units,
          quoteRef: '',
          supplier: '',
          code: this.iQuoteStore_getPsProfileScheduleCode(psOptionsEntry.designPackPsRoleProfileId, psOptionsEntry.designPackPsScheduleId),
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Professional Services',
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: 0,
          unitOOInternalCost: cost,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(cost * 2 * ((100 - dpSectionDiscounts.upfrontInternalDiscount) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Tabs',
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: true,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: true,
          priceOverrideAllowed: true,
          usesGlobalMargins: false,
          canBePaused: false,
          sortOrder: '03-PS Options-' + psOptionsEntry.id.toString().padStart(10, '0'),
          itemId: 'PSOptions.' + psOptionsEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: psOptionsEntry.priceOverride,
          unitARPriceOverride: null
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_PSExpenses () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription = 'Professional Services (Tab) - Expenses'
      var dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.THIRD_PARTY_PRO_SERVICES
      var designPackTableSectionId = 0

      var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

      if (dpSectionMargins === undefined) {
        dpSectionMargins = { upfrontExternalMargin: 20 }
      }

      designPack.psExpenseEntries.filter(x => !x.disabled && x.units !== 0).forEach(psExpenseEntry => {
        var entry = {
          description: psExpenseEntry.item,
          quoteDescription: null,
          quantity: psExpenseEntry.units,
          quoteRef: '',
          supplier: '',
          code: 'Expenses',
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Expenses',
          dpSectionDescription: 'Professional Services (Tab) - Expenses',
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: psExpenseEntry.unitCost,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(psExpenseEntry.unitCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Tabs',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: true,
          canBePaused: false,
          sortOrder: '02-PS Expense-' + psExpenseEntry.id.toString().padStart(10, '0'),
          itemId: 'PSExpenses.' + psExpenseEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: psExpenseEntry.priceOverride,
          unitARPriceOverride: null
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_PSThirdPartyInstallation () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription = 'Professional Services (Tab) - External Installation'
      var dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.THIRD_PARTY_PRO_SERVICES
      var designPackTableSectionId = this.iQuoteConstants.designPackTableSections.THIRD_PARTY_PRO_SERVICES_INSTALLATION
      var designPackTableSection = this.iQuoteStore_GetDesignPackTableSections.find(x => x.id === designPackTableSectionId)

      var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

      if (dpSectionMargins === undefined) {
        dpSectionMargins = {
          upfrontExternalMargin: designPackTableSection.upfrontExternalDefaultMargin,
          upfrontInternalMargin: designPackTableSection.upfrontInternalDefaultMargin,
          recurringExternalMargin: designPackTableSection.recurringExternalDefaultMargin,
          recurringInternalMargin: designPackTableSection.recurringInternalDefaultMargin
        }
      }

      designPack.psThirdPartyInstallationEntries.filter(x => !x.disabled && x.units !== 0).forEach(psThirdPartyInstallationEntry => {
        var entry = {
          description: psThirdPartyInstallationEntry.item,
          quoteDescription: null,
          quantity: psThirdPartyInstallationEntry.units,
          quoteRef: '',
          supplier: '',
          code: psThirdPartyInstallationEntry.item,
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Third Party Professional Services',
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: psThirdPartyInstallationEntry.unitCost,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(psThirdPartyInstallationEntry.unitCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Tabs',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: true,
          canBePaused: false,
          sortOrder: '04-PS Third Party Installation-' + psThirdPartyInstallationEntry.id.toString().padStart(10, '0'),
          itemId: 'PSThirdPartyInstallation.' + psThirdPartyInstallationEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: psThirdPartyInstallationEntry.priceOverride,
          unitARPriceOverride: null
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_PSThirdPartyOther () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription = 'Professional Services (Tab) - External Other'
      var dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.THIRD_PARTY_PRO_SERVICES
      var designPackTableSectionId = this.iQuoteConstants.designPackTableSections.THIRD_PARTY_PRO_SERVICES_OTHER
      var designPackTableSection = this.iQuoteStore_GetDesignPackTableSections.find(x => x.id === designPackTableSectionId)

      var dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

      if (dpSectionMargins === undefined) {
        dpSectionMargins = {
          upfrontExternalMargin: designPackTableSection.upfrontExternalDefaultMargin,
          upfrontInternalMargin: designPackTableSection.upfrontInternalDefaultMargin,
          recurringExternalMargin: designPackTableSection.recurringExternalDefaultMargin,
          recurringInternalMargin: designPackTableSection.recurringInternalDefaultMargin
        }
      }

      designPack.psThirdPartyOtherEntries.filter(x => !x.disabled && x.units !== 0).forEach(psThirdPartyOtherEntry => {
        var entry = {
          description: psThirdPartyOtherEntry.item,
          quoteDescription: null,
          quantity: psThirdPartyOtherEntry.units,
          quoteRef: '',
          supplier: '',
          code: psThirdPartyOtherEntry.item,
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Third Party Other',
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: psThirdPartyOtherEntry.unitCost,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(psThirdPartyOtherEntry.unitCost / ((100 - dpSectionMargins.upfrontExternalMargin) / 100)),
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Tabs',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: true,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: true,
          usesGlobalMargins: true,
          canBePaused: false,
          sortOrder: '05-PS Third Party Other-' + psThirdPartyOtherEntry.id.toString().padStart(10, '0'),
          itemId: 'PSThirdPartyOther.' + psThirdPartyOtherEntry.designPackItemLookupId.toString(),
          unitOOPriceOverride: psThirdPartyOtherEntry.priceOverride,
          unitARPriceOverride: null
        }

        entries.push(entry)
      })

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_PCTime () {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      var dpSectionDescription = 'Order Co-ordination'
      var dpSectionSalesforceCoeCodeId = 14 /* Professional Services */
      var designPackTableSectionId = this.iQuoteConstants.designPackTableSections.PROFESSIONAL_SERVICES

      var dpSectionDiscounts = designPack.discounts.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

      if (dpSectionDiscounts === undefined) {
        dpSectionDiscounts = { upfrontInternalDiscount: 0 }
      }

      if (designPack.addPCTime) {
        var cost = this.iQuoteStore_getPsProfileScheduleCost(this.iQuoteConstants.designPackPsRoleProfiles.PROJECT_COORDINATOR, this.iQuoteConstants.designPackPsSchedules.DAY) * 0.25

        var entry = {
          description: 'Order Co-ordination Charge',
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: 'Labour - Project Coordinator (0.25 Day)',
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Admin',
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          dpSectionSortOrder: 100000,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: 0,
          unitOOInternalCost: cost,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: this.iQuoteFunctions_RoundToTwoDecimalPlaces(cost * 2 * ((100 - dpSectionDiscounts.upfrontInternalDiscount) / 100)),
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'Design Pack Options',
          costTypeId: this.iQuoteConstants.costTypes.internal,
          visibleToCustomer: true,
          showOnExportATCodesList: true,
          includeInCalculations: true,
          canBeDiscounted: true,
          priceOverrideAllowed: true,
          usesGlobalMargins: false,
          canBePaused: true,
          sortOrder: '997',
          itemId: 'AddProjectCoordination',
          unitOOPriceOverride: designPack.priceOverrideOrderCoordination
        }

        entries.push(entry)
      }

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_FrameworkCharges (detailedDataEntries) {
      var entries = []
      var designPack = this.iQuoteStore_GetDesignPack

      if (this.iQuoteStore_GetDesignPack.contractFrameworkId === this.iQuoteConstants.designPackContractFrameworkIds.NONE) {
        return entries
      }

      var contractFramework = this.iQuoteCalculationsDesignPackMixin.contractFrameworks.find(x => x.id === this.iQuoteStore_GetDesignPack.contractFrameworkId)

      if (contractFramework === undefined) {
        this.iQuoteFunctions_ShowError('Could not find contract framework' + designPack.contractFrameworkId)
        return entries
      }

      var dpSectionDescription = contractFramework.name + ' upfront management charge'
      var dpSectionSalesforceCoeCodeId = this.iQuoteConstants.salesforceCoeCodes.TECHNOLOGY

      var enabledDetailedDataEntries = detailedDataEntries.filter(x => x.paused === false &&
        (x.includeInCalculations || x.visibleToCustomer))
      var totalUpfrontPrice = enabledDetailedDataEntries.reduce((accumulator, dataEntry) => accumulator + (dataEntry.unitOOPriceOverride !== null ? dataEntry.unitOOPriceOverride * Math.abs(dataEntry.quantity) : dataEntry.totalExternalUpfrontPrice + dataEntry.totalInternalUpfrontPrice), 0)
      var totalRecurringPrice = enabledDetailedDataEntries.reduce((accumulator, dataEntry) => accumulator + (dataEntry.unitARPriceOverride !== null ? dataEntry.unitARPriceOverride * Math.abs(dataEntry.quantity) : dataEntry.totalExternalAnnualPrice + dataEntry.totalInternalAnnualPrice), 0)

      if (totalUpfrontPrice > 0) {
        var upfrontFrameworkCharge = Number((totalUpfrontPrice * (contractFramework.managementCharge / 100)).toFixed(2))

        var upfrontEntry = {
          description: dpSectionDescription,
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: dpSectionDescription,
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: false,
          categoryName: 'Additional Charges',
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: 0,
          dpSectionSortOrder: 9999,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: upfrontFrameworkCharge,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          totalInternalAnnualPrice: 0,
          totalExternalAnnualPrice: 0,
          totalInternalUpfrontPrice: 0,
          totalExternalUpfrontPrice: 0,
          totalInternalAnnualCost: 0,
          totalExternalAnnualCost: 0,
          totalInternalUpfrontCost: 0,
          totalExternalUpfrontCost: upfrontFrameworkCharge,
          totalInternalAnnualHiddenCost: 0,
          totalExternalAnnualHiddenCost: 0,
          totalInternalUpfrontHiddenCost: 0,
          totalExternalUpfrontHiddenCost: 0,
          totalInternalAnnualQuoteOnlyCost: 0,
          totalExternalAnnualQuoteOnlyCost: 0,
          totalInternalUpfrontQuoteOnlyCost: 0,
          totalExternalUpfrontQuoteOnlyCost: 0,
          dpSource: 'Additional Charges',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: false,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: false,
          canBePaused: false,
          sortOrder: '998',
          itemId: 'FrameworkCharge',
          unitOOPriceOverride: null,
          unitARPriceOverride: null
        }

        // Check if it's paused at the summary level
        upfrontEntry.paused = designPack.disabledEntries.findIndex(x => x.source === upfrontEntry.dpSource &&
                                                            x.description === upfrontEntry.dpSectionDescription &&
                                                            x.salesforceCoeCodeId === upfrontEntry.dpSectionSalesforceCoeCodeId
        ) !== -1

        entries.push(upfrontEntry)
      }

      if (totalRecurringPrice > 0) {
        dpSectionDescription = contractFramework.name + ' recurring management charge'
        var recurringFrameworkCharge = Number((totalRecurringPrice * (contractFramework.managementCharge / 100)).toFixed(2))

        var dpSections = enabledDetailedDataEntries.map(x => ({ salesforceCoeCodeId: x.dpSectionSalesforceCoeCodeId, description: x.dpSectionDescription }))
        var dpUniqueDpSections = dpSections.filter((value, index, searchedArray) => searchedArray.findIndex((x) =>
          (x.salesforceCoeCodeId === value.salesforceCoeCodeId && x.description === value.description)) === index && value.salesforceCoeCodeId !== null)

        // Ticket 3506 - The salesforce code used by the recurring element should be the code
        // used on the highest value entry in the list
        var sfCoeOfEntryWithMaxValue = enabledDetailedDataEntries[0].dpSectionSalesforceCoeCodeId
        var maxRecurringSellPrice = 0

        dpUniqueDpSections.forEach(dpSection => {
          var entriesWithinDpSection = enabledDetailedDataEntries.filter(x =>
            x.dpSectionDescription === dpSection.description &&
            x.dpSectionSalesforceCoeCodeId === dpSection.salesforceCoeCodeId)

          var totalRecurringPriceWithinSection = entriesWithinDpSection.reduce((accumulator, dataEntry) => accumulator + (dataEntry.unitARPriceOverride !== null ? dataEntry.unitARPriceOverride * Math.abs(dataEntry.quantity) : dataEntry.totalExternalAnnualPrice + dataEntry.totalInternalAnnualPrice), 0)

          if (totalRecurringPriceWithinSection > maxRecurringSellPrice) {
            sfCoeOfEntryWithMaxValue = dpSection.salesforceCoeCodeId
            maxRecurringSellPrice = totalRecurringPriceWithinSection
          }
        })

        var recurringEntry = {
          description: dpSectionDescription,
          quoteDescription: null,
          quantity: 1,
          quoteRef: '',
          supplier: '',
          code: dpSectionDescription,
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: true,
          categoryName: 'Additional Charges',
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: sfCoeOfEntryWithMaxValue,
          designPackTableSectionId: 0,
          dpSectionSortOrder: 9999,
          unitARExternalCost: recurringFrameworkCharge,
          unitARInternalCost: 0,
          unitOOExternalCost: 0,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          totalInternalAnnualPrice: 0,
          totalExternalAnnualPrice: 0,
          totalInternalUpfrontPrice: 0,
          totalExternalUpfrontPrice: 0,
          totalInternalAnnualCost: 0,
          totalExternalAnnualCost: recurringFrameworkCharge,
          totalInternalUpfrontCost: 0,
          totalExternalUpfrontCost: 0,
          totalInternalAnnualHiddenCost: 0,
          totalExternalAnnualHiddenCost: 0,
          totalInternalUpfrontHiddenCost: 0,
          totalExternalUpfrontHiddenCost: 0,
          totalInternalAnnualQuoteOnlyCost: 0,
          totalExternalAnnualQuoteOnlyCost: 0,
          totalInternalUpfrontQuoteOnlyCost: 0,
          totalExternalUpfrontQuoteOnlyCost: 0,
          dpSource: 'Additional Charges',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: false,
          showOnExportATCodesList: false,
          includeInCalculations: true,
          canBeDiscounted: false,
          priceOverrideAllowed: false,
          usesGlobalMargins: false,
          canBePaused: false,
          sortOrder: '999',
          itemId: 'FrameworkCharge',
          unitARPriceOverride: null,
          unitOOPriceOverride: null
        }

        // Check if it's paused at the summary level
        recurringEntry.paused = designPack.disabledEntries.findIndex(x => x.source === recurringEntry.dpSource &&
          x.description === recurringEntry.dpSectionDescription &&
          x.salesforceCoeCodeId === recurringEntry.dpSectionSalesforceCoeCodeId
        ) !== -1

        entries.push(recurringEntry)
      }

      return entries
    },
    iQuoteCalculationsDesignPackDetailDataMixin_Licenses () {
      var entries = []
      var quoteLicenses = this.iQuoteStore_GetQuoteLicensesRequired
      var designPackTableSections = this.iQuoteStore_GetDesignPackTableSections
      var designPack = this.iQuoteStore_GetDesignPack
      var services = this.iQuoteStore_GetServices
      var designPackTableSection
      var service

      quoteLicenses.forEach(license => {
        designPackTableSection = designPackTableSections.find(x => x.id === license.designPackTableSectionId)
        service = services.find(x => x.id === license.serviceId)
        var dpSectionMargins
        var dpSectionDiscounts
        var dpSectionDescription = null
        var dpSectionSalesforceCoeCodeId = null
        var designPackTableSectionId = 0

        if (designPackTableSection !== undefined) {
          dpSectionDescription = designPackTableSection.description
          dpSectionSalesforceCoeCodeId = designPackTableSection.salesforceCoeCodeId
          designPackTableSectionId = designPackTableSection.id
        }

        dpSectionMargins = designPack.margins.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)
        dpSectionDiscounts = designPack.discounts.find(x => x.description === dpSectionDescription && x.salesforceCoeCodeId === dpSectionSalesforceCoeCodeId)

        var entry = {
          description: license.description,
          quoteDescription: license.quoteDescription,
          quantity: license.quantity,
          quoteRef: '',
          supplier: '',
          code: license.code,
          sellTermMonths: 0,
          costTermMonths: 0,
          recurringCost: (license.chargeIntervalId !== this.iQuoteConstants.chargeInterval.ONE_OFF),
          categoryName: service.name,
          dpSectionDescription: dpSectionDescription,
          dpSectionSalesforceCoeCodeId: dpSectionSalesforceCoeCodeId,
          designPackTableSectionId: designPackTableSectionId,
          unitARExternalCost: 0,
          unitARInternalCost: 0,
          unitOOExternalCost: 0,
          unitOOInternalCost: 0,
          unitARExternalPrice: 0,
          unitARInternalPrice: 0,
          unitOOExternalPrice: 0,
          unitOOInternalPrice: 0,
          unitMRExternalCost: 0,
          unitMRInternalCost: 0,
          unitMRExternalPrice: 0,
          unitMRInternalPrice: 0,
          dpSource: 'iQuote',
          costTypeId: this.iQuoteConstants.costTypes.external,
          visibleToCustomer: license.visibleToCustomer,
          showOnExportATCodesList: license.showOnExportATCodesList,
          includeInCalculations: license.includeInCalculations,
          canBeDiscounted: designPackTableSection === undefined ? false : designPackTableSection.canBeDiscounted,
          priceOverrideAllowed: true,
          usesGlobalMargins: designPackTableSection === undefined ? false : !designPackTableSection.precalculatedPricing,
          canBePaused: true,
          sortOrder: '80-Licenses-' + license.id.toString().padStart(10, '0'),
          itemId: 'Licenses.' + license.licenseId.toString(),
          unitOOPriceOverride: (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.ONE_OFF) ? license.priceOverride : null,
          unitARPriceOverride: (license.chargeIntervalId !== this.iQuoteConstants.chargeInterval.ONE_OFF) ? license.priceOverride : null
        }

        if (license.visibleToCustomer) {
          // Licenses that are shown on quote, need to work out the prices

          if (license.chargeIntervalId !== this.iQuoteConstants.chargeInterval.ONE_OFF) {
            entry.unitARExternalPrice = this.calculateLicensePrice(license, designPackTableSection, dpSectionMargins, dpSectionDiscounts)
            entry.unitARExternalRRP = this.calculateLicenseRRP(license)
          } else {
            entry.unitOOExternalPrice = this.calculateLicensePrice(license, designPackTableSection, dpSectionMargins, dpSectionDiscounts)
            entry.unitOOExternalRRP = this.calculateLicenseRRP(license)
          }
        }

        if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
          entry.unitARExternalCost += license.cost * 12
        } else if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.YEARLY) {
          entry.unitARExternalCost += license.cost
        } else if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.ONE_OFF) {
          entry.unitOOExternalCost += license.cost
        }

        entries.push(entry)
      })

      return entries
    },
    calculateUnitCostPrice (unitCost, designPackTableSection, dpSectionMargins, dpSectionDiscounts) {
      var applicableMargin = null
      var applicableDiscount = null

      //
      // Work out which discount or margin applies to this unitcost
      //

      if (unitCost.costTypeId === this.iQuoteConstants.costTypes.internal) {
        if (unitCost.recurringCost) {
          if (dpSectionMargins !== undefined) {
            applicableMargin = dpSectionMargins.recurringInternalMargin
          }

          if (dpSectionDiscounts !== undefined) {
            applicableDiscount = dpSectionDiscounts.recurringInternalDiscount
          }
        } else {
          if (dpSectionMargins !== undefined) {
            applicableMargin = dpSectionMargins.upfrontInternalMargin
          }

          if (dpSectionDiscounts !== undefined) {
            applicableDiscount = dpSectionDiscounts.upfrontInternalDiscount
          }
        }
      } else {
        if (unitCost.recurringCost) {
          if (dpSectionMargins !== undefined) {
            applicableMargin = dpSectionMargins.recurringExternalMargin
          }

          if (dpSectionDiscounts !== undefined) {
            applicableDiscount = dpSectionDiscounts.recurringExternalDiscount
          }
        } else {
          if (dpSectionMargins !== undefined) {
            applicableMargin = dpSectionMargins.upfrontExternalMargin
          }

          if (dpSectionDiscounts !== undefined) {
            applicableDiscount = dpSectionDiscounts.upfrontExternalDiscount
          }
        }
      }

      //
      // Work out which discounts and margins apply to this unitcost
      //

      var unitCostPrice

      if (!unitCost.recurringCost) {
        if (applicableMargin !== null) {
          unitCostPrice = unitCost.cost / ((100 - applicableMargin) / 100)
        } else if (applicableDiscount !== null) {
          unitCostPrice = unitCost.price * ((100 - applicableDiscount) / 100)
        } else {
          unitCostPrice = unitCost.price
        }
      } else {
        if (unitCost.paymentsPerYear === -1) {
          // Spread the charge over the termlength
          if (applicableMargin !== null) {
            unitCostPrice = (unitCost.cost * unitCost.paymentsPerYear) / ((100 - applicableMargin) / 100) / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
          } else if (applicableDiscount !== null) {
            unitCostPrice = (unitCost.price * unitCost.paymentsPerYear) * ((100 - applicableDiscount) / 100) / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
          } else {
            unitCostPrice = unitCost.price * unitCost.paymentsPerYear / (this.iQuoteStore_GetQuote.termLengthMonths / 12)
          }
        } else {
          // This is charged annually
          if (applicableMargin !== null) {
            unitCostPrice = (unitCost.cost * unitCost.paymentsPerYear) / ((100 - applicableMargin) / 100)
          } else if (applicableDiscount !== null) {
            unitCostPrice = (unitCost.price * unitCost.paymentsPerYear) * ((100 - applicableDiscount) / 100)
          } else {
            unitCostPrice = unitCost.price * unitCost.paymentsPerYear
          }
        }
      }

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(unitCostPrice)
    },
    calculateLicensePrice (license, designPackTableSection, dpSectionMargins, dpSectionDiscounts) {
      var applicableMargin = null
      var applicableDiscount = null

      //
      // Work out which discount or margin applies to this license
      //

      if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY ||
          license.chargeIntervalId === this.iQuoteConstants.chargeInterval.YEARLY) {
        if (dpSectionMargins !== undefined) {
          applicableMargin = dpSectionMargins.recurringExternalMargin
        }

        if (dpSectionDiscounts !== undefined) {
          applicableDiscount = dpSectionDiscounts.recurringExternalDiscount
        }
      } else if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.ONE_OFF) {
        if (dpSectionMargins !== undefined) {
          applicableMargin = dpSectionMargins.upfrontExternalMargin
        }

        if (dpSectionDiscounts !== undefined) {
          applicableDiscount = dpSectionDiscounts.upfrontExternalDiscount
        }
      }

      //
      // Work out which discounts and margins apply to this license
      //

      var licensePrice

      if (applicableMargin !== null) {
        licensePrice = license.cost / ((100 - applicableMargin) / 100)
      } else if (applicableDiscount !== null) {
        licensePrice = license.price * ((100 - applicableDiscount) / 100)
      } else {
        licensePrice = license.price
      }

      if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
        licensePrice = this.iQuoteFunctions_RoundToTwoDecimalPlaces(licensePrice) * 12
      }

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(licensePrice)
    },
    calculateLicenseRRP (license) {
      var licensePrice = license.price
      var designPackTableSections = this.iQuoteStore_GetDesignPackTableSections
      var designPackTableSection = designPackTableSections.find(x => x.id === license.designPackTableSectionId)

      if (designPackTableSection === undefined || designPackTableSection.precalculatedPricing === false) {
        return undefined
      }

      if (license.chargeIntervalId === this.iQuoteConstants.chargeInterval.MONTHLY) {
        licensePrice *= 12
      }

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(licensePrice)
    },
    iQuoteCalculationsDesignPackDetailDataMixin_getTempMarginId () {
      var designPack = this.iQuoteStore_GetDesignPack

      /* items that are not saved to the db yet will have negative ids */
      var minId = -1
      var arr = designPack.margins

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    iQuoteCalculationsDesignPackDetailDataMixin_getTempDiscountId () {
      var designPack = this.iQuoteStore_GetDesignPack

      /* items that are not saved to the db yet will have negative ids */
      var minId = -1
      var arr = designPack.discounts

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    }
  }
}
