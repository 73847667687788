<template>
  <div id="mainContainer">
    <pagination
      :index="paginationComponentIndex"
      :page-length="pageLength"
      :margin-top="-5"
      :margin-bottom="3"
      :has-replica-in-the-same-page="hasReplicaInTheSamePage"
      @pageChanged="pageChanged"
      @firstClicked="firstClicked"
      @prevClicked="prevClicked"
      @nextClicked="nextClicked"
      @lastClicked="lastClicked"
      @searchText="searchText"
    />
    <table
      id="mainTable"
      class="iquoteDesignpackTable noTableShading">
      <tr class="mainHeader">
        <th colspan="12">&nbsp;</th>
        <td class="noborder">&nbsp;</td>
        <th
          colspan="2"
          style="width: 200px"
        >
          Required Margin</th>
      </tr>
      <tr class="mainHeader">
        <th colspan="3">Site</th>
        <th colspan="3">Hardware</th>
        <th colspan="5">Pricing</th>
        <th>&nbsp;</th>
        <td class="noborder">&nbsp;</td>
        <th
          colspan="2"
          style="width: 200px"
        >
          <div
            v-if="!isReadOnly"
            class="verticalAlignTop"
          >
            <input
              id="chkOverrideMargin"
              v-model.number="hardwareMainTableEditorComponent.overrideMargin"
              type="checkbox"
            >
            <input
              v-show="hardwareMainTableEditorComponent.overrideMargin"
              id="txtMarginOverride"
              v-model.number="iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack.marginHardwareMainPercent"
              :class="{invalidInput: (iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack.marginHardwareMainPercent < 0 || iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack.marginHardwareMainPercent >= 100 || iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack.marginHardwareMainPercent < designPackTableSection_Tech.upfrontExternalIquoteUserMinMargin)}"
              :disabled="isReadOnly"
              :min="designPackTableSection_Tech.upfrontExternalIquoteUserMinMargin"
              type="number"
              step="any"
              class="marginInput marginOverrideTextboxInTableHeader"
              max="99"
              style="width: 50px"
              @change="handleMarginChange($event)">
            <span v-if="!hardwareMainTableEditorComponent.overrideMargin">Override Margin</span>
            <span v-if="hardwareMainTableEditorComponent.overrideMargin">%</span>
          </div>
        </th>
      </tr>
      <tr class="subHeader">
        <th>Site No.</th>
        <th>Location</th>
        <th>Postcode</th>
        <th>Description</th>
        <th>Part Number</th>
        <th>Vendor</th>
        <th>Quantity</th>
        <th>Unit Cost</th>
        <th>Currency</th>
        <th>UnitCostGBP</th>
        <th>TotalCostGBP</th>
        <th>&nbsp;</th>
        <td class="noborder">&nbsp;</td>
        <th>Margin</th>
        <th>Sell Price</th>
      </tr>
      <tbody
        v-for="hardwareMainEntry in hardwareMainTableEditorComponent.currentPageEntries"
        :key="hardwareMainEntry.id"
        :class="{ opaque: !hardwareMainTableEditorComponent.showData }">
        <tr
          :class="{disabled: hardwareMainEntry.disabled}"
        >
          <td>
            <input
              :id="'siteNoInput' + hardwareMainEntry.id"
              v-model="hardwareMainEntry.siteNo"
              :disabled="isReadOnly"
              class="siteNoInput"
              type="text"
              @blur="onInputItemFocusLost(hardwareMainEntry, $event)"
              @change="sendChangesToParent()">
          </td>
          <td>
            <input
              :id="'locationInput' + hardwareMainEntry.id"
              v-model="hardwareMainEntry.location"
              :disabled="isReadOnly"
              class="locationInput"
              type="text"
              @blur="onInputItemFocusLost(hardwareMainEntry, $event)"
              @change="sendChangesToParent()">
          </td>
          <td>
            <input
              :id="'postcodeInput' + hardwareMainEntry.id"
              v-model="hardwareMainEntry.postcode"
              :disabled="isReadOnly"
              class="postcodeInput"
              type="text"
              @blur="onInputItemFocusLost(hardwareMainEntry, $event)"
              @change="sendChangesToParent()">
          </td>
          <td>
            <input
              :id="'descriptionInput' + hardwareMainEntry.id"
              v-model="hardwareMainEntry.description"
              :disabled="isReadOnly"
              class="descriptionInput"
              type="text"
              @blur="onInputItemFocusLost(hardwareMainEntry, $event)"
              @change="sendChangesToParent()">
          </td>
          <td>
            <input
              :id="'partNumberInput' + hardwareMainEntry.id"
              v-model="hardwareMainEntry.partNumber"
              :disabled="isReadOnly"
              class="partNumberInput"
              type="text"
              @blur="onInputItemFocusLost(hardwareMainEntry, $event)"
              @change="sendChangesToParent()">
          </td>
          <td>
            <select
              v-if="!isBlankEntry(hardwareMainEntry)"
              :id="'vendorInput' + hardwareMainEntry.id"
              :class="{invalidInput: hardwareMainEntry.designPackHardwareVendorId === 0}"
              :disabled="isReadOnly"
              v-model="hardwareMainEntry.designPackHardwareVendorId"
              class="vendorInput"
              @blur="onInputItemFocusLost(hardwareMainEntry, $event)"
              @change="sendChangesToParent()"
            >
              <option
                v-if="hardwareMainEntry.designPackHardwareVendorId === 0"
                key="0"
                value="0"
                title="< Please Select >"
              >&lt; Please Select &gt;
              </option>
              <option
                v-for="t in iQuoteStore_GetHardwareTableLookups.vendors"
                :key="t.id"
                :value="t.id"
                :title="t.description"
              >{{ t.description }}
              </option>
            </select>
          </td>
          <td>
            <input
              v-if="!isBlankEntry(hardwareMainEntry)"
              :id="'quantityInput' + hardwareMainEntry.id"
              v-model.number="hardwareMainEntry.quantity"
              :class="{invalidInput: hardwareMainEntry.quantity === 0}"
              :disabled="isReadOnly"
              type="number"
              class="quantityInput"
              @change="sendChangesToParent()">
          </td>
          <td>
            <input
              v-if="!isBlankEntry(hardwareMainEntry)"
              :id="'unitCostInput' + hardwareMainEntry.id"
              v-model.number="hardwareMainEntry.unitCost"
              :class="{invalidInput: hardwareMainEntry.unitCost <= 0}"
              :disabled="isReadOnly"
              type="number"
              class="unitCostInput"
              @change="sendChangesToParent()">
          </td>
          <td>
            <select
              v-if="!isBlankEntry(hardwareMainEntry)"
              :id="'currencyInput' + hardwareMainEntry.id"
              :class="{invalidInput: hardwareMainEntry.designPackHardwareCurrencyId === 0}"
              :disabled="isReadOnly"
              v-model="hardwareMainEntry.designPackHardwareCurrencyId"
              class="currencyInput"
              @change="sendChangesToParent()"
            >
              <option
                v-if="hardwareMainEntry.designPackHardwareCurrencyId === 0"
                key="0"
                value="0"
                title="< Please Select >"
              >...
              </option>
              <option
                v-for="t in iQuoteCalculationsDesignPackMixin.hardwareCurrencies"
                :key="t.id"
                :value="t.id"
                :title="t.description"
              >{{ t.description }}
              </option>
            </select>
          </td>
          <td class="readOnlyCell"><span v-if="!isBlankEntry(hardwareMainEntry) && iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryUnitCostGBP(hardwareMainEntry) !== -1">£{{ iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryUnitCostGBP(hardwareMainEntry).toFixed(2) }}</span></td>
          <td class="readOnlyCell"><span v-if="!isBlankEntry(hardwareMainEntry) && iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryUnitCostGBP(hardwareMainEntry) !== -1 && iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitCostGBP (hardwareMainEntry) !== -1">£{{ iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitCostGBP (hardwareMainEntry).toFixed(2) }}</span></td>
          <td>
            <div class="commandButtons">
              <button
                v-if="!isBlankEntry(hardwareMainEntry) && !isReadOnly && !hardwareMainEntry.disabled"
                :id="'btnDisable' + hardwareMainEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Disable this entry so that the costs and prices are NOT INCLUDED in the design pack and quote"
                @click="disableEntry(hardwareMainEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconPause"
                  alt="remove">
              </button>
              <button
                v-if="!isBlankEntry(hardwareMainEntry) && !isReadOnly && hardwareMainEntry.disabled"
                :id="'btnDisable' + hardwareMainEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Enable this entry so that the costs and prices are included in the design pack and quote"
                @click="enableEntry(hardwareMainEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconResume"
                  alt="insert">
              </button>
              <button
                v-if="!isBlankEntry(hardwareMainEntry) && !isReadOnly && hardwareMainEntry.quantity !== 0"
                :id="'btnNegate' + hardwareMainEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Invert the quantity of this entry.  If it's positive make it negative and vice-versa"
                @click="negateEntry(hardwareMainEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconPlusMinus"
                  alt="negate">
              </button>
              <button
                v-if="!isBlankEntry(hardwareMainEntry) && !isReadOnly"
                :id="'btnDelete' + hardwareMainEntry.id.toString()"
                type="button"
                class="iQuoteIconButton"
                title="Delete this entry"
                style="margin-left: 15px"
                @click="deleteEntry(hardwareMainEntry)"
              >
                <img
                  :src="iQuoteImagesMixin_iconDelete"
                  alt="delete">
              </button>
            </div>
          </td>
          <td class="noborder">&nbsp;</td>
          <td
            v-if="hardwareMainEntry.priceOverride === null"
          >
            <input
              v-if="!isBlankEntry(hardwareMainEntry)"
              :id="'unitCostInput' + hardwareMainEntry.id"
              v-model.number="hardwareMainEntry.marginPercent"
              :class="{invalidInput: hardwareMainEntry.marginPercent < 0 || hardwareMainEntry.marginPercent < designPackTableSection_Tech.upfrontExternalIquoteUserMinMargin}"
              :disabled="isReadOnly"
              :min="designPackTableSection_Tech.upfrontExternalIquoteUserMinMargin"
              type="number"
              step="any"
              class="marginInput"
              max="99"
              @change="sendChangesToParent()">
            <span v-if="!isBlankEntry(hardwareMainEntry)">%</span>
          </td>
          <td
            v-if="hardwareMainEntry.priceOverride === null"
            class="readOnlyCell">
            <span v-if="!isBlankEntry(hardwareMainEntry) && iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryUnitCostGBP(hardwareMainEntry) !== -1">£{{ iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitPriceGBP(hardwareMainEntry).toFixed(2) }}</span>
          </td>
          <td
            v-if="hardwareMainEntry.priceOverride !== null"
            class="readOnlyCell"
          >
            {{ calcMargin(hardwareMainEntry) }}
            <TooltipIcon
              width="200"
              align="right"
              text="Price was set on Detailed Summary Tab" />
          </td>
          <td
            v-if="hardwareMainEntry.priceOverride !== null"
            class="readOnlyCell"
          >
            {{ '£' + (hardwareMainEntry.priceOverride * hardwareMainEntry.quantity).toFixed(2) }}
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :index="paginationComponentIndex"
      :page-length="pageLength"
      :margin-top="15"
      :margin-bottom="70"
      :has-replica-in-the-same-page="hasReplicaInTheSamePage"
      @pageChanged="pageChanged"
      @firstClicked="firstClicked"
      @prevClicked="prevClicked"
      @nextClicked="nextClicked"
      @lastClicked="lastClicked"
      @searchText="searchText"
    />
  </div>
</template>

<script>

import { iQuoteStoreMixin } from '../../../mixins/iQuote/iQuoteStoreMixin'
import { iQuoteConstants } from '../../../mixins/iQuote/iQuoteConstants'
import { iQuoteFunctions } from '../../../mixins/iQuote/iQuoteFunctions'
import { iQuoteCalculationsDesignPackHardwareMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPackHardware'
import { iQuoteCalculationsDesignPackMixin } from '../../../mixins/iQuote/Calculations/iQuoteCalculationsDesignPack'
import { iQuoteImagesMixin } from '../../../mixins/iQuote/Globals/iQuoteImages'
import pagination from '../../common/pagination'
import TooltipIcon from '../../common/tooltipIcon'
import { DataEventBus } from '../../../events/dataEvents'

import { mapActions } from 'vuex'

export default {
  name: 'HardwareMainTableEditor',
  components: {
    pagination, TooltipIcon
  },
  mixins: [ iQuoteStoreMixin, iQuoteConstants, iQuoteFunctions, iQuoteCalculationsDesignPackHardwareMixin, iQuoteImagesMixin, iQuoteCalculationsDesignPackMixin ],
  props: {
  },
  data () {
    return {
      paginationComponentIndex: 2,
      hasReplicaInTheSamePage: true,
      maxNumberOfEntries: 2000,
      hardwareMainTableEditorComponent: {
        currentPage: 0,
        pageLength: 20,
        cachedEntries: [],
        currentPageEntries: [],
        filteredEntries: [],
        filter: '',
        uniqueId: 0,
        quoteDesignPackRevisionId: 0,
        overrideMargin: false,
        animatePageTransitions: true,
        showData: true
      }
    }
  },
  computed: {
    isReadOnly () {
      return this.iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack.isReadOnly
    },
    cachedEntries () {
      return this.hardwareMainTableEditorComponent.cachedEntries
    },
    filteredEntries () {
      return this.hardwareMainTableEditorComponent.filteredEntries
    },
    filter () {
      return this.hardwareMainTableEditorComponent.filter
    },
    hasFilter () {
      return this.hardwareMainTableEditorComponent.filter !== ''
    },
    currentPage () {
      return this.hardwareMainTableEditorComponent.currentPage
    },
    pageLength () {
      return this.hardwareMainTableEditorComponent.pageLength
    },
    totalPages () {
      let totalEntries = 0
      if (this.hasFilter) {
        totalEntries = this.filteredEntries
      } else {
        totalEntries = this.cachedEntries
      }
      return Math.ceil(totalEntries.length / this.pageLength)
    },
    designPackTableSection_Tech () {
      return this.iQuoteStore_GetDesignPackTableSections.find(x => x.id === this.iQuoteConstants.designPackTableSections.TECH)
    }
  },
  watch: {
    currentPage () {
      this.refreshCurrentPageData()
      this.refreshPaginationTab()
    },
    totalPages () {
      if (this.currentPage === this.totalPages) {
        this.hardwareMainTableEditorComponent.currentPage = this.currentPage - 1
      }
    },
    filter () {
      this.refreshCurrentPageData()
      this.refreshPaginationTab()
      if (this.totalPages > 0) {
        this.hardwareMainTableEditorComponent.currentPage = 0
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, 1)
      }
    }
  },
  mounted () {
    this.$store.watch(
      (state, getters) => getters.designPack,
      (newValue) => {
        this.initialise(newValue)
      }
    )
    this.initialise(this.iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack)
  },
  methods: {
    ...mapActions([
      'setDesignPack'
    ]),
    calcMargin (hardwareMainEntry) {
      var cost = this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitCostGBP(hardwareMainEntry)
      var price = this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitPriceGBP(hardwareMainEntry)

      if (hardwareMainEntry.priceOverride !== null) {
        price = hardwareMainEntry.priceOverride * hardwareMainEntry.quantity
      }

      var profit = price - cost
      var margin = (profit / price) * 100

      return this.iQuoteFunctions_RoundToTwoDecimalPlaces(margin) + '%'
    },
    initialise (designPack) {
      this.hardwareMainTableEditorComponent.quoteDesignPackRevisionId = designPack.quoteDesignPackRevisionId

      if (this.currentPage !== 0) this.hardwareMainTableEditorComponent.currentPage = 0
      this.initialiseData()
    },
    initialiseData () {
      this.refreshCurrentPageData()
      this.refreshPaginationTab()
      this.clearFilter()
    },
    refreshCurrentPageData () { // mount current page - triggered on page change, search and delete
      if (!this.iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack.hardwareMainEntries) return

      this.hardwareMainTableEditorComponent.uniqueId = 0
      this.hardwareMainTableEditorComponent.cachedEntries = this.iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack.hardwareMainEntries.map(array => ({ ...array, uniqueId: ++this.hardwareMainTableEditorComponent.uniqueId, selected: false }))

      const offset = this.currentPage * this.pageLength
      if (!this.hasFilter) {
        this.hardwareMainTableEditorComponent.currentPageEntries = this.cachedEntries.slice(offset, offset + this.pageLength)
      } else {
        this.hardwareMainTableEditorComponent.filteredEntries = []
        this.cachedEntries.forEach(element => {
          var foundOne = false
          for (var key in element) {
            if (key === 'id' || key === 'uniqueId' || key === 'disabled') continue
            // vendor lookup (dropdown):
            if (key === 'designPackHardwareVendorId') {
              if (this.searchInDescriptions(this.iQuoteStore_GetHardwareTableLookups.vendors, element.designPackHardwareVendorId, this.filter)) {
                foundOne = true
              }
            // currency lookup (dropdown):
            } else if (key === 'designPackHardwareCurrencyId') {
              if (this.searchInDescriptions(this.iQuoteCalculationsDesignPackMixin.hardwareCurrencies, element.designPackHardwareCurrencyId, this.filter)) {
                foundOne = true
              }
            // other
            } else if (element[key] && element[key].toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
              foundOne = true
            }
          }
          if (!foundOne) {
            foundOne = this.searchInCalculatedColumns(element)
          }

          if (foundOne) { this.hardwareMainTableEditorComponent.filteredEntries.push(element) }
        })
        this.hardwareMainTableEditorComponent.currentPageEntries = this.filteredEntries.slice(offset, offset + this.pageLength)
      }

      this.addBlankEntry()
    },
    searchInDescriptions (objectToSearch, id, filter) {
      // find item in dropdowns
      const item = objectToSearch.find((el) => el.id === id)
      if (item !== undefined && item.description.toLowerCase().indexOf(filter.toLowerCase()) > -1) {
        return true
      }

      return false
    },
    searchInCalculatedColumns (element) {
      // unit cost GBP lookup:
      if (this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryUnitCostGBP(element).toFixed(2).toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
        return true
      }
      // total unit cost GBP lookup:
      if (this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitCostGBP(element).toFixed(2).toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
        return true
      }
      // sell price lookup:
      if (this.iQuoteCalculationsDesignPackMixin_calcHardwareMainEntryTotalUnitPriceGBP(element).toFixed(2).toString().toLowerCase().indexOf(this.filter.toLowerCase()) > -1) {
        return true
      }
    },
    refreshPaginationTab () {
      let numberOfEntries
      if (this.hasFilter) {
        numberOfEntries = this.filteredEntries.length
      } else {
        numberOfEntries = this.cachedEntries.length
      }
      DataEventBus.$emit(`refreshTotalEntries${this.paginationComponentIndex}`, numberOfEntries)
    },
    clearFilter () {
      this.hardwareMainTableEditorComponent.filter = ''
      DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'filter', true, '')
    },
    onInputItemFocusLost (entry, event) {
      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }
    },
    handleMarginChange (event) {
      this.hardwareMainTableEditorComponent.currentPageEntries.forEach(entry => {
        entry.marginPercent = this.iQuoteCalculationsDesignPackHardwareMixin_selectedDesignPack.marginHardwareMainPercent
      })
      this.sendChangesToParent()
    },
    isBlankEntry (entry) {
      return entry.siteNo === '' &&
          entry.location === '' &&
          entry.postcode === '' &&
          entry.description === '' &&
          entry.partNumber === '' &&
          entry.designPackHardwareVendorId === 0 &&
          entry.quantity === 0 &&
          entry.unitCost === 0
    },
    hasBlankEntry () {
      var foundBlankEntry = false
      this.hardwareMainTableEditorComponent.currentPageEntries.forEach(entry => {
        if (this.isBlankEntry(entry)) {
          foundBlankEntry = true
        }
      })

      return foundBlankEntry
    },
    canAddBlankEntry () {
      let hasBlankEntry = false
      if (this.hardwareMainTableEditorComponent.currentPageEntries.length > 0) {
        const quantity = this.hardwareMainTableEditorComponent.currentPageEntries[this.hardwareMainTableEditorComponent.currentPageEntries.length - 1].quantity
        hasBlankEntry = (quantity === 0 || quantity === '')
      }

      return ((this.cachedEntries.length + (hasBlankEntry ? 1 : 0)) < this.maxNumberOfEntries)
    },
    addBlankEntry () {
      if (this.canAddBlankEntry()) {
        this.hardwareMainTableEditorComponent.currentPageEntries.push(this.getBlankEntry())
      }
    },
    getBlankEntry () {
      return {
        id: this.getTempEntryId(),
        siteNo: '',
        location: '',
        postcode: '',
        description: '',
        partNumber: '',
        designPackHardwareVendorId: 0,
        designPackHardwareCurrencyId: 1,
        quantity: 0,
        unitCost: 0,
        marginPercent: this.iQuoteConstants.salesMargins.HARDWARE_DEFAULT_MARGIN,
        uniqueId: ++this.hardwareMainTableEditorComponent.uniqueId,
        priceOverride: null,
        designPackItemLookupId: this.iQuoteCalculationsDesignPackMixin_getDesignPackItemLookupId()
      }
    },
    sendChangesToParent () {
      var currentPageEntries = []
      var blankEntry = this.getBlankEntry()
      var array = this.hardwareMainTableEditorComponent.currentPageEntries

      for (let index = 0; index < array.length; index++) {
        const entry = array[index]
        if (!this.isBlankEntry(entry)) {
          // Loop through all properties of the blank entry and compare against the entry to be
          // saved to check that the user hasn't entered a value of the wrong data type. This typically
          // will only happen if they deleted the contents of a numeric field. This would result in a empty
          // string being returned, and will cause a failure if we tried to save that to the DB, as it's not numeric
          for (const property in blankEntry) {
            if (typeof blankEntry[property] !== typeof entry[property] &&
                property !== 'priceOverride' /* can be object (null) or a number */) {
              // User has entered something of wrong datatype,
              // which will fail when saving, so reset it to default value

              console.log('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])
              this.iQuoteFunctions_ShowError('Field \'' + property + '\' was should be of type ' + typeof blankEntry[property] + ' but instead was of type ' + typeof entry[property] + ', so has been reset to ' + blankEntry[property])

              entry[property] = blankEntry[property] // Set to default
            }
          }
          currentPageEntries.push(entry)
        }
      }

      const cachedEntries = this.hardwareMainTableEditorComponent.cachedEntries // original records

      currentPageEntries.forEach(pageEntry => {
        let cachedEntry = cachedEntries.find(entry => entry.uniqueId === pageEntry.uniqueId)
        if (cachedEntry === undefined) {
          cachedEntries.push(pageEntry) // adds the new record into the cached records
        } else {
          cachedEntry = pageEntry // updates the cached record with changes made to the record in the current page
        }
      })
      this.$emit('tableUpdated', cachedEntries)
      this.refreshPaginationTab()

      if (!this.hasBlankEntry()) {
        this.addBlankEntry()
      }
    },
    getTempEntryId () {
      /* items that are not saved to the db yet will have negative ids */
      var minId = -1

      var arr = this.hardwareMainTableEditorComponent.currentPageEntries

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id <= minId) {
          minId = arr[i].id - 1
        }
      }

      return minId
    },
    deleteEntry (entry) {
      var arr = this.hardwareMainTableEditorComponent.currentPageEntries
      let cachedEntries = this.hardwareMainTableEditorComponent.cachedEntries // original records

      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id === entry.id) {
          cachedEntries.splice(cachedEntries.findIndex(c => c.uniqueId === arr[i].uniqueId), 1)
          arr.splice(i, 1)
          this.sendChangesToParent()
          this.refreshCurrentPageData()
          return
        }
      }
    },
    disableEntry (entry, sendChangesEvent = true) {
      entry.disabled = true
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    enableEntry (entry, sendChangesEvent = true) {
      entry.disabled = false
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    negateEntry (entry, sendChangesEvent = true) {
      entry.quantity = -entry.quantity
      if (sendChangesEvent) {
        this.sendChangesToParent()
      }
    },
    pageChanged (pageNumber) {
      this.hardwareMainTableEditorComponent.currentPage = pageNumber - 1
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.hardwareMainTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    firstClicked () {
      this.hardwareMainTableEditorComponent.currentPage = 0
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.hardwareMainTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    prevClicked () {
      this.hardwareMainTableEditorComponent.currentPage--
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.hardwareMainTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    nextClicked () {
      this.hardwareMainTableEditorComponent.currentPage++
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.hardwareMainTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    lastClicked () {
      this.hardwareMainTableEditorComponent.currentPage = this.totalPages - 1
      if (this.hasReplicaInTheSamePage) {
        DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'currentPage', false, this.hardwareMainTableEditorComponent.currentPage + 1)
      }
      this.animateTable()
    },
    searchText (filter) {
      this.hardwareMainTableEditorComponent.filter = filter
      DataEventBus.$emit(`refreshReplica${this.paginationComponentIndex}`, 'filter', true, filter)
    },
    animateTable () {
      if (this.hardwareMainTableEditorComponent.animatePageTransitions) {
        this.hardwareMainTableEditorComponent.showData = false
        setTimeout(() => {
          this.hardwareMainTableEditorComponent.showData = true
        }, 400)
      }
    }
  }
}
</script>
<style scoped>
  table.iquoteDesignpackTable tr{
    height: 38px;
  }

  #mainTable {
    margin-top: 15px;
    border: none;
    display: block;
  }

  table input[type=text], table input[type=number], table input[type=button], table select {
    border: none;
    margin: 0;
    height: 25px;
    font-size: 1em;
  }

  tr.mainHeader {
    text-align: center;
    font-size: 1.5em;
  }

  #mainTable input.siteNoInput {
    width: 40px;
  }

  #mainTable input.locationInput {
    width: 90px;
  }

  #mainTable input.descriptionInput {
    width: 180px;
  }

  #mainTable input.postcodeInput {
    width: 70px;
  }

  #mainTable input.partNumberInput {
    width: 80px;
  }

  #mainTable select.vendorInput {
    width: 120px;
  }

  #mainTable input.quantityInput {
    width: 45px;
  }

  #mainTable input.unitCostInput {
    width: 55px;
  }

  #mainTable select.currencyInput {
    width: 55px;
  }

  #mainTable input.marginInput {
    width: 45px;
  }

  .readOnlyCell {
    background-color: silver;
  }

  .noborder {
    border: none;
  }

  .commandButtons {
    width: 100px;
  }

  #mainTable tr.disabled td {
    background-color: silver;
  }

  @-webkit-keyframes fadeIn {
    0% { opacity: 0.1; }
    30% { opacity: 0.4; }
    50% { opacity: 0.6; }
    70% { opacity: 0.9; }
    100% { opacity: 1; }
  }

  @keyframes fadeIn {
    0% { opacity: 0.1; }
    30% { opacity: 0.4; }
    50% { opacity: 0.6; }
    70% { opacity: 0.9; }
    100% { opacity: 1; }
  }

  .opaque {
    -webkit-animation-name: fadeIn;
    -webkit-animation-duration: 0.7s;
    animation-name: fadeIn;
    animation-duration: 0.7s;
  }
</style>
